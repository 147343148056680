import { Col, Row, Spin } from 'antd';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import { BusinessSelect } from 'components/atoms/BusinessSelect';
import { DivisionSelect } from 'components/atoms/DivisionSelect';
import { FieldInput } from 'components/atoms/FieldInput';
import { FieldInputDatePicker } from 'components/atoms/FieldInputDatePicker';
import { FieldInputSelect } from 'components/atoms/FieldInputSelect';
import { FieldInputTextArea } from 'components/atoms/FieldInputTextArea';
import { ReturnReferenceSearch } from 'components/atoms/ReturnReferenceSearch';
import { useFormikContext } from 'formik';
import { ShipmentPayload } from 'models/DockReceipt';
import { FC, useState } from 'react';
import { useGetEntityAddressesQuery } from 'redux/services/chuckieSue/addressesApi';
import { LocationAddressTypes } from 'redux/services/chuckieSue/models/addresses';
import { useGetUsersQuery } from 'redux/services/cookieMonster/cookieMonsterApi';
import { useAppSelector } from 'redux/store';
import { AssignTrackingList } from './AssignTrackingList';

type Props = {
  recordId: string;
  isCreate: boolean;
};

export const ShipmentForm: FC<Props> = ({ recordId }) => {
  const { xs } = useBreakpoint();
  const { values } = useFormikContext<ShipmentPayload>();
  const isReadOnly = values.status === 'Cancelled' || values.status === 'Completed';
  const [loadingState, setLoadingState] = useState(false);
  const { data: userData, isLoading: isUsersLoading } = useGetUsersQuery({ isMdsiEmployee: true, warehouseIdEquals: values.processingWarehouse }, { skip: !values.processingWarehouse });

  const { chuckieSueAddressesParams } = useAppSelector((state) => state);

  const { data, isLoading } = useGetEntityAddressesQuery({ params: { ...chuckieSueAddressesParams, addressTypesInclude: [LocationAddressTypes.Warehouse] } });

  return (
    <Row gutter={[8, 8]} style={{ marginTop: 0, width: '100%' }}>
      <Col span={!xs ? 12 : 24}>
        <FieldInputSelect
          fieldName="status"
          label="Status"
          options={['New', 'In Progress', 'Escalation', 'Issue Resolved', 'Completed', 'Cancelled', 'MIR'].map((opt) => ({ label: opt, value: opt }))}
        />
      </Col>
      <Col span={!xs ? 12 : 24}>
        <FieldInputSelect
          disabled={isReadOnly}
          options={['Purchase Order', 'Customer Return', 'RMA/Defective Return', 'Transfer', 'Other'].map((opt) => ({ label: opt, value: opt }))}
          fieldName="receiptType"
          label="Receipt Type"
        />
      </Col>
      <Col span={!xs ? 12 : 24}>
        <ReturnReferenceSearch disabled={isReadOnly} setLoadingState={setLoadingState} fieldName="returnReference" label="Return Ref" />
      </Col>
      <Col span={!xs ? 12 : 24}>
        {values.receiptType === 'Other' && <DivisionSelect />}
        {values.receiptType !== 'Other' && <FieldInput loadingState={loadingState} disabled={values.receiptType !== 'Other' && values.status !== 'MIR'} fieldName="accountId" label="Account Id" />}
      </Col>
      <Col span={!xs ? 12 : 24}>
        <FieldInput loadingState={loadingState} disabled={values.receiptType !== 'Other' && values.status !== 'MIR'} fieldName="mdsiOrderNumber" label="MDSi Order Number" />
      </Col>
      {values.status === 'MIR' && (
        <Col span={!xs ? 12 : 24}>
          <BusinessSelect />
        </Col>
      )}
      <Col span={!xs ? 12 : 24}>
        <Spin spinning={isLoading}>
          <FieldInputSelect
            disabled={isReadOnly}
            options={data?.data?.map((opt) => ({ label: `${opt.code} - ${opt.name}`, value: opt.code }))}
            fieldName="processingWarehouse"
            label="Processing Warehouse"
          />
        </Spin>
      </Col>
      <Col span={!xs ? 12 : 24}>
        <Spin spinning={isUsersLoading}>
          <FieldInputSelect
            disabled={!values.processingWarehouse || isReadOnly}
            options={userData?.data?.map((user) => ({ label: user.name, value: user.user_id }))}
            fieldName="assignedToUserFullName"
            label="Assigned To User"
          />
        </Spin>
      </Col>
      <Col span={!xs ? 12 : 24}>
        <FieldInputDatePicker disabled={isReadOnly} fieldName="shipmentDate" label="Shipment Date" />
      </Col>
      <Col span={!xs ? 12 : 24}>
        <AssignTrackingList recordId={recordId} />
      </Col>
      <Col span={!xs ? 12 : 24}>
        <FieldInputTextArea disabled={isReadOnly} fieldName="note" label="Note" />
      </Col>
      {!xs && <Col span={12} />}
    </Row>
  );
};
