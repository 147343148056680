import { Row, Space, Tabs, Typography } from 'antd';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import { CreateLoadDrawer } from 'components/atoms/CreateLoadDrawer';
import { MobileFilters } from 'components/atoms/MobileFilters';
import { RecycleBinButton } from 'components/atoms/RecycleBinButton';
import { HomeFilters } from 'components/molecules/HomeFilters';
import { ShipmentFilters } from 'components/molecules/ShipmentFilters';
import { AllShipmentsTable } from 'components/organisms/AllShipmentsTable';
import { DockReceiptsTable } from 'components/organisms/DockReceiptsTable';
import { MIRShipmentsTable } from 'components/organisms/MIRShipmentsTable';
import { useState } from 'react';
import { useAppSelector } from 'redux/store';

export const Home = (): JSX.Element => {
  const { xs } = useBreakpoint();
  const { returnDeletedDataOnly } = useAppSelector((state) => state.lindaParams);
  const [navKey, setNavKey] = useState('1');

  const items = [
    {
      key: '1',
      label: 'Dock Receipts',
      children: <DockReceiptsTable />
    },
    {
      key: '2',
      label: 'MIR Shipments',
      children: <MIRShipmentsTable />
    },
    {
      key: '3',
      label: 'All Shipments',
      children: <AllShipmentsTable />
    }
  ];

  return (
    <Space direction="vertical" size={10}>
      <Row justify={'space-between'} align={'middle'}>
        <Typography.Title style={{ margin: 0, padding: 0 }} level={xs ? 5 : 4}>
          {navKey === '1' ? 'Search Dock Receipts' : navKey === '2' ? 'Search MIR Shipments' : 'Search All Shipments'}
        </Typography.Title>
        <Space>
          {!returnDeletedDataOnly && navKey === '1' && <CreateLoadDrawer />}
          {!xs && navKey === '1' && <RecycleBinButton />}
        </Space>
        {xs && (
          <Space>
            {(navKey === '2' || navKey === '3') && <MobileFilters navKey={navKey} />}
            {navKey === '1' && <RecycleBinButton />}
          </Space>
        )}
      </Row>
      {!xs && navKey === '1' && <HomeFilters />}
      {!xs && (navKey === '2' || navKey === '3') && <ShipmentFilters isMIR={navKey === '2'} />}
      <Tabs onChange={(key): void => setNavKey(key)} activeKey={navKey} defaultActiveKey={navKey} items={items} type="card" />
    </Space>
  );
};
