import { List, Space, Spin } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { Table } from 'antd/lib';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import { AssignTrackingNumbersDrawer } from 'components/atoms/AssignTrackingNumbersDrawer';
import { DeleteDockReceiptButton } from 'components/atoms/DeleteDockReceiptButton';
import { EditLoadDrawer } from 'components/atoms/EditLoadDrawer';
import { MobileActions } from 'components/atoms/MobileActions';
import { MobileCard } from 'components/atoms/MobileCard';
import InfiniteScroll from 'react-infinite-scroll-component';

import { RecoverDockReceiptButton } from 'components/atoms/RecoverDockReceiptButton';
import { ShipmentsDrawer } from 'components/atoms/ShipmentsDrawer';
import { LoaderWithMessage } from 'components/common/LoaderWithMessage';
import { TablePagination } from 'components/molecules/TablePagination';
import { DockReceipt } from 'models/DockReceipt';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { useGetDockReceiptsQuery } from 'redux/services/linda/lindaApi';
import { setOffset } from 'redux/services/linda/lindaParams';
import { useAppSelector } from 'redux/store';

export const DockReceiptsTable = (): JSX.Element => {
  const { xs } = useBreakpoint();
  const { lindaParams } = useAppSelector((state) => state);
  const { data, isLoading, isFetching } = useGetDockReceiptsQuery({ params: { ...lindaParams, orderByDirection: 'descending' }, isTable: true });
  const dispatch = useDispatch();

  const columns: ColumnsType<DockReceipt> = [
    {
      title: 'Load Id',
      dataIndex: 'loadId',
      key: 'loadId'
    },
    {
      title: 'Carrier',
      dataIndex: 'carrierId',
      key: 'carrierId'
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status'
    },
    {
      title: 'Date',
      dataIndex: 'arrivalDate',
      key: 'arrivalDate',
      render: (date) => moment(date).format('MM-DD-YYYY')
    },
    {
      title: 'Warehouse',
      dataIndex: 'warehouseId',
      key: 'warehouseId'
    },
    {
      title: 'Assigned User',
      dataIndex: 'assignedToUserFullName',
      key: 'assignedToUserFullName'
    },
    {
      title: 'Actions',
      key: 'action',
      fixed: 'right',
      align: 'center',
      render: (_, record): JSX.Element => {
        if (xs) {
          return <MobileActions record={record} />;
        }
        if (lindaParams.returnDeletedDataOnly) {
          return (
            <Space>
              <RecoverDockReceiptButton record={record} />
              <DeleteDockReceiptButton record={record} />
            </Space>
          );
        }

        return (
          <Space>
            <EditLoadDrawer setParentVisible={() => console.log()} record={record} />
            <AssignTrackingNumbersDrawer isTable record={record} />
            <ShipmentsDrawer record={record} />
            <DeleteDockReceiptButton record={record} />
          </Space>
        );
      }
    }
  ];

  const handleNext = (): void => {
    dispatch(setOffset(lindaParams.offset + 25));
  };

  if (isLoading) {
    return <LoaderWithMessage loadingMessage="Loading Dock Receipts..." />;
  }

  if (xs)
    return (
      <InfiniteScroll style={{ height: '80%' }} dataLength={100} next={handleNext} hasMore={data?.hasMore ?? false} loader={<Spin spinning size="small" />}>
        <List grid={{ xs: 1, sm: 2, md: 4, lg: 4, xl: 4, xxl: 4, gutter: [8, 8] }} dataSource={data?.data} renderItem={(item): JSX.Element => <MobileCard record={item} />} />
      </InfiniteScroll>
    );

  return (
    <>
      <Table
        loading={{ spinning: isFetching, indicator: <LoaderWithMessage loadingMessage="Loading Dock Receipts..." /> }}
        scroll={{ x: 'max-content' }}
        size={xs ? 'large' : 'small'}
        columns={columns}
        dataSource={data?.data}
        rowKey={(record): string => record.id}
        pagination={false}
      />
      <TablePagination offset={lindaParams.offset} dispatchAction={setOffset} hasMore={data?.hasMore ?? false} />
    </>
  );
};
