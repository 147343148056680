import { Modal, notification } from 'antd';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import { deleteLottie } from 'assets/lottieAssets';
import { Shipment } from 'models/DockReceipt';
import { QueryErrorModel } from 'models/ErrorModel';
import { FC } from 'react';
import { useDeleteShipmentReceiptMutation } from 'redux/services/linda/lindaApi';
import { LottieButton } from './LottieButton';

type Props = {
  record: Shipment;
  dockReceiptId: string;
};

export const DeleteShipmentsButton: FC<Props> = ({ record, dockReceiptId }) => {
  const { xs } = useBreakpoint();
  const [deleteDockReceipt] = useDeleteShipmentReceiptMutation();
  const handleClick = (): void => {
    Modal.confirm({
      title: 'Are you sure you want to delete this shipment?',
      content: 'This action cannot be undone.',
      onOk: async () => {
        if (!record.id) {
          return;
        }
        try {
          await deleteDockReceipt({ receiptId: dockReceiptId, shipmentId: record.id, params: { wouldYouLikeToPlayAGame: true } });
          notification.success({ message: 'Shipment successfully deleted' });
        } catch (e) {
          console.error(e, 'error');

          const error = e as QueryErrorModel;
          const errorMessage = error?.data?.errorMessage ? error?.data?.errorMessage : error.data ? (error?.data as string) : 'An error occurred, the team has been notified.';

          notification.error({
            message: 'Error',
            description: errorMessage,
            className: 'custom-class',
            style: {
              width: 600
            },
            duration: 5
          });
        }
      }
    });
  };

  return <LottieButton tooltip={xs ? undefined : 'Delete'} onClick={handleClick} animationData={deleteLottie} borderColor="#D94853" hoverColor="#FFEAEB" />;
};
