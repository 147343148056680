import { DeleteOutlined } from '@ant-design/icons';
import { Col, Input, InputRef, List, notification, Row, Typography } from 'antd';
import { useFormikContext } from 'formik';
import { DockReceipt, Load } from 'models/DockReceipt';
import { FC, useState } from 'react';

type Props = {
  visible: boolean;
  inputRef: React.RefObject<InputRef>;
  record?: DockReceipt;
};
export const TrackingNumbersForm: FC<Props> = ({ inputRef, record }) => {
  const [val, setVal] = useState<string>('');

  const {
    values: { trackingNumbers },
    setFieldValue
  } = useFormikContext<Load>();

  return (
    <Row justify={'center'} gutter={[0, 20]}>
      <Col style={{ textAlign: 'center' }} span={24}>
        <Typography.Title style={{ margin: 0, padding: 0 }} level={4}>
          Load Id: FED00001
        </Typography.Title>
      </Col>
      <Col style={{ textAlign: 'center' }} span={24}>
        <Typography.Text>Tracking Number Scan</Typography.Text>
        <Input
          // readOnly={xs ? true : false}
          value={val}
          ref={inputRef}
          onChange={(e): void => {
            // if (!xs) {
            setVal(e.target.value.toUpperCase());
            // }
          }}
          inputMode="none"
          onKeyDown={(key): void => {
            if (key.key === 'Enter') {
              key.preventDefault();
              if (record?.masterTrackingNumbers.includes(val)) {
                setVal('');

                return notification.error({ message: `Tracking number ${val} already exists` });
              }
              if (!val) {
                return notification.error({ message: 'Tracking number cannot be empty' });
              }
              setFieldValue('trackingNumbers', [...trackingNumbers, val]);
              setVal('');
            }
          }}
        />
      </Col>

      <Col style={{ textAlign: 'center' }} span={24}>
        <Typography.Title style={{ margin: 0, padding: 0, marginBottom: 8 }} level={5}>
          Unassigned Tracking Numbers
        </Typography.Title>
        <List
          dataSource={trackingNumbers}
          renderItem={(item): JSX.Element => (
            <Row justify={'space-between'}>
              <Col>
                <Typography.Text>{item}</Typography.Text>
              </Col>
              <Col>
                <DeleteOutlined
                  style={{ color: 'red', cursor: 'pointer' }}
                  onClick={(): void => {
                    setFieldValue(
                      'trackingNumbers',
                      trackingNumbers.filter((num) => num !== item)
                    );
                  }}
                />
              </Col>
            </Row>
          )}
        />
      </Col>
      <Col style={{ textAlign: 'center' }} span={24}>
        <Typography.Title style={{ margin: 0, padding: 0, marginBottom: 8 }} level={5}>
          Assigned Tracking Numbers
        </Typography.Title>
        <List
          dataSource={record?.assignedTrackingNumbers}
          renderItem={(item): JSX.Element => (
            <Row justify={'space-between'}>
              <Col>
                <Typography.Text>{item}</Typography.Text>
              </Col>
              <Col>
                <DeleteOutlined
                  style={{ color: 'gray', cursor: 'pointer', pointerEvents: 'none' }}
                  onClick={(): void => {
                    setFieldValue(
                      'trackingNumbers',
                      trackingNumbers.filter((num) => num !== item)
                    );
                  }}
                />
              </Col>
            </Row>
          )}
        />
      </Col>
    </Row>
  );
};
