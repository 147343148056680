import { SlidersOutlined } from '@ant-design/icons';
import { Button, Drawer, Row, Typography } from 'antd';
import { ShipmentFilters } from 'components/molecules/ShipmentFilters';
import { useState } from 'react';

export const MobileFiltersShipments = (): JSX.Element => {
  const [visible, setVisible] = useState(false);

  const showDrawer = (): void => {
    setVisible(true);
  };

  const onClose = (): void => {
    setVisible(false);
  };

  return (
    <>
      <Button onClick={showDrawer} icon={<SlidersOutlined />} />
      <Drawer
        title={
          <Row justify={'center'} style={{ marginLeft: -50 }}>
            <Typography.Title level={4} style={{ padding: 0, margin: 0 }}>
              Filters
            </Typography.Title>
          </Row>
        }
        style={{ borderRadius: 15 }}
        onClose={onClose}
        height={'75%'}
        placement="bottom"
        open={visible}>
        <ShipmentFilters />
      </Drawer>
    </>
  );
};
