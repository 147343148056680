import { Button, message, Select, Space } from 'antd';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import { colors } from 'common/styles/colors';
import { Shipment } from 'models/DockReceipt';
import { FC, useState } from 'react';
import { useAppSelector } from 'redux/store';

type Props = {
  record: Shipment;
};

export const PreviewLabelButton: FC<Props> = ({ record }) => {
  const { xs } = useBreakpoint();
  const { printDetails } = useAppSelector((state) => state.app);
  const { user } = useAppSelector((state) => state.app);
  const [printer, setPrinter] = useState<string | undefined>(undefined);
  const [loading, setLoading] = useState(false);

  const userPrinters = (user as any)['https://acuity.mdsiinc.com/user/user_metadata'].assigned_printers;

  const options = userPrinters?.map((printer: any, idx: number) => ({ label: printer.Name, value: printer.Name, key: idx }));

  const handleZebraPrint = async (): Promise<void> => {
    const slipData = Object.entries(printDetails).map(([label, qty]) => {
      return {
        qty: qty.qty,
        slip: record,
        trackingNumber: label
      };
    });

    const port = printer?.split(':')[1];
    const ip = printer?.split(':')[0];

    let success = true;

    setLoading(true);
    for await (const slip of slipData) {
      for (let i = 0; i < slip.qty; i++) {
        try {
          const test = await fetch('https://feline-intent-polecat.ngrok-free.app/sendPrintJob', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              ipAddress: ip,
              port: Number(port),
              printDetails: [slip]
            })
          });

          if (!test.ok) {
            const statusText = test.status === 404 ? 'Print server is offline' : test.status === 500 ? 'Failed to connect to printer' : 'Failed to print label';

            success = false;
            throw new Error(statusText);
          }
        } catch (error) {
          message.error((error as any).message);
          success = false;
          break;
        }
      }
      if (!success) break;
    }
    setLoading(false);
    success && message.success('Labels successfully printed');
  };

  return (
    <Space>
      <Select onSelect={(e): void => setPrinter(e)} value={printer} placeholder="Select Printer" options={options} style={{ width: xs ? 145 : 200 }} />
      <Button loading={loading} disabled={loading} style={{ background: colors.success[600], color: 'white', width: xs ? 145 : undefined }} onClick={handleZebraPrint}>
        Print
      </Button>
    </Space>
  );
};
