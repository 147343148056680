import { ArrowLeftOutlined, DeleteOutlined } from '@ant-design/icons';
import { Button, Tooltip } from 'antd';
import { FC } from 'react';
import { useDispatch } from 'react-redux';
import { setReturnDeletedDataOnly } from 'redux/services/linda/lindaParams';
import { useAppSelector } from 'redux/store';

export const RecycleBinButton: FC = () => {
  const { returnDeletedDataOnly } = useAppSelector((state) => state.lindaParams);

  const dispatch = useDispatch();

  const handleClick = (): void => {
    if (returnDeletedDataOnly) {
      dispatch(setReturnDeletedDataOnly(false));

      return;
    }
    dispatch(setReturnDeletedDataOnly(true));
  };

  return (
    <Tooltip title={returnDeletedDataOnly ? 'Back' : 'Recycle Bin'}>
      <Button onClick={handleClick} shape="circle" icon={returnDeletedDataOnly ? <ArrowLeftOutlined /> : <DeleteOutlined />} />
    </Tooltip>
  );
};
