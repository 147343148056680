import { notification } from 'antd';
import { recoverLottie } from 'assets/lottieAssets';
import { DockReceipt } from 'models/DockReceipt';
import { QueryErrorModel } from 'models/ErrorModel';
import { FC } from 'react';
import { useRecoverDockReceiptMutation } from 'redux/services/linda/lindaApi';
import { LottieButton } from './LottieButton';

type Props = {
  record: DockReceipt;
};

export const RecoverDockReceiptButton: FC<Props> = ({ record }) => {
  const [recoverDockReceipt] = useRecoverDockReceiptMutation();
  const handleClick = async (): Promise<void> => {
    try {
      if (!record.id) {
        return;
      }
      await recoverDockReceipt({ receiptId: record.id });
      notification.success({ message: 'Dock Receipt successfully restored' });
    } catch (e) {
      console.error(e, 'error');

      const error = e as QueryErrorModel;
      const errorMessage = error?.data?.errorMessage ? error?.data?.errorMessage : error.data ? (error?.data as string) : 'An error occurred, the team has been notified.';

      notification.error({
        message: 'Error',
        description: errorMessage,
        className: 'custom-class',
        style: {
          width: 600
        },
        duration: 5
      });
    }
  };

  return <LottieButton onClick={handleClick} tooltip="Restore" animationData={recoverLottie} borderColor="#39C0F1" hoverColor="#E5F7FF" />;
};
