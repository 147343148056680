import { EditOutlined, MoreOutlined } from '@ant-design/icons';
import { Button, Drawer, Row, Space, Typography } from 'antd';
import { DockReceipt } from 'models/DockReceipt';
import { FC, useState } from 'react';
import { EditLoadDrawer } from './EditLoadDrawer';
type Props = {
  record: DockReceipt;
};
export const MobileActions: FC<Props> = ({ record }) => {
  const [visible, setVisible] = useState(false);
  const [selection, setSelection] = useState('');

  return (
    <>
      <Button icon={<MoreOutlined />} onClick={() => setVisible(true)} />
      <Drawer
        destroyOnClose
        afterOpenChange={(open): void => {
          if (!open) setSelection('');
        }}
        style={{ borderRadius: 15 }}
        closeIcon={null}
        onClose={() => setVisible(false)}
        height={'30%'}
        open={visible}
        placement={'bottom'}>
        <Space direction="vertical" style={{ width: '100%' }}>
          <Row style={{ border: '1px solid black', padding: 8, borderRadius: 10 }} justify={'space-between'} onClick={() => setSelection('edit')}>
            <Typography.Title level={4} style={{ margin: 0, padding: 0 }} onClick={() => setSelection('edit')}>
              Edit
            </Typography.Title>
            <EditOutlined style={{ fontSize: 25 }} />
          </Row>
          <Row style={{ border: '1px solid black', padding: 8, borderRadius: 10 }} justify={'space-between'}>
            <Typography.Title level={4} style={{ margin: 0, padding: 0 }} onClick={() => setSelection('edit')}>
              Assign Tracking Numbers
            </Typography.Title>
            <EditOutlined style={{ fontSize: 25 }} />
          </Row>
          <Row style={{ border: '1px solid black', padding: 8, borderRadius: 10 }} justify={'space-between'}>
            <Typography.Title level={4} style={{ margin: 0, padding: 0 }} onClick={() => setSelection('edit')}>
              Add Shipments
            </Typography.Title>
            <EditOutlined style={{ fontSize: 25 }} />
          </Row>
          <Row style={{ border: '1px solid black', padding: 8, borderRadius: 10 }} justify={'space-between'}>
            <Typography.Title level={4} style={{ margin: 0, padding: 0 }} onClick={() => setSelection('edit')}>
              Delete
            </Typography.Title>
            <EditOutlined style={{ fontSize: 25 }} />
          </Row>
        </Space>
      </Drawer>
      <EditLoadDrawer selection={selection} setParentVisible={setVisible} record={record} />
    </>
  );
};
