import { PlusCircleOutlined } from '@ant-design/icons';
import { useAuth0 } from '@auth0/auth0-react';
import { Button, Drawer, FloatButton, notification, Row, Space, Spin } from 'antd';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import { colors, toRgba } from 'common/styles/colors';
import { LoaderWithMessage } from 'components/common/LoaderWithMessage';
import { ColorCard } from 'components/molecules/ColorCard';
import { LoadForm } from 'components/molecules/LoadForm';
import { FormikProvider, useFormik } from 'formik';
import { DockReceipt, Load, loadSchema } from 'models/DockReceipt';
import { QueryErrorModel } from 'models/ErrorModel';
import moment from 'moment';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useCreateDockReceiptMutation } from 'redux/services/linda/lindaApi';
import { setIsCreated } from 'redux/slices/appSlice';
import { useAppSelector } from 'redux/store';
import { AssignTrackingNumbersDrawer } from './AssignTrackingNumbersDrawer';

export const CreateLoadDrawer = (): JSX.Element => {
  const { xs } = useBreakpoint();
  const dispatch = useDispatch();
  const { isCreated } = useAppSelector((state) => state.app);
  const { user } = useAuth0();
  const [visible, setVisible] = useState(false);
  const [createDockReceipt] = useCreateDockReceiptMutation();
  const [record, setRecord] = useState<DockReceipt | undefined>(undefined);
  const assignedWarehouses = user?.['https://acuity.mdsiinc.com/user/user_metadata'].authorized_warehouses;
  const defaultWarehouse = assignedWarehouses?.find((warehouse: any) => warehouse.isDefault)?.warehouseCode;

  const formik = useFormik<Load>({
    enableReinitialize: true,
    validationSchema: loadSchema,
    initialValues: {
      carrierId: '',
      arrivalDate: moment().format('MM/DD/YYYY'),
      assignedToUserFullName: user?.name ?? '',
      assignedToUserId: user?.sub ?? '',
      trackingNumbers: [],
      warehouseId: defaultWarehouse ?? '',
      isActive: true,
      status: 'Open'
    },
    onSubmit: async (values) => {
      try {
        const resp = await createDockReceipt({ payload: values }).unwrap();

        setRecord(resp);
        notification.success({ message: 'Dock Receipt Created' });
        setVisible(false);
        formik.resetForm();
        dispatch(setIsCreated(true));
      } catch (e) {
        console.error(e, 'error');

        const error = e as QueryErrorModel;
        const errorMessage = error?.data?.errorMessage ? error?.data?.errorMessage : error.data ? (error?.data as string) : 'An error occured, the team has been notified.';

        notification.error({
          message: 'Error',
          description: errorMessage,
          className: 'custom-class',
          style: {
            width: 600
          },
          duration: 5
        });
      }
    }
  });

  const showDrawer = (): void => {
    setVisible(true);
  };

  const onClose = (): void => {
    setVisible(false);
    formik.resetForm();
  };

  return (
    <FormikProvider value={formik}>
      {!xs && (
        <Button type="primary" onClick={showDrawer} icon={<PlusCircleOutlined />}>
          Create Load
        </Button>
      )}
      {xs && <FloatButton type="primary" style={{ width: 100, height: 50, borderRadius: '5%' }} onClick={showDrawer} icon={<PlusCircleOutlined />} description="Create Load" />}
      <Drawer
        destroyOnClose
        footer={
          <Row justify={'end'} align={'bottom'}>
            <Space>
              <Button onClick={onClose}>{isCreated ? 'Save' : 'Cancel'}</Button>
              <Button
                disabled={
                  !formik.values.arrivalDate ||
                  !formik.values.carrierId ||
                  !formik.values.warehouseId ||
                  !formik.values.assignedToUserFullName ||
                  !formik.values.assignedToUserId ||
                  !formik.values.isActive ||
                  !formik.values.status
                }
                type={'primary'}
                onClick={formik.submitForm}>
                {isCreated ? 'Complete Scan' : 'Create'}
              </Button>
            </Space>
          </Row>
        }
        style={{ borderRadius: xs ? 15 : 0 }}
        closeIcon={null}
        onClose={onClose}
        height={isCreated ? '75%' : '90%'}
        width={xs ? '100%' : '30%'}
        open={visible}
        placement={xs ? 'bottom' : 'right'}>
        <ColorCard color={isCreated ? toRgba(colors.illuminatingEmerald, 0.4) : toRgba(colors.orangeWeb, 0.4)} title={isCreated ? 'Assign Tracking Numbers' : 'Load Identification'}>
          <Spin spinning={formik.isSubmitting} indicator={<LoaderWithMessage loadingMessage="Creating Dock Receipt" />}>
            <Space direction="vertical" size={20}>
              {!isCreated && <LoadForm isCreate />}
            </Space>
          </Spin>
        </ColorCard>
      </Drawer>
      {isCreated && <AssignTrackingNumbersDrawer record={record} />}
    </FormikProvider>
  );
};
