import { Col, Row } from 'antd';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import { FancyDateRangePicker } from 'components/ui/filters/FancyDateRangePicker';
import { FancyInput } from 'components/ui/filters/FancyInput';
import { FancySelect } from 'components/ui/filters/FancySelect';
import { useDispatch } from 'react-redux';
import { setCarrierIdContains, setLoadIdContains, setStatuses, setTrackingNumberContains, setWarehouseIdContains } from 'redux/services/linda/lindaParams';
import { useAppSelector } from 'redux/store';
import { useDebouncedCallback } from 'use-debounce';

export const HomeFilters = (): JSX.Element => {
  const { xs } = useBreakpoint();
  const dispatch = useDispatch();
  const { statuses } = useAppSelector((state) => state.lindaParams);

  const handleChange = useDebouncedCallback((event: React.ChangeEvent<HTMLInputElement>, filterAction: any) => {
    const { value } = event.target;

    dispatch(filterAction(value || undefined));
  }, 500);

  const handleFilters = (filter: string, event: React.ChangeEvent<HTMLInputElement>): void => {
    switch (filter) {
      case 'loadId': {
        handleChange(event, setLoadIdContains);
        break;
      }

      case 'carrier': {
        handleChange(event, setCarrierIdContains);
        break;
      }

      case 'status': {
        dispatch(setStatuses(event as unknown as string));
        break;
      }

      case 'warehouse': {
        handleChange(event, setWarehouseIdContains);
        break;
      }

      case 'trackingNumber': {
        handleChange(event, setTrackingNumberContains);
        break;
      }
    }
  };

  return (
    <Row style={{ background: 'white', padding: 16 }} gutter={xs ? [0, 25] : [8, 8]}>
      <Col xs={24} sm={8} md={6} lg={4} xl={4} xxl={4}>
        <FancyInput onChange={(e) => handleFilters('loadId', e)} placeholder="Load ID" />
      </Col>
      <Col xs={24} sm={8} md={6} lg={4} xl={4} xxl={4}>
        <FancyInput onChange={(e) => handleFilters('carrier', e)} placeholder="Carrier" />
      </Col>
      <Col xs={24} sm={8} md={6} lg={4} xl={4} xxl={4}>
        <FancySelect
          value={statuses}
          onChange={(e) => handleFilters('status', e)}
          style={{ width: '100%' }}
          options={['Open', 'Closed', 'Cancelled'].map((opt) => ({ label: opt, value: opt }))}
          mode="multiple"
          placeholder="Status"
        />
      </Col>
      <Col xs={24} sm={8} md={6} lg={4} xl={4} xxl={4}>
        <FancyInput onChange={(e) => handleFilters('warehouse', e)} placeholder="Warehouse" />
      </Col>
      <Col xs={24} sm={8} md={6} lg={4} xl={4} xxl={4}>
        <FancyInput onChange={(e) => handleFilters('trackingNumber', e)} placeholder="Tracking Number" />
      </Col>
      <Col span={xs ? 24 : 4}>
        <FancyDateRangePicker />
      </Col>
    </Row>
  );
};
