import { ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons';
import { ActionCreatorWithPayload } from '@reduxjs/toolkit';
import { Button, Row } from 'antd';
import { FC } from 'react';
import { useDispatch } from 'react-redux';

type Props = {
  hasMore: boolean;
  dispatchAction: ActionCreatorWithPayload<number, string>;
  offset: number;
};

export const TablePagination: FC<Props> = ({ hasMore, dispatchAction, offset }) => {
  const dispatch = useDispatch();

  const handleNext = (): void => {
    dispatch(dispatchAction(offset + 25));
  };
  const handlePrev = (): void => {
    dispatch(dispatchAction(offset - 25));
  };

  return (
    <Row style={{ width: '100%', marginTop: 8 }}>
      <Row style={{ width: '100%' }} justify={'end'}>
        {offset > 0 && (
          <Button icon={<ArrowLeftOutlined />} style={{ marginRight: 5 }} onClick={handlePrev}>
            Previous Page
          </Button>
        )}
        {hasMore && (
          <Button onClick={handleNext}>
            Next Page
            <ArrowRightOutlined />
          </Button>
        )}
      </Row>
    </Row>
  );
};
