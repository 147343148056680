export const editLottie = {
  v: '5.5.7',
  meta: { g: 'LottieFiles AE 0.1.20', a: 'Sadjad', k: 'edit icon', d: 'edit icon Animation for you designed, enjoy', tc: 'none' },
  fr: 30,
  ip: 0,
  op: 37,
  w: 250,
  h: 250,
  nm: 'edit_lottie',
  ddd: 0,
  assets: [],
  layers: [
    {
      ddd: 0,
      ind: 1,
      ty: 3,
      nm: 'Null 1',
      sr: 1,
      ks: {
        o: { a: 0, k: 0, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [125, 125, 0], ix: 2 },
        a: { a: 0, k: [0, 0, 0], ix: 1 },
        s: {
          a: 1,
          k: [
            { i: { x: [0.45, 0.45, 0.45], y: [1, 1, 1] }, o: { x: [0.55, 0.55, 0.55], y: [0, 0, 0] }, t: 6, s: [100, 100, 100] },
            { i: { x: [0.45, 0.45, 0.45], y: [1, 1, 1] }, o: { x: [0.55, 0.55, 0.55], y: [0, 0, 0] }, t: 18, s: [80, 80, 100] },
            { t: 29, s: [100, 100, 100] }
          ],
          ix: 6
        }
      },
      ao: 0,
      ip: 0,
      op: 241,
      st: 0,
      bm: 0
    },
    {
      ddd: 0,
      ind: 2,
      ty: 4,
      nm: 'pen Outlines',
      parent: 1,
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: {
          a: 1,
          k: [
            { i: { x: [0.45], y: [1] }, o: { x: [0.55], y: [0] }, t: 6, s: [0] },
            { i: { x: [0.45], y: [1] }, o: { x: [0.55], y: [0] }, t: 12, s: [-13] },
            { i: { x: [0.45], y: [1] }, o: { x: [0.55], y: [0] }, t: 18, s: [0] },
            { i: { x: [0.45], y: [1] }, o: { x: [0.55], y: [0] }, t: 23, s: [-13] },
            { t: 29, s: [0] }
          ],
          ix: 10
        },
        p: {
          a: 1,
          k: [
            { i: { x: 0.45, y: 1 }, o: { x: 0.55, y: 0 }, t: 6, s: [-33.586, 33.586, 0], to: [-8.333, 8.333, 0], ti: [0, 0, 0] },
            { i: { x: 0.45, y: 1 }, o: { x: 0.55, y: 0 }, t: 18, s: [-83.586, 83.586, 0], to: [0, 0, 0], ti: [-8.333, 8.333, 0] },
            { t: 29, s: [-33.586, 33.586, 0] }
          ],
          ix: 2
        },
        a: { a: 0, k: [13, 132.403, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 }
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [-6.903, -6.903],
                    [6.903, -6.903],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  o: [
                    [6.903, -6.903],
                    [6.903, 6.904],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  v: [
                    [27.799, -52.799],
                    [52.799, -52.799],
                    [52.799, -27.799],
                    [-26.368, 51.368],
                    [-59.702, 59.702],
                    [-51.368, 26.368]
                  ],
                  c: true
                },
                ix: 2
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            {
              ty: 'st',
              c: { a: 0, k: [1, 0.749, 0.4, 1], ix: 3 },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 13, ix: 5 },
              lc: 2,
              lj: 2,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false
            },
            {
              ty: 'tr',
              p: { a: 0, k: [72.701, 72.701], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform'
            }
          ],
          nm: 'Group 1',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false
        }
      ],
      ip: 0,
      op: 241,
      st: 0,
      bm: 0
    },
    {
      ddd: 0,
      ind: 3,
      ty: 4,
      nm: 'rect Outlines 2',
      parent: 1,
      sr: 1,
      ks: { o: { a: 0, k: 100, ix: 11 }, r: { a: 0, k: 0, ix: 10 }, p: { a: 0, k: [-8.586, 8.586, 0], ix: 2 }, a: { a: 0, k: [88, 88, 0], ix: 1 }, s: { a: 0, k: [100, 100, 100], ix: 6 } },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, -9.205],
                    [0, 0],
                    [-9.205, 0],
                    [0, 0],
                    [0, 9.205],
                    [0, 0]
                  ],
                  o: [
                    [0, 0],
                    [-9.205, 0],
                    [0, 0],
                    [0, 9.205],
                    [0, 0],
                    [9.205, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  v: [
                    [0, -75],
                    [-58.333, -75],
                    [-75, -58.333],
                    [-75, 58.333],
                    [-58.333, 75],
                    [58.333, 75],
                    [75, 58.333],
                    [75, 0]
                  ],
                  c: false
                },
                ix: 2
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            {
              ty: 'st',
              c: { a: 0, k: [1, 0.749, 0.4, 1], ix: 3 },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 13, ix: 5 },
              lc: 2,
              lj: 2,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false
            },
            {
              ty: 'tr',
              p: { a: 0, k: [88, 88], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform'
            }
          ],
          nm: 'Group 1',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false
        },
        {
          ty: 'tm',
          s: { a: 0, k: 100, ix: 1 },
          e: {
            a: 1,
            k: [
              { i: { x: [0.45], y: [1] }, o: { x: [0.55], y: [0] }, t: 18, s: [100] },
              { t: 29, s: [0] }
            ],
            ix: 2
          },
          o: { a: 0, k: 0, ix: 3 },
          m: 1,
          ix: 2,
          nm: 'Trim Paths 1',
          mn: 'ADBE Vector Filter - Trim',
          hd: false
        }
      ],
      ip: 0,
      op: 241,
      st: 0,
      bm: 0
    },
    {
      ddd: 0,
      ind: 4,
      ty: 4,
      nm: 'rect Outlines',
      parent: 1,
      sr: 1,
      ks: { o: { a: 0, k: 100, ix: 11 }, r: { a: 0, k: 0, ix: 10 }, p: { a: 0, k: [-8.586, 8.586, 0], ix: 2 }, a: { a: 0, k: [88, 88, 0], ix: 1 }, s: { a: 0, k: [100, 100, 100], ix: 6 } },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, -9.205],
                    [0, 0],
                    [-9.205, 0],
                    [0, 0],
                    [0, 9.205],
                    [0, 0]
                  ],
                  o: [
                    [0, 0],
                    [-9.205, 0],
                    [0, 0],
                    [0, 9.205],
                    [0, 0],
                    [9.205, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  v: [
                    [0, -75],
                    [-58.333, -75],
                    [-75, -58.333],
                    [-75, 58.333],
                    [-58.333, 75],
                    [58.333, 75],
                    [75, 58.333],
                    [75, 0]
                  ],
                  c: false
                },
                ix: 2
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            {
              ty: 'st',
              c: { a: 0, k: [1, 0.749, 0.4, 1], ix: 3 },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 13, ix: 5 },
              lc: 2,
              lj: 2,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false
            },
            {
              ty: 'tr',
              p: { a: 0, k: [88, 88], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform'
            }
          ],
          nm: 'Group 1',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false
        },
        {
          ty: 'tm',
          s: { a: 0, k: 0, ix: 1 },
          e: {
            a: 1,
            k: [
              { i: { x: [0.45], y: [1] }, o: { x: [0.55], y: [0] }, t: 6, s: [100] },
              { t: 18, s: [0] }
            ],
            ix: 2
          },
          o: { a: 0, k: 0, ix: 3 },
          m: 1,
          ix: 2,
          nm: 'Trim Paths 1',
          mn: 'ADBE Vector Filter - Trim',
          hd: false
        }
      ],
      ip: 0,
      op: 241,
      st: 0,
      bm: 0
    }
  ],
  markers: []
};

export const linkLottie = {
  v: '4.8.0',
  meta: { g: 'LottieFiles AE ', a: '', k: '', d: '', tc: '' },
  fr: 23,
  ip: 0,
  op: 94,
  w: 284,
  h: 284,
  nm: '11',
  ddd: 0,
  assets: [],
  layers: [
    {
      ddd: 0,
      ind: 1,
      ty: 4,
      nm: 'Line5',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 101, ix: 10 },
        p: { a: 0, k: [220.5, 174.062, 0], ix: 2 },
        a: { a: 0, k: [-116.25, -64.938, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 }
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0]
                  ],
                  o: [
                    [0, 0],
                    [0, 0]
                  ],
                  v: [
                    [-115.75, -72.375],
                    [-115.75, -56.5]
                  ],
                  c: false
                },
                ix: 2
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            {
              ty: 'st',
              c: { a: 0, k: [0.3736, 0.6578, 0.9064, 1], ix: 3 },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 11, ix: 5 },
              lc: 2,
              lj: 2,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false
            },
            {
              ty: 'tr',
              p: { a: 0, k: [-1, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform'
            }
          ],
          nm: 'Shape 2',
          np: 3,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0]
                  ],
                  o: [
                    [0, 0],
                    [0, 0]
                  ],
                  v: [
                    [-115.75, -71.812],
                    [-115.75, -56.5]
                  ],
                  c: false
                },
                ix: 2
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            {
              ty: 'st',
              c: { a: 0, k: [0.2907, 0.61, 0.8893, 1], ix: 3 },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 14, ix: 5 },
              lc: 2,
              lj: 2,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform'
            }
          ],
          nm: 'Shape 1',
          np: 3,
          cix: 2,
          bm: 0,
          ix: 2,
          mn: 'ADBE Vector Group',
          hd: false
        },
        {
          ty: 'tm',
          s: {
            a: 1,
            k: [
              { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 70, s: [100] },
              { t: 75.1591796875, s: [0] }
            ],
            ix: 1
          },
          e: {
            a: 1,
            k: [
              { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 72.866, s: [100] },
              { t: 78.599609375, s: [0] }
            ],
            ix: 2
          },
          o: { a: 0, k: 0, ix: 3 },
          m: 1,
          ix: 3,
          nm: 'Trim Paths 1',
          mn: 'ADBE Vector Filter - Trim',
          hd: false
        }
      ],
      ip: 70,
      op: 79,
      st: 52,
      bm: 0
    },
    {
      ddd: 0,
      ind: 2,
      ty: 4,
      nm: 'Line4',
      sr: 1,
      ks: { o: { a: 0, k: 100, ix: 11 }, r: { a: 0, k: -38, ix: 10 }, p: { a: 0, k: [201, 206.312, 0], ix: 2 }, a: { a: 0, k: [-116.25, -64.938, 0], ix: 1 }, s: { a: 0, k: [100, 100, 100], ix: 6 } },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0]
                  ],
                  o: [
                    [0, 0],
                    [0, 0]
                  ],
                  v: [
                    [-115.75, -72.375],
                    [-115.75, -56.5]
                  ],
                  c: false
                },
                ix: 2
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            {
              ty: 'st',
              c: { a: 0, k: [0.3736, 0.6578, 0.9064, 1], ix: 3 },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 11, ix: 5 },
              lc: 2,
              lj: 2,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false
            },
            {
              ty: 'tr',
              p: { a: 0, k: [-1, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform'
            }
          ],
          nm: 'Shape 2',
          np: 3,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0]
                  ],
                  o: [
                    [0, 0],
                    [0, 0]
                  ],
                  v: [
                    [-115.75, -71.812],
                    [-115.75, -56.5]
                  ],
                  c: false
                },
                ix: 2
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            {
              ty: 'st',
              c: { a: 0, k: [0.2907, 0.61, 0.8893, 1], ix: 3 },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 14, ix: 5 },
              lc: 2,
              lj: 2,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform'
            }
          ],
          nm: 'Shape 1',
          np: 3,
          cix: 2,
          bm: 0,
          ix: 2,
          mn: 'ADBE Vector Group',
          hd: false
        },
        {
          ty: 'tm',
          s: {
            a: 1,
            k: [
              { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 72.667, s: [0] },
              { t: 78, s: [100] }
            ],
            ix: 1
          },
          e: {
            a: 1,
            k: [
              { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 70, s: [0] },
              { t: 74.4443359375, s: [100] }
            ],
            ix: 2
          },
          o: { a: 0, k: 0, ix: 3 },
          m: 1,
          ix: 3,
          nm: 'Trim Paths 1',
          mn: 'ADBE Vector Filter - Trim',
          hd: false
        }
      ],
      ip: 70,
      op: 79,
      st: 52,
      bm: 0
    },
    {
      ddd: 0,
      ind: 3,
      ty: 4,
      nm: 'Line3',
      sr: 1,
      ks: { o: { a: 0, k: 100, ix: 11 }, r: { a: 0, k: 1, ix: 10 }, p: { a: 0, k: [171.25, 220.562, 0], ix: 2 }, a: { a: 0, k: [-116.25, -64.938, 0], ix: 1 }, s: { a: 0, k: [100, 100, 100], ix: 6 } },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0]
                  ],
                  o: [
                    [0, 0],
                    [0, 0]
                  ],
                  v: [
                    [-115.75, -72.375],
                    [-115.75, -56.5]
                  ],
                  c: false
                },
                ix: 2
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            {
              ty: 'st',
              c: { a: 0, k: [0.3736, 0.6578, 0.9064, 1], ix: 3 },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 11, ix: 5 },
              lc: 2,
              lj: 2,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false
            },
            {
              ty: 'tr',
              p: { a: 0, k: [-1, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform'
            }
          ],
          nm: 'Shape 2',
          np: 3,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0]
                  ],
                  o: [
                    [0, 0],
                    [0, 0]
                  ],
                  v: [
                    [-115.75, -71.812],
                    [-115.75, -56.5]
                  ],
                  c: false
                },
                ix: 2
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            {
              ty: 'st',
              c: { a: 0, k: [0.2907, 0.61, 0.8893, 1], ix: 3 },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 14, ix: 5 },
              lc: 2,
              lj: 2,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform'
            }
          ],
          nm: 'Shape 1',
          np: 3,
          cix: 2,
          bm: 0,
          ix: 2,
          mn: 'ADBE Vector Group',
          hd: false
        },
        {
          ty: 'tm',
          s: {
            a: 1,
            k: [
              { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 72.667, s: [0] },
              { t: 78, s: [100] }
            ],
            ix: 1
          },
          e: {
            a: 1,
            k: [
              { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 70, s: [0] },
              { t: 74.4443359375, s: [100] }
            ],
            ix: 2
          },
          o: { a: 0, k: 0, ix: 3 },
          m: 1,
          ix: 3,
          nm: 'Trim Paths 1',
          mn: 'ADBE Vector Filter - Trim',
          hd: false
        }
      ],
      ip: 70,
      op: 79,
      st: 52,
      bm: 0
    },
    {
      ddd: 0,
      ind: 4,
      ty: 4,
      nm: 'Line 4',
      sr: 1,
      ks: { o: { a: 0, k: 100, ix: 11 }, r: { a: 0, k: -11, ix: 10 }, p: { a: 0, k: [110, 59.562, 0], ix: 2 }, a: { a: 0, k: [-116.25, -64.938, 0], ix: 1 }, s: { a: 0, k: [100, 100, 100], ix: 6 } },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0]
                  ],
                  o: [
                    [0, 0],
                    [0, 0]
                  ],
                  v: [
                    [-115.75, -72.375],
                    [-115.75, -56.5]
                  ],
                  c: false
                },
                ix: 2
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            {
              ty: 'st',
              c: { a: 0, k: [0.3736, 0.6578, 0.9064, 1], ix: 3 },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 11, ix: 5 },
              lc: 2,
              lj: 2,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false
            },
            {
              ty: 'tr',
              p: { a: 0, k: [-1, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform'
            }
          ],
          nm: 'Shape 2',
          np: 3,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0]
                  ],
                  o: [
                    [0, 0],
                    [0, 0]
                  ],
                  v: [
                    [-115.75, -71.812],
                    [-115.75, -56.5]
                  ],
                  c: false
                },
                ix: 2
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            {
              ty: 'st',
              c: { a: 0, k: [0.2907, 0.61, 0.8893, 1], ix: 3 },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 14, ix: 5 },
              lc: 2,
              lj: 2,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform'
            }
          ],
          nm: 'Shape 1',
          np: 3,
          cix: 2,
          bm: 0,
          ix: 2,
          mn: 'ADBE Vector Group',
          hd: false
        },
        {
          ty: 'tm',
          s: {
            a: 1,
            k: [
              { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 70, s: [100] },
              { t: 75.1591796875, s: [0] }
            ],
            ix: 1
          },
          e: {
            a: 1,
            k: [
              { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 72.866, s: [100] },
              { t: 78.599609375, s: [0] }
            ],
            ix: 2
          },
          o: { a: 0, k: 0, ix: 3 },
          m: 1,
          ix: 3,
          nm: 'Trim Paths 1',
          mn: 'ADBE Vector Filter - Trim',
          hd: false
        }
      ],
      ip: 70,
      op: 79,
      st: 52,
      bm: 0
    },
    {
      ddd: 0,
      ind: 5,
      ty: 4,
      nm: 'Line 3',
      sr: 1,
      ks: { o: { a: 0, k: 100, ix: 11 }, r: { a: 0, k: -44, ix: 10 }, p: { a: 0, k: [82.5, 83.312, 0], ix: 2 }, a: { a: 0, k: [-116.25, -64.938, 0], ix: 1 }, s: { a: 0, k: [100, 100, 100], ix: 6 } },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0]
                  ],
                  o: [
                    [0, 0],
                    [0, 0]
                  ],
                  v: [
                    [-115.75, -72.375],
                    [-115.75, -56.5]
                  ],
                  c: false
                },
                ix: 2
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            {
              ty: 'st',
              c: { a: 0, k: [0.3736, 0.6578, 0.9064, 1], ix: 3 },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 11, ix: 5 },
              lc: 2,
              lj: 2,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false
            },
            {
              ty: 'tr',
              p: { a: 0, k: [-1, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform'
            }
          ],
          nm: 'Shape 2',
          np: 3,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0]
                  ],
                  o: [
                    [0, 0],
                    [0, 0]
                  ],
                  v: [
                    [-115.75, -71.812],
                    [-115.75, -56.5]
                  ],
                  c: false
                },
                ix: 2
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            {
              ty: 'st',
              c: { a: 0, k: [0.2907, 0.61, 0.8893, 1], ix: 3 },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 14, ix: 5 },
              lc: 2,
              lj: 2,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform'
            }
          ],
          nm: 'Shape 1',
          np: 3,
          cix: 2,
          bm: 0,
          ix: 2,
          mn: 'ADBE Vector Group',
          hd: false
        },
        {
          ty: 'tm',
          s: {
            a: 1,
            k: [
              { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 70, s: [100] },
              { t: 75, s: [0] }
            ],
            ix: 1
          },
          e: {
            a: 1,
            k: [
              { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 72.866, s: [100] },
              { t: 78.599609375, s: [0] }
            ],
            ix: 2
          },
          o: { a: 0, k: 0, ix: 3 },
          m: 1,
          ix: 3,
          nm: 'Trim Paths 1',
          mn: 'ADBE Vector Filter - Trim',
          hd: false
        }
      ],
      ip: 70,
      op: 79,
      st: 52,
      bm: 0
    },
    {
      ddd: 0,
      ind: 6,
      ty: 4,
      nm: 'Line 2',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: -85, ix: 10 },
        p: { a: 0, k: [59.25, 110.312, 0], ix: 2 },
        a: { a: 0, k: [-116.25, -64.938, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 }
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0]
                  ],
                  o: [
                    [0, 0],
                    [0, 0]
                  ],
                  v: [
                    [-115.75, -72.375],
                    [-115.75, -56.5]
                  ],
                  c: false
                },
                ix: 2
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            {
              ty: 'st',
              c: { a: 0, k: [0.3736, 0.6578, 0.9064, 1], ix: 3 },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 11, ix: 5 },
              lc: 2,
              lj: 2,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false
            },
            {
              ty: 'tr',
              p: { a: 0, k: [-1, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform'
            }
          ],
          nm: 'Shape 2',
          np: 3,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0]
                  ],
                  o: [
                    [0, 0],
                    [0, 0]
                  ],
                  v: [
                    [-115.75, -71.812],
                    [-115.75, -56.5]
                  ],
                  c: false
                },
                ix: 2
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            {
              ty: 'st',
              c: { a: 0, k: [0.2907, 0.61, 0.8893, 1], ix: 3 },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 14, ix: 5 },
              lc: 2,
              lj: 2,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform'
            }
          ],
          nm: 'Shape 1',
          np: 3,
          cix: 2,
          bm: 0,
          ix: 2,
          mn: 'ADBE Vector Group',
          hd: false
        },
        {
          ty: 'tm',
          s: {
            a: 1,
            k: [
              { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 70.4, s: [100] },
              { t: 75.5595703125, s: [0] }
            ],
            ix: 1
          },
          e: {
            a: 1,
            k: [
              { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 73.267, s: [100] },
              { t: 79, s: [0] }
            ],
            ix: 2
          },
          o: { a: 0, k: 0, ix: 3 },
          m: 1,
          ix: 3,
          nm: 'Trim Paths 1',
          mn: 'ADBE Vector Filter - Trim',
          hd: false
        }
      ],
      ip: 70,
      op: 79,
      st: 52,
      bm: 0
    },
    {
      ddd: 0,
      ind: 7,
      ty: 4,
      nm: 'Line2',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 101, ix: 10 },
        p: { a: 0, k: [220.5, 174.062, 0], ix: 2 },
        a: { a: 0, k: [-116.25, -64.938, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 }
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0]
                  ],
                  o: [
                    [0, 0],
                    [0, 0]
                  ],
                  v: [
                    [-115.75, -72.375],
                    [-115.75, -56.5]
                  ],
                  c: false
                },
                ix: 2
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            {
              ty: 'st',
              c: { a: 0, k: [0.3736, 0.6578, 0.9064, 1], ix: 3 },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 11, ix: 5 },
              lc: 2,
              lj: 2,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false
            },
            {
              ty: 'tr',
              p: { a: 0, k: [-1, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform'
            }
          ],
          nm: 'Shape 2',
          np: 3,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0]
                  ],
                  o: [
                    [0, 0],
                    [0, 0]
                  ],
                  v: [
                    [-115.75, -71.812],
                    [-115.75, -56.5]
                  ],
                  c: false
                },
                ix: 2
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            {
              ty: 'st',
              c: { a: 0, k: [0.2907, 0.61, 0.8893, 1], ix: 3 },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 14, ix: 5 },
              lc: 2,
              lj: 2,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform'
            }
          ],
          nm: 'Shape 1',
          np: 3,
          cix: 2,
          bm: 0,
          ix: 2,
          mn: 'ADBE Vector Group',
          hd: false
        },
        {
          ty: 'tm',
          s: {
            a: 1,
            k: [
              { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 18, s: [100] },
              { t: 23.1591796875, s: [0] }
            ],
            ix: 1
          },
          e: {
            a: 1,
            k: [
              { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 20.866, s: [100] },
              { t: 26.599609375, s: [0] }
            ],
            ix: 2
          },
          o: { a: 0, k: 0, ix: 3 },
          m: 1,
          ix: 3,
          nm: 'Trim Paths 1',
          mn: 'ADBE Vector Filter - Trim',
          hd: false
        }
      ],
      ip: 18,
      op: 27,
      st: 0,
      bm: 0
    },
    {
      ddd: 0,
      ind: 8,
      ty: 4,
      nm: 'Line2',
      sr: 1,
      ks: { o: { a: 0, k: 100, ix: 11 }, r: { a: 0, k: -38, ix: 10 }, p: { a: 0, k: [201, 206.312, 0], ix: 2 }, a: { a: 0, k: [-116.25, -64.938, 0], ix: 1 }, s: { a: 0, k: [100, 100, 100], ix: 6 } },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0]
                  ],
                  o: [
                    [0, 0],
                    [0, 0]
                  ],
                  v: [
                    [-115.75, -72.375],
                    [-115.75, -56.5]
                  ],
                  c: false
                },
                ix: 2
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            {
              ty: 'st',
              c: { a: 0, k: [0.3736, 0.6578, 0.9064, 1], ix: 3 },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 11, ix: 5 },
              lc: 2,
              lj: 2,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false
            },
            {
              ty: 'tr',
              p: { a: 0, k: [-1, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform'
            }
          ],
          nm: 'Shape 2',
          np: 3,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0]
                  ],
                  o: [
                    [0, 0],
                    [0, 0]
                  ],
                  v: [
                    [-115.75, -71.812],
                    [-115.75, -56.5]
                  ],
                  c: false
                },
                ix: 2
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            {
              ty: 'st',
              c: { a: 0, k: [0.2907, 0.61, 0.8893, 1], ix: 3 },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 14, ix: 5 },
              lc: 2,
              lj: 2,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform'
            }
          ],
          nm: 'Shape 1',
          np: 3,
          cix: 2,
          bm: 0,
          ix: 2,
          mn: 'ADBE Vector Group',
          hd: false
        },
        {
          ty: 'tm',
          s: {
            a: 1,
            k: [
              { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 20.667, s: [0] },
              { t: 26, s: [100] }
            ],
            ix: 1
          },
          e: {
            a: 1,
            k: [
              { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 18, s: [0] },
              { t: 22.4443359375, s: [100] }
            ],
            ix: 2
          },
          o: { a: 0, k: 0, ix: 3 },
          m: 1,
          ix: 3,
          nm: 'Trim Paths 1',
          mn: 'ADBE Vector Filter - Trim',
          hd: false
        }
      ],
      ip: 18,
      op: 27,
      st: 0,
      bm: 0
    },
    {
      ddd: 0,
      ind: 9,
      ty: 4,
      nm: 'Line2',
      sr: 1,
      ks: { o: { a: 0, k: 100, ix: 11 }, r: { a: 0, k: 1, ix: 10 }, p: { a: 0, k: [171.25, 220.562, 0], ix: 2 }, a: { a: 0, k: [-116.25, -64.938, 0], ix: 1 }, s: { a: 0, k: [100, 100, 100], ix: 6 } },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0]
                  ],
                  o: [
                    [0, 0],
                    [0, 0]
                  ],
                  v: [
                    [-115.75, -72.375],
                    [-115.75, -56.5]
                  ],
                  c: false
                },
                ix: 2
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            {
              ty: 'st',
              c: { a: 0, k: [0.3736, 0.6578, 0.9064, 1], ix: 3 },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 11, ix: 5 },
              lc: 2,
              lj: 2,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false
            },
            {
              ty: 'tr',
              p: { a: 0, k: [-1, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform'
            }
          ],
          nm: 'Shape 2',
          np: 3,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0]
                  ],
                  o: [
                    [0, 0],
                    [0, 0]
                  ],
                  v: [
                    [-115.75, -71.812],
                    [-115.75, -56.5]
                  ],
                  c: false
                },
                ix: 2
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            {
              ty: 'st',
              c: { a: 0, k: [0.2907, 0.61, 0.8893, 1], ix: 3 },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 14, ix: 5 },
              lc: 2,
              lj: 2,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform'
            }
          ],
          nm: 'Shape 1',
          np: 3,
          cix: 2,
          bm: 0,
          ix: 2,
          mn: 'ADBE Vector Group',
          hd: false
        },
        {
          ty: 'tm',
          s: {
            a: 1,
            k: [
              { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 20.667, s: [0] },
              { t: 26, s: [100] }
            ],
            ix: 1
          },
          e: {
            a: 1,
            k: [
              { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 18, s: [0] },
              { t: 22.4443359375, s: [100] }
            ],
            ix: 2
          },
          o: { a: 0, k: 0, ix: 3 },
          m: 1,
          ix: 3,
          nm: 'Trim Paths 1',
          mn: 'ADBE Vector Filter - Trim',
          hd: false
        }
      ],
      ip: 18,
      op: 27,
      st: 0,
      bm: 0
    },
    {
      ddd: 0,
      ind: 10,
      ty: 4,
      nm: 'Line',
      sr: 1,
      ks: { o: { a: 0, k: 100, ix: 11 }, r: { a: 0, k: -11, ix: 10 }, p: { a: 0, k: [110, 59.562, 0], ix: 2 }, a: { a: 0, k: [-116.25, -64.938, 0], ix: 1 }, s: { a: 0, k: [100, 100, 100], ix: 6 } },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0]
                  ],
                  o: [
                    [0, 0],
                    [0, 0]
                  ],
                  v: [
                    [-115.75, -72.375],
                    [-115.75, -56.5]
                  ],
                  c: false
                },
                ix: 2
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            {
              ty: 'st',
              c: { a: 0, k: [0.3736, 0.6578, 0.9064, 1], ix: 3 },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 11, ix: 5 },
              lc: 2,
              lj: 2,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false
            },
            {
              ty: 'tr',
              p: { a: 0, k: [-1, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform'
            }
          ],
          nm: 'Shape 2',
          np: 3,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0]
                  ],
                  o: [
                    [0, 0],
                    [0, 0]
                  ],
                  v: [
                    [-115.75, -71.812],
                    [-115.75, -56.5]
                  ],
                  c: false
                },
                ix: 2
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            {
              ty: 'st',
              c: { a: 0, k: [0.2907, 0.61, 0.8893, 1], ix: 3 },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 14, ix: 5 },
              lc: 2,
              lj: 2,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform'
            }
          ],
          nm: 'Shape 1',
          np: 3,
          cix: 2,
          bm: 0,
          ix: 2,
          mn: 'ADBE Vector Group',
          hd: false
        },
        {
          ty: 'tm',
          s: {
            a: 1,
            k: [
              { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 18, s: [100] },
              { t: 23.1591796875, s: [0] }
            ],
            ix: 1
          },
          e: {
            a: 1,
            k: [
              { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 20.866, s: [100] },
              { t: 26.599609375, s: [0] }
            ],
            ix: 2
          },
          o: { a: 0, k: 0, ix: 3 },
          m: 1,
          ix: 3,
          nm: 'Trim Paths 1',
          mn: 'ADBE Vector Filter - Trim',
          hd: false
        }
      ],
      ip: 18,
      op: 27,
      st: 0,
      bm: 0
    },
    {
      ddd: 0,
      ind: 11,
      ty: 4,
      nm: 'Line',
      sr: 1,
      ks: { o: { a: 0, k: 100, ix: 11 }, r: { a: 0, k: -44, ix: 10 }, p: { a: 0, k: [82.5, 83.312, 0], ix: 2 }, a: { a: 0, k: [-116.25, -64.938, 0], ix: 1 }, s: { a: 0, k: [100, 100, 100], ix: 6 } },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0]
                  ],
                  o: [
                    [0, 0],
                    [0, 0]
                  ],
                  v: [
                    [-115.75, -72.375],
                    [-115.75, -56.5]
                  ],
                  c: false
                },
                ix: 2
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            {
              ty: 'st',
              c: { a: 0, k: [0.3736, 0.6578, 0.9064, 1], ix: 3 },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 11, ix: 5 },
              lc: 2,
              lj: 2,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false
            },
            {
              ty: 'tr',
              p: { a: 0, k: [-1, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform'
            }
          ],
          nm: 'Shape 2',
          np: 3,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0]
                  ],
                  o: [
                    [0, 0],
                    [0, 0]
                  ],
                  v: [
                    [-115.75, -71.812],
                    [-115.75, -56.5]
                  ],
                  c: false
                },
                ix: 2
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            {
              ty: 'st',
              c: { a: 0, k: [0.2907, 0.61, 0.8893, 1], ix: 3 },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 14, ix: 5 },
              lc: 2,
              lj: 2,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform'
            }
          ],
          nm: 'Shape 1',
          np: 3,
          cix: 2,
          bm: 0,
          ix: 2,
          mn: 'ADBE Vector Group',
          hd: false
        },
        {
          ty: 'tm',
          s: {
            a: 1,
            k: [
              { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 18, s: [100] },
              { t: 23, s: [0] }
            ],
            ix: 1
          },
          e: {
            a: 1,
            k: [
              { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 20.866, s: [100] },
              { t: 26.599609375, s: [0] }
            ],
            ix: 2
          },
          o: { a: 0, k: 0, ix: 3 },
          m: 1,
          ix: 3,
          nm: 'Trim Paths 1',
          mn: 'ADBE Vector Filter - Trim',
          hd: false
        }
      ],
      ip: 18,
      op: 27,
      st: 0,
      bm: 0
    },
    {
      ddd: 0,
      ind: 12,
      ty: 4,
      nm: 'Line',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: -85, ix: 10 },
        p: { a: 0, k: [59.25, 110.312, 0], ix: 2 },
        a: { a: 0, k: [-116.25, -64.938, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 }
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0]
                  ],
                  o: [
                    [0, 0],
                    [0, 0]
                  ],
                  v: [
                    [-115.75, -72.375],
                    [-115.75, -56.5]
                  ],
                  c: false
                },
                ix: 2
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            {
              ty: 'st',
              c: { a: 0, k: [0.3736, 0.6578, 0.9064, 1], ix: 3 },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 11, ix: 5 },
              lc: 2,
              lj: 2,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false
            },
            {
              ty: 'tr',
              p: { a: 0, k: [-1, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform'
            }
          ],
          nm: 'Shape 2',
          np: 3,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0]
                  ],
                  o: [
                    [0, 0],
                    [0, 0]
                  ],
                  v: [
                    [-115.75, -71.812],
                    [-115.75, -56.5]
                  ],
                  c: false
                },
                ix: 2
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            {
              ty: 'st',
              c: { a: 0, k: [0.2907, 0.61, 0.8893, 1], ix: 3 },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 14, ix: 5 },
              lc: 2,
              lj: 2,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform'
            }
          ],
          nm: 'Shape 1',
          np: 3,
          cix: 2,
          bm: 0,
          ix: 2,
          mn: 'ADBE Vector Group',
          hd: false
        },
        {
          ty: 'tm',
          s: {
            a: 1,
            k: [
              { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 18.4, s: [100] },
              { t: 23.5595703125, s: [0] }
            ],
            ix: 1
          },
          e: {
            a: 1,
            k: [
              { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 21.267, s: [100] },
              { t: 27, s: [0] }
            ],
            ix: 2
          },
          o: { a: 0, k: 0, ix: 3 },
          m: 1,
          ix: 3,
          nm: 'Trim Paths 1',
          mn: 'ADBE Vector Filter - Trim',
          hd: false
        }
      ],
      ip: 18,
      op: 27,
      st: 0,
      bm: 0
    },
    {
      ddd: 0,
      ind: 13,
      ty: 4,
      nm: 'Shadow',
      parent: 14,
      sr: 1,
      ks: { o: { a: 0, k: 100, ix: 11 }, r: { a: 0, k: 0, ix: 10 }, p: { a: 0, k: [-0.671, -0.993, 0], ix: 2 }, a: { a: 0, k: [0, 0, 0], ix: 1 }, s: { a: 0, k: [100, 100, 100], ix: 6 } },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 1,
                k: [
                  {
                    i: { x: 0.667, y: 1 },
                    o: { x: 0.167, y: 0.167 },
                    t: 0,
                    s: [
                      {
                        i: [
                          [-1.501, -1.746],
                          [-0.331, -0.995],
                          [1.112, 0.084],
                          [1.746, -1.501],
                          [0, 0],
                          [-1.05, -3.137],
                          [0.723, 0.841],
                          [-3.492, 3.002],
                          [0, 0],
                          [-2.141, -0.157]
                        ],
                        o: [
                          [0.723, 0.841],
                          [-1.005, -0.516],
                          [-2.141, -0.157],
                          [0, 0],
                          [-2.651, 2.279],
                          [-0.934, -0.476],
                          [-3.002, -3.492],
                          [0, 0],
                          [1.746, -1.501],
                          [2.14, 0.166]
                        ],
                        v: [
                          [23.981, -18.438],
                          [25.562, -15.657],
                          [22.355, -16.562],
                          [16.267, -14.567],
                          [-14.563, 10.571],
                          [-17.036, 19.597],
                          [-19.548, 17.616],
                          [-18.657, 5.808],
                          [12.173, -19.33],
                          [18.261, -21.324]
                        ],
                        c: true
                      }
                    ]
                  },
                  {
                    i: { x: 0.833, y: 0.833 },
                    o: { x: 0.333, y: 0 },
                    t: 14,
                    s: [
                      {
                        i: [
                          [-1.501, -1.746],
                          [-0.331, -0.995],
                          [1.112, 0.084],
                          [1.746, -1.501],
                          [0, 0],
                          [-1.05, -3.137],
                          [0.723, 0.841],
                          [-3.492, 3.002],
                          [0, 0],
                          [-2.141, -0.157]
                        ],
                        o: [
                          [0.723, 0.841],
                          [-1.005, -0.516],
                          [-2.141, -0.157],
                          [0, 0],
                          [-2.651, 2.279],
                          [-0.934, -0.476],
                          [-3.002, -3.492],
                          [0, 0],
                          [1.746, -1.501],
                          [2.14, 0.166]
                        ],
                        v: [
                          [34.793, -28.001],
                          [36.375, -25.219],
                          [33.167, -26.125],
                          [27.079, -24.13],
                          [-29.376, 23.196],
                          [-31.848, 32.222],
                          [-34.361, 30.241],
                          [-33.47, 18.433],
                          [22.986, -28.892],
                          [29.074, -30.887]
                        ],
                        c: true
                      }
                    ]
                  },
                  {
                    i: { x: 0.833, y: 0.833 },
                    o: { x: 0.167, y: 0.167 },
                    t: 18,
                    s: [
                      {
                        i: [
                          [-1.501, -1.746],
                          [-0.331, -0.995],
                          [1.112, 0.084],
                          [1.746, -1.501],
                          [0, 0],
                          [-1.05, -3.137],
                          [0.723, 0.841],
                          [-3.492, 3.002],
                          [0, 0],
                          [-2.141, -0.157]
                        ],
                        o: [
                          [0.723, 0.841],
                          [-1.005, -0.516],
                          [-2.141, -0.157],
                          [0, 0],
                          [-2.651, 2.279],
                          [-0.934, -0.476],
                          [-3.002, -3.492],
                          [0, 0],
                          [1.746, -1.501],
                          [2.14, 0.166]
                        ],
                        v: [
                          [27.731, -23.188],
                          [29.312, -20.407],
                          [26.105, -21.312],
                          [20.017, -19.317],
                          [-22.313, 17.071],
                          [-24.786, 26.097],
                          [-27.298, 24.116],
                          [-26.407, 12.308],
                          [15.923, -24.08],
                          [22.011, -26.074]
                        ],
                        c: true
                      }
                    ]
                  },
                  {
                    i: { x: 0.833, y: 0.833 },
                    o: { x: 0.167, y: 0.167 },
                    t: 47,
                    s: [
                      {
                        i: [
                          [-1.501, -1.746],
                          [-0.331, -0.995],
                          [1.112, 0.084],
                          [1.746, -1.501],
                          [0, 0],
                          [-1.05, -3.137],
                          [0.723, 0.841],
                          [-3.492, 3.002],
                          [0, 0],
                          [-2.141, -0.157]
                        ],
                        o: [
                          [0.723, 0.841],
                          [-1.005, -0.516],
                          [-2.141, -0.157],
                          [0, 0],
                          [-2.651, 2.279],
                          [-0.934, -0.476],
                          [-3.002, -3.492],
                          [0, 0],
                          [1.746, -1.501],
                          [2.14, 0.166]
                        ],
                        v: [
                          [27.731, -23.188],
                          [29.312, -20.407],
                          [26.105, -21.312],
                          [20.017, -19.317],
                          [-22.313, 17.071],
                          [-24.786, 26.097],
                          [-27.298, 24.116],
                          [-26.407, 12.308],
                          [15.923, -24.08],
                          [22.011, -26.074]
                        ],
                        c: true
                      }
                    ]
                  },
                  {
                    i: { x: 0.667, y: 1 },
                    o: { x: 0.167, y: 0.167 },
                    t: 50,
                    s: [
                      {
                        i: [
                          [-1.501, -1.746],
                          [-0.331, -0.995],
                          [1.112, 0.084],
                          [1.746, -1.501],
                          [0, 0],
                          [-1.05, -3.137],
                          [0.723, 0.841],
                          [-3.492, 3.002],
                          [0, 0],
                          [-2.141, -0.157]
                        ],
                        o: [
                          [0.723, 0.841],
                          [-1.005, -0.516],
                          [-2.141, -0.157],
                          [0, 0],
                          [-2.651, 2.279],
                          [-0.934, -0.476],
                          [-3.002, -3.492],
                          [0, 0],
                          [1.746, -1.501],
                          [2.14, 0.166]
                        ],
                        v: [
                          [23.981, -18.438],
                          [25.562, -15.657],
                          [22.355, -16.562],
                          [16.267, -14.567],
                          [-14.563, 10.571],
                          [-17.036, 19.597],
                          [-19.548, 17.616],
                          [-18.657, 5.808],
                          [12.173, -19.33],
                          [18.261, -21.324]
                        ],
                        c: true
                      }
                    ]
                  },
                  {
                    i: { x: 0.833, y: 0.833 },
                    o: { x: 0.333, y: 0 },
                    t: 64,
                    s: [
                      {
                        i: [
                          [-1.501, -1.746],
                          [-0.331, -0.995],
                          [1.112, 0.084],
                          [1.746, -1.501],
                          [0, 0],
                          [-1.05, -3.137],
                          [0.723, 0.841],
                          [-3.492, 3.002],
                          [0, 0],
                          [-2.141, -0.157]
                        ],
                        o: [
                          [0.723, 0.841],
                          [-1.005, -0.516],
                          [-2.141, -0.157],
                          [0, 0],
                          [-2.651, 2.279],
                          [-0.934, -0.476],
                          [-3.002, -3.492],
                          [0, 0],
                          [1.746, -1.501],
                          [2.14, 0.166]
                        ],
                        v: [
                          [34.793, -28.001],
                          [36.375, -25.219],
                          [33.167, -26.125],
                          [27.079, -24.13],
                          [-29.376, 23.196],
                          [-31.848, 32.222],
                          [-34.361, 30.241],
                          [-33.47, 18.433],
                          [22.986, -28.892],
                          [29.074, -30.887]
                        ],
                        c: true
                      }
                    ]
                  },
                  {
                    t: 68,
                    s: [
                      {
                        i: [
                          [-1.501, -1.746],
                          [-0.331, -0.995],
                          [1.112, 0.084],
                          [1.746, -1.501],
                          [0, 0],
                          [-1.05, -3.137],
                          [0.723, 0.841],
                          [-3.492, 3.002],
                          [0, 0],
                          [-2.141, -0.157]
                        ],
                        o: [
                          [0.723, 0.841],
                          [-1.005, -0.516],
                          [-2.141, -0.157],
                          [0, 0],
                          [-2.651, 2.279],
                          [-0.934, -0.476],
                          [-3.002, -3.492],
                          [0, 0],
                          [1.746, -1.501],
                          [2.14, 0.166]
                        ],
                        v: [
                          [27.731, -23.188],
                          [29.312, -20.407],
                          [26.105, -21.312],
                          [20.017, -19.317],
                          [-22.313, 17.071],
                          [-24.786, 26.097],
                          [-27.298, 24.116],
                          [-26.407, 12.308],
                          [15.923, -24.08],
                          [22.011, -26.074]
                        ],
                        c: true
                      }
                    ]
                  }
                ],
                ix: 2
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            { ty: 'fl', c: { a: 0, k: [0.7756, 0.887, 0.9844, 1], ix: 4 }, o: { a: 0, k: 100, ix: 5 }, r: 1, bm: 0, nm: 'Fill 1', mn: 'ADBE Vector Graphic - Fill', hd: false },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform'
            }
          ],
          nm: 'Group 1',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false
        }
      ],
      ip: 0,
      op: 2025,
      st: 0,
      bm: 0
    },
    {
      ddd: 0,
      ind: 14,
      ty: 4,
      nm: 'Clip',
      sr: 1,
      ks: { o: { a: 0, k: 100, ix: 11 }, r: { a: 0, k: 0, ix: 10 }, p: { a: 0, k: [141.851, 143.968, 0], ix: 2 }, a: { a: 0, k: [0, 0, 0], ix: 1 }, s: { a: 0, k: [100, 100, 100], ix: 6 } },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 1,
                k: [
                  {
                    i: { x: 0.667, y: 1 },
                    o: { x: 0.167, y: 0.167 },
                    t: 0,
                    s: [
                      {
                        i: [
                          [-2.085, 1.793],
                          [0, 0],
                          [1.793, 2.085],
                          [0, 0],
                          [2.085, -1.793],
                          [0, 0],
                          [-1.793, -2.085],
                          [0, 0]
                        ],
                        o: [
                          [0, 0],
                          [2.085, -1.793],
                          [0, 0],
                          [-1.793, -2.085],
                          [0, 0],
                          [-2.085, 1.793],
                          [0, 0],
                          [1.793, 2.085]
                        ],
                        v: [
                          [-11.265, 19.242],
                          [25.181, -10.043],
                          [25.714, -17.094],
                          [21.316, -22.21],
                          [14.265, -22.742],
                          [-22.181, 6.543],
                          [-22.714, 13.594],
                          [-18.316, 18.71]
                        ],
                        c: true
                      }
                    ]
                  },
                  {
                    i: { x: 0.833, y: 0.833 },
                    o: { x: 0.333, y: 0 },
                    t: 14,
                    s: [
                      {
                        i: [
                          [-2.085, 1.793],
                          [0, 0],
                          [1.793, 2.085],
                          [0, 0],
                          [2.085, -1.793],
                          [0, 0],
                          [-1.793, -2.085],
                          [0, 0]
                        ],
                        o: [
                          [0, 0],
                          [2.085, -1.793],
                          [0, 0],
                          [-1.793, -2.085],
                          [0, 0],
                          [-2.085, 1.793],
                          [0, 0],
                          [1.793, 2.085]
                        ],
                        v: [
                          [-26.765, 33.242],
                          [37.181, -20.043],
                          [37.714, -27.094],
                          [33.316, -32.21],
                          [26.265, -32.742],
                          [-37.681, 20.543],
                          [-38.214, 27.594],
                          [-33.816, 32.71]
                        ],
                        c: true
                      }
                    ]
                  },
                  {
                    i: { x: 0.833, y: 0.833 },
                    o: { x: 0.167, y: 0.167 },
                    t: 18,
                    s: [
                      {
                        i: [
                          [-2.085, 1.793],
                          [0, 0],
                          [1.793, 2.085],
                          [0, 0],
                          [2.085, -1.793],
                          [0, 0],
                          [-1.793, -2.085],
                          [0, 0]
                        ],
                        o: [
                          [0, 0],
                          [2.085, -1.793],
                          [0, 0],
                          [-1.793, -2.085],
                          [0, 0],
                          [-2.085, 1.793],
                          [0, 0],
                          [1.793, 2.085]
                        ],
                        v: [
                          [-18.265, 26.742],
                          [29.181, -14.043],
                          [29.714, -21.094],
                          [25.316, -26.21],
                          [18.265, -26.742],
                          [-29.181, 14.043],
                          [-29.714, 21.094],
                          [-25.316, 26.21]
                        ],
                        c: true
                      }
                    ]
                  },
                  {
                    i: { x: 0.833, y: 0.833 },
                    o: { x: 0.167, y: 0.167 },
                    t: 47,
                    s: [
                      {
                        i: [
                          [-2.085, 1.793],
                          [0, 0],
                          [1.793, 2.085],
                          [0, 0],
                          [2.085, -1.793],
                          [0, 0],
                          [-1.793, -2.085],
                          [0, 0]
                        ],
                        o: [
                          [0, 0],
                          [2.085, -1.793],
                          [0, 0],
                          [-1.793, -2.085],
                          [0, 0],
                          [-2.085, 1.793],
                          [0, 0],
                          [1.793, 2.085]
                        ],
                        v: [
                          [-18.265, 26.742],
                          [29.181, -14.043],
                          [29.714, -21.094],
                          [25.316, -26.21],
                          [18.265, -26.742],
                          [-29.181, 14.043],
                          [-29.714, 21.094],
                          [-25.316, 26.21]
                        ],
                        c: true
                      }
                    ]
                  },
                  {
                    i: { x: 0.667, y: 1 },
                    o: { x: 0.167, y: 0.167 },
                    t: 50,
                    s: [
                      {
                        i: [
                          [-2.085, 1.793],
                          [0, 0],
                          [1.793, 2.085],
                          [0, 0],
                          [2.085, -1.793],
                          [0, 0],
                          [-1.793, -2.085],
                          [0, 0]
                        ],
                        o: [
                          [0, 0],
                          [2.085, -1.793],
                          [0, 0],
                          [-1.793, -2.085],
                          [0, 0],
                          [-2.085, 1.793],
                          [0, 0],
                          [1.793, 2.085]
                        ],
                        v: [
                          [-11.265, 19.242],
                          [25.181, -10.043],
                          [25.714, -17.094],
                          [21.316, -22.21],
                          [14.265, -22.742],
                          [-22.181, 6.543],
                          [-22.714, 13.594],
                          [-18.316, 18.71]
                        ],
                        c: true
                      }
                    ]
                  },
                  {
                    i: { x: 0.833, y: 0.833 },
                    o: { x: 0.333, y: 0 },
                    t: 64,
                    s: [
                      {
                        i: [
                          [-2.085, 1.793],
                          [0, 0],
                          [1.793, 2.085],
                          [0, 0],
                          [2.085, -1.793],
                          [0, 0],
                          [-1.793, -2.085],
                          [0, 0]
                        ],
                        o: [
                          [0, 0],
                          [2.085, -1.793],
                          [0, 0],
                          [-1.793, -2.085],
                          [0, 0],
                          [-2.085, 1.793],
                          [0, 0],
                          [1.793, 2.085]
                        ],
                        v: [
                          [-26.765, 33.242],
                          [37.181, -20.043],
                          [37.714, -27.094],
                          [33.316, -32.21],
                          [26.265, -32.742],
                          [-37.681, 20.543],
                          [-38.214, 27.594],
                          [-33.816, 32.71]
                        ],
                        c: true
                      }
                    ]
                  },
                  {
                    t: 68,
                    s: [
                      {
                        i: [
                          [-2.085, 1.793],
                          [0, 0],
                          [1.793, 2.085],
                          [0, 0],
                          [2.085, -1.793],
                          [0, 0],
                          [-1.793, -2.085],
                          [0, 0]
                        ],
                        o: [
                          [0, 0],
                          [2.085, -1.793],
                          [0, 0],
                          [-1.793, -2.085],
                          [0, 0],
                          [-2.085, 1.793],
                          [0, 0],
                          [1.793, 2.085]
                        ],
                        v: [
                          [-18.265, 26.742],
                          [29.181, -14.043],
                          [29.714, -21.094],
                          [25.316, -26.21],
                          [18.265, -26.742],
                          [-29.181, 14.043],
                          [-29.714, 21.094],
                          [-25.316, 26.21]
                        ],
                        c: true
                      }
                    ]
                  }
                ],
                ix: 2
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            { ty: 'fl', c: { a: 0, k: [0.8884, 0.9434, 0.9916, 1], ix: 4 }, o: { a: 0, k: 100, ix: 5 }, r: 1, bm: 0, nm: 'Fill 1', mn: 'ADBE Vector Graphic - Fill', hd: false },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform'
            }
          ],
          nm: 'Group 1',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false
        }
      ],
      ip: 0,
      op: 2025,
      st: 0,
      bm: 0
    },
    {
      ddd: 0,
      ind: 15,
      ty: 4,
      nm: 'Shadow',
      parent: 16,
      sr: 1,
      ks: { o: { a: 0, k: 100, ix: 11 }, r: { a: 0, k: 0, ix: 10 }, p: { a: 0, k: [-22.296, 18.113, 0], ix: 2 }, a: { a: 0, k: [0, 0, 0], ix: 1 }, s: { a: 0, k: [100, 100, 100], ix: 6 } },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-15.281, 13.136],
                    [0, 0],
                    [13.136, 15.281],
                    [0, 0],
                    [-15.281, 13.136],
                    [0, 0],
                    [-13.136, -15.281],
                    [0, 0]
                  ],
                  o: [
                    [0, 0],
                    [-15.281, 13.136],
                    [0, 0],
                    [-13.136, -15.281],
                    [0, 0],
                    [-15.281, 13.136],
                    [0, 0],
                    [13.136, 15.281]
                  ],
                  v: [
                    [26.951, 30.793],
                    [38.063, 21.241],
                    [-13.468, 17.351],
                    [-18.135, 11.922],
                    [-14.245, -39.609],
                    [-25.357, -30.057],
                    [-29.247, 21.474],
                    [-24.58, 26.903]
                  ],
                  c: true
                },
                ix: 2
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            { ty: 'fl', c: { a: 0, k: [0.3595, 0.6694, 0.9405, 1], ix: 4 }, o: { a: 0, k: 100, ix: 5 }, r: 1, bm: 0, nm: 'Fill 1', mn: 'ADBE Vector Graphic - Fill', hd: false },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform'
            }
          ],
          nm: 'Group 1',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false
        }
      ],
      ip: 0,
      op: 2025,
      st: 0,
      bm: 0
    },
    {
      ddd: 0,
      ind: 16,
      ty: 4,
      nm: 'Gear1',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          a: 1,
          k: [
            { i: { x: 0.667, y: 1 }, o: { x: 0.167, y: 0.167 }, t: 0, s: [191.579, 99.383, 0], to: [2, -1.417, 0], ti: [-0.833, 0.333, 0] },
            { i: { x: 0.833, y: 0.833 }, o: { x: 0.333, y: 0 }, t: 14, s: [203.579, 90.883, 0], to: [0.833, -0.333, 0], ti: [1.167, -1.083, 0] },
            { i: { x: 0.833, y: 0.833 }, o: { x: 0.167, y: 0.167 }, t: 18, s: [196.579, 97.383, 0], to: [0, 0, 0], ti: [0, 0, 0] },
            { i: { x: 0.833, y: 0.833 }, o: { x: 0.167, y: 0.167 }, t: 47, s: [196.579, 97.383, 0], to: [-0.833, 0.333, 0], ti: [-1.167, 1.083, 0] },
            { i: { x: 0.667, y: 1 }, o: { x: 0.167, y: 0.167 }, t: 50, s: [191.579, 99.383, 0], to: [1.167, -1.083, 0], ti: [-0.833, 0.333, 0] },
            { i: { x: 0.833, y: 0.833 }, o: { x: 0.333, y: 0 }, t: 64, s: [203.579, 90.883, 0], to: [0.833, -0.333, 0], ti: [1.167, -1.083, 0] },
            { t: 68, s: [196.579, 97.383, 0] }
          ],
          ix: 2
        },
        a: { a: 0, k: [0, 0, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 }
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [5.61, 6.526],
                    [0, 0],
                    [6.525, -5.609],
                    [0, 0],
                    [-5.61, -6.526],
                    [0, 0],
                    [-6.525, 5.609]
                  ],
                  o: [
                    [6.525, -5.609],
                    [0, 0],
                    [-5.61, -6.526],
                    [0, 0],
                    [-6.525, 5.609],
                    [0, 0],
                    [5.61, 6.526],
                    [0, 0]
                  ],
                  v: [
                    [34.009, -3.931],
                    [35.67, -25.939],
                    [31, -31.372],
                    [8.992, -33.034],
                    [-34.009, 3.931],
                    [-35.67, 25.939],
                    [-31, 31.372],
                    [-8.992, 33.034]
                  ],
                  c: true
                },
                ix: 2
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            {
              ind: 1,
              ty: 'sh',
              ix: 2,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-15.278, 13.134],
                    [0, 0],
                    [-13.134, -15.279],
                    [0, 0],
                    [15.278, -13.134],
                    [0, 0],
                    [13.134, 15.279],
                    [0, 0]
                  ],
                  o: [
                    [0, 0],
                    [15.278, -13.134],
                    [0, 0],
                    [13.134, 15.279],
                    [0, 0],
                    [-15.278, 13.134],
                    [0, 0],
                    [-13.134, -15.279]
                  ],
                  v: [
                    [-47.655, -11.942],
                    [-4.653, -48.907],
                    [46.874, -45.017],
                    [51.544, -39.585],
                    [47.655, 11.942],
                    [4.653, 48.907],
                    [-46.874, 45.017],
                    [-51.544, 39.585]
                  ],
                  c: true
                },
                ix: 2
              },
              nm: 'Path 2',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            { ty: 'fl', c: { a: 0, k: [0.1569, 0.6039, 1, 1], ix: 4 }, o: { a: 0, k: 100, ix: 5 }, r: 1, bm: 0, nm: 'Fill 1', mn: 'ADBE Vector Graphic - Fill', hd: false },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform'
            }
          ],
          nm: 'Group 1',
          np: 3,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false
        }
      ],
      ip: 0,
      op: 2025,
      st: 0,
      bm: 0
    },
    {
      ddd: 0,
      ind: 17,
      ty: 4,
      nm: 'Shadow',
      parent: 18,
      sr: 1,
      ks: { o: { a: 0, k: 100, ix: 11 }, r: { a: 0, k: 0, ix: 10 }, p: { a: 0, k: [22.296, -18.113, 0], ix: 2 }, a: { a: 0, k: [0, 0, 0], ix: 1 }, s: { a: 0, k: [100, 100, 100], ix: 6 } },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [15.281, -13.136],
                    [0, 0],
                    [-13.136, -15.281],
                    [0, 0],
                    [15.281, -13.136],
                    [0, 0],
                    [13.136, 15.281],
                    [0, 0]
                  ],
                  o: [
                    [0, 0],
                    [15.281, -13.136],
                    [0, 0],
                    [13.136, 15.281],
                    [0, 0],
                    [15.281, -13.136],
                    [0, 0],
                    [-13.136, -15.281]
                  ],
                  v: [
                    [-26.951, -30.793],
                    [-38.063, -21.241],
                    [13.468, -17.351],
                    [18.135, -11.922],
                    [14.245, 39.609],
                    [25.357, 30.057],
                    [29.247, -21.474],
                    [24.58, -26.903]
                  ],
                  c: true
                },
                ix: 2
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            { ty: 'fl', c: { a: 0, k: [0.3595, 0.6694, 0.9405, 1], ix: 4 }, o: { a: 0, k: 100, ix: 5 }, r: 1, bm: 0, nm: 'Fill 1', mn: 'ADBE Vector Graphic - Fill', hd: false },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform'
            }
          ],
          nm: 'Group 1',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false
        }
      ],
      ip: 0,
      op: 2025,
      st: 0,
      bm: 0
    },
    {
      ddd: 0,
      ind: 18,
      ty: 4,
      nm: 'Gear2',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          a: 1,
          k: [
            { i: { x: 0.667, y: 1 }, o: { x: 0.167, y: 0.167 }, t: 0, s: [92.421, 182.117, 0], to: [-1.917, 1.583, 0], ti: [0.833, -0.75, 0] },
            { i: { x: 0.833, y: 0.833 }, o: { x: 0.333, y: 0 }, t: 14, s: [80.921, 191.617, 0], to: [-0.833, 0.75, 0], ti: [-1.083, 0.833, 0] },
            { i: { x: 0.833, y: 0.833 }, o: { x: 0.167, y: 0.167 }, t: 18, s: [87.421, 186.617, 0], to: [0, 0, 0], ti: [0, 0, 0] },
            { i: { x: 0.833, y: 0.833 }, o: { x: 0.167, y: 0.167 }, t: 47, s: [87.421, 186.617, 0], to: [0.833, -0.75, 0], ti: [1.083, -0.833, 0] },
            { i: { x: 0.667, y: 1 }, o: { x: 0.167, y: 0.167 }, t: 50, s: [92.421, 182.117, 0], to: [-1.083, 0.833, 0], ti: [0.833, -0.75, 0] },
            { i: { x: 0.833, y: 0.833 }, o: { x: 0.333, y: 0 }, t: 64, s: [80.921, 191.617, 0], to: [-0.833, 0.75, 0], ti: [-1.083, 0.833, 0] },
            { t: 68, s: [87.421, 186.617, 0] }
          ],
          ix: 2
        },
        a: { a: 0, k: [0, 0, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 }
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [-5.61, -6.526],
                    [0, 0],
                    [-6.525, 5.609],
                    [0, 0],
                    [5.61, 6.526],
                    [0, 0],
                    [6.525, -5.609]
                  ],
                  o: [
                    [-6.525, 5.609],
                    [0, 0],
                    [5.61, 6.526],
                    [0, 0],
                    [6.525, -5.609],
                    [0, 0],
                    [-5.61, -6.526],
                    [0, 0]
                  ],
                  v: [
                    [-34.009, 3.931],
                    [-35.67, 25.939],
                    [-31, 31.372],
                    [-8.992, 33.034],
                    [34.009, -3.931],
                    [35.67, -25.939],
                    [31, -31.372],
                    [8.992, -33.034]
                  ],
                  c: true
                },
                ix: 2
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            {
              ind: 1,
              ty: 'sh',
              ix: 2,
              ks: {
                a: 0,
                k: {
                  i: [
                    [15.278, -13.134],
                    [0, 0],
                    [13.134, 15.279],
                    [0, 0],
                    [-15.278, 13.134],
                    [0, 0],
                    [-13.134, -15.279],
                    [0, 0]
                  ],
                  o: [
                    [0, 0],
                    [-15.278, 13.134],
                    [0, 0],
                    [-13.134, -15.279],
                    [0, 0],
                    [15.278, -13.134],
                    [0, 0],
                    [13.134, 15.279]
                  ],
                  v: [
                    [47.655, 11.942],
                    [4.653, 48.907],
                    [-46.874, 45.017],
                    [-51.544, 39.585],
                    [-47.655, -11.942],
                    [-4.653, -48.907],
                    [46.874, -45.017],
                    [51.544, -39.585]
                  ],
                  c: true
                },
                ix: 2
              },
              nm: 'Path 2',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            { ty: 'fl', c: { a: 0, k: [0.1569, 0.6039, 1, 1], ix: 4 }, o: { a: 0, k: 100, ix: 5 }, r: 1, bm: 0, nm: 'Fill 1', mn: 'ADBE Vector Graphic - Fill', hd: false },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform'
            }
          ],
          nm: 'Group 1',
          np: 3,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false
        }
      ],
      ip: 0,
      op: 2025,
      st: 0,
      bm: 0
    }
  ],
  markers: []
};

export const shipmentLottie = {
  v: '5.8.1',
  fr: 29.9700012207031,
  ip: 0,
  op: 120.0000048877,
  w: 512,
  h: 512,
  nm: 'Comp 1',
  ddd: 0,
  assets: [],
  layers: [
    {
      ddd: 0,
      ind: 1,
      ty: 4,
      nm: 'Layer 8',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [194.5, 332.345, 0], ix: 2, l: 2 },
        a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 }
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0]
                  ],
                  o: [
                    [0, 0],
                    [0, 0]
                  ],
                  v: [
                    [33.03, 8.655],
                    [-33.03, -8.655]
                  ],
                  c: false
                },
                ix: 2
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            {
              ty: 'st',
              c: { a: 0, k: [0.0258, 0.0342, 0.0307, 1], ix: 3 },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 10, ix: 5 },
              lc: 2,
              lj: 1,
              ml: 10,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform'
            }
          ],
          nm: 'Group 1',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false
        },
        {
          ty: 'tm',
          s: {
            a: 1,
            k: [
              { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 30, s: [100] },
              { t: 40.0000016292334, s: [0] }
            ],
            ix: 1
          },
          e: { a: 0, k: 100, ix: 2 },
          o: { a: 0, k: 0, ix: 3 },
          m: 1,
          ix: 2,
          nm: 'Trim Paths 1',
          mn: 'ADBE Vector Filter - Trim',
          hd: false
        }
      ],
      ip: 0,
      op: 120.0000048877,
      st: 0,
      bm: 0
    },
    {
      ddd: 0,
      ind: 2,
      ty: 4,
      nm: 'Layer 7',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [194.5, 353.345, 0], ix: 2, l: 2 },
        a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 }
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0]
                  ],
                  o: [
                    [0, 0],
                    [0, 0]
                  ],
                  v: [
                    [33.03, 8.655],
                    [-33.03, -8.655]
                  ],
                  c: false
                },
                ix: 2
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            {
              ty: 'st',
              c: { a: 0, k: [0.0258, 0.0342, 0.0307, 1], ix: 3 },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 10, ix: 5 },
              lc: 2,
              lj: 1,
              ml: 10,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform'
            }
          ],
          nm: 'Group 1',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false
        },
        {
          ty: 'tm',
          s: {
            a: 1,
            k: [
              { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 45, s: [100] },
              { t: 55.0000022401959, s: [0] }
            ],
            ix: 1
          },
          e: { a: 0, k: 100, ix: 2 },
          o: { a: 0, k: 0, ix: 3 },
          m: 1,
          ix: 2,
          nm: 'Trim Paths 1',
          mn: 'ADBE Vector Filter - Trim',
          hd: false
        }
      ],
      ip: 0,
      op: 120.0000048877,
      st: 0,
      bm: 0
    },
    {
      ddd: 0,
      ind: 3,
      ty: 4,
      nm: 'Layer 6',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [90.314, 145, 0], ix: 2, l: 2 },
        a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 }
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [-0.621, -0.392],
                    [0, 0]
                  ],
                  o: [
                    [0, 0],
                    [-0.621, 0.392],
                    [0, 0],
                    [0, 0]
                  ],
                  v: [
                    [10.314, -13.59],
                    [-9.849, -0.845],
                    [-9.849, 0.845],
                    [10.314, 13.59]
                  ],
                  c: false
                },
                ix: 2
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            {
              ty: 'st',
              c: { a: 0, k: [0.0258, 0.0342, 0.0307, 1], ix: 3 },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 10, ix: 5 },
              lc: 2,
              lj: 1,
              ml: 10,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform'
            }
          ],
          nm: 'Group 1',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false
        },
        {
          ty: 'tm',
          s: { a: 0, k: 0, ix: 1 },
          e: {
            a: 1,
            k: [
              { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 80, s: [0] },
              { t: 85.000003462121, s: [100] }
            ],
            ix: 2
          },
          o: { a: 0, k: 0, ix: 3 },
          m: 1,
          ix: 2,
          nm: 'Trim Paths 1',
          mn: 'ADBE Vector Filter - Trim',
          hd: false
        }
      ],
      ip: 0,
      op: 120.0000048877,
      st: 0,
      bm: 0
    },
    {
      ddd: 0,
      ind: 4,
      ty: 4,
      nm: 'Layer 5',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [251.25, 376, 0], ix: 2, l: 2 },
        a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 }
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, -29.271],
                    [0, 0],
                    [-29.271, 0],
                    [0, 0]
                  ],
                  o: [
                    [0, 0],
                    [-29.271, 0],
                    [0, 0],
                    [0, 29.271],
                    [0, 0],
                    [0, 0]
                  ],
                  v: [
                    [-118.25, -56],
                    [-118.25, -56],
                    [-171.25, -3],
                    [-171.25, 3],
                    [-118.25, 56],
                    [171.25, 56]
                  ],
                  c: false
                },
                ix: 2
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            {
              ty: 'st',
              c: { a: 0, k: [0.0258, 0.0342, 0.0307, 1], ix: 3 },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 10, ix: 5 },
              lc: 2,
              lj: 1,
              ml: 10,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform'
            }
          ],
          nm: 'Group 1',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false
        },
        {
          ty: 'tm',
          s: {
            a: 1,
            k: [
              { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 55, s: [100] },
              { t: 65.0000026475043, s: [0] }
            ],
            ix: 1
          },
          e: { a: 0, k: 100, ix: 2 },
          o: { a: 0, k: 0, ix: 3 },
          m: 1,
          ix: 2,
          nm: 'Trim Paths 1',
          mn: 'ADBE Vector Filter - Trim',
          hd: false
        }
      ],
      ip: 0,
      op: 120.0000048877,
      st: 0,
      bm: 0
    },
    {
      ddd: 0,
      ind: 5,
      ty: 4,
      nm: 'Layer 4',
      parent: 6,
      sr: 1,
      ks: { o: { a: 0, k: 100, ix: 11 }, r: { a: 0, k: 0, ix: 10 }, p: { a: 0, k: [0, -21.91, 0], ix: 2, l: 2 }, a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 }, s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 } },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, -11.874],
                    [11.874, 0],
                    [0, 11.874],
                    [-11.874, 0]
                  ],
                  o: [
                    [0, 11.874],
                    [-11.874, 0],
                    [0, -11.874],
                    [11.874, 0]
                  ],
                  v: [
                    [21.5, 0],
                    [0, 21.5],
                    [-21.5, 0],
                    [0, -21.5]
                  ],
                  c: true
                },
                ix: 2
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            {
              ty: 'st',
              c: { a: 0, k: [0.0258, 0.0342, 0.0307, 1], ix: 3 },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 10, ix: 5 },
              lc: 1,
              lj: 1,
              ml: 10,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform'
            }
          ],
          nm: 'Group 1',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false
        }
      ],
      ip: 0,
      op: 120.0000048877,
      st: 0,
      bm: 0
    },
    {
      ddd: 0,
      ind: 6,
      ty: 4,
      nm: 'Layer 3',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: {
          a: 1,
          k: [
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 40, s: [0] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 50, s: [7] },
            { i: { x: [0.833], y: [1] }, o: { x: [0.333], y: [0] }, t: 60, s: [-4] },
            { t: 70.0000028511585, s: [0] }
          ],
          ix: 10
        },
        p: { a: 0, k: [257, 212.91, 0], ix: 2, l: 2 },
        a: { a: 0, k: [1, 66, 0], ix: 1, l: 2 },
        s: {
          a: 1,
          k: [
            { i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] }, o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] }, t: 25, s: [0, 0, 100] },
            { t: 40.0000016292334, s: [100, 100, 100] }
          ],
          ix: 6,
          l: 2
        }
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [1.843, 3.685],
                    [0, 0],
                    [-32.16, 0],
                    [0, 0],
                    [14.383, -28.765],
                    [0, 0]
                  ],
                  o: [
                    [0, 0],
                    [-14.383, -28.765],
                    [0, 0],
                    [32.16, 0],
                    [0, 0],
                    [-1.843, 3.685]
                  ],
                  v: [
                    [-4.472, 64.146],
                    [-38.695, -4.3],
                    [0, -66.91],
                    [0, -66.91],
                    [38.695, -4.3],
                    [4.472, 64.146]
                  ],
                  c: true
                },
                ix: 2
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            {
              ty: 'st',
              c: { a: 0, k: [0.0258, 0.0342, 0.0307, 1], ix: 3 },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 10, ix: 5 },
              lc: 1,
              lj: 1,
              ml: 10,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false
            },
            { ty: 'fl', c: { a: 0, k: [0.3216, 0.8, 0.6, 1], ix: 4 }, o: { a: 0, k: 100, ix: 5 }, r: 1, bm: 0, nm: 'Fill 1', mn: 'ADBE Vector Graphic - Fill', hd: false },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform'
            }
          ],
          nm: 'Group 1',
          np: 3,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false
        }
      ],
      ip: 0,
      op: 120.0000048877,
      st: 0,
      bm: 0
    },
    {
      ddd: 0,
      ind: 7,
      ty: 4,
      nm: 'Layer 2',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: {
          a: 1,
          k: [
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 0, s: [0] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 5, s: [8] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 10, s: [-8] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 15, s: [4] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 20, s: [-4] },
            { t: 25.0000010182709, s: [0] }
          ],
          ix: 10
        },
        p: { a: 0, k: [256, 284, 0], ix: 2, l: 2 },
        a: { a: 0, k: [256, 284, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 }
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0]
                  ],
                  o: [
                    [0, 0],
                    [0, 0]
                  ],
                  v: [
                    [0, 73.415],
                    [0, -73.415]
                  ],
                  c: false
                },
                ix: 2
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            {
              ty: 'st',
              c: { a: 0, k: [0.0258, 0.0342, 0.0307, 1], ix: 3 },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 10, ix: 5 },
              lc: 1,
              lj: 1,
              ml: 10,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false
            },
            {
              ty: 'tr',
              p: { a: 0, k: [256, 323.87], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform'
            }
          ],
          nm: 'Group 1',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [1.105, 0.327],
                    [0, 0],
                    [-5.065, 1.498],
                    [0, 0],
                    [-1.105, -0.327],
                    [0, 0],
                    [5.065, -1.498],
                    [0, 0]
                  ],
                  o: [
                    [0, 0],
                    [-5.065, -1.498],
                    [0, 0],
                    [1.105, -0.327],
                    [0, 0],
                    [5.065, 1.498],
                    [0, 0],
                    [-1.105, 0.327]
                  ],
                  v: [
                    [-1.701, 39.497],
                    [-119.201, 4.755],
                    [-119.201, -4.755],
                    [-1.701, -39.497],
                    [1.701, -39.497],
                    [119.201, -4.755],
                    [119.201, 4.755],
                    [1.701, 39.497]
                  ],
                  c: true
                },
                ix: 2
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            {
              ty: 'st',
              c: { a: 0, k: [0.0258, 0.0342, 0.0307, 1], ix: 3 },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 10, ix: 5 },
              lc: 1,
              lj: 1,
              ml: 10,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false
            },
            {
              ty: 'tr',
              p: { a: 0, k: [256, 210.457], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform'
            }
          ],
          nm: 'Group 2',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 2,
          mn: 'ADBE Vector Group',
          hd: false
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [-2.294, -0.503],
                    [0, 0],
                    [-0.706, 0.155],
                    [0, 0],
                    [0, 2.348],
                    [0, 0]
                  ],
                  o: [
                    [0, 0],
                    [0, 2.348],
                    [0, 0],
                    [0.706, 0.155],
                    [0, 0],
                    [2.294, -0.503],
                    [0, 0],
                    [0, 0]
                  ],
                  v: [
                    [-123, -93.415],
                    [-123, 62.513],
                    [-119.072, 67.397],
                    [-1.072, 93.299],
                    [1.072, 93.299],
                    [119.072, 67.397],
                    [123, 62.513],
                    [123, -93.415]
                  ],
                  c: false
                },
                ix: 2
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            {
              ty: 'st',
              c: { a: 0, k: [0.0258, 0.0342, 0.0307, 1], ix: 3 },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 10, ix: 5 },
              lc: 1,
              lj: 1,
              ml: 10,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false
            },
            {
              ty: 'tr',
              p: { a: 0, k: [256, 303.87], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform'
            }
          ],
          nm: 'Group 3',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 3,
          mn: 'ADBE Vector Group',
          hd: false
        }
      ],
      ip: 0,
      op: 120.0000048877,
      st: 0,
      bm: 0
    },
    {
      ddd: 0,
      ind: 8,
      ty: 4,
      nm: 'Layer 1',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [256.005, 209.5, 0], ix: 2, l: 2 },
        a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 }
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 29.271],
                    [0, 0],
                    [29.271, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  o: [
                    [0, 0],
                    [29.271, 0],
                    [0, 0],
                    [0, -29.271],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  v: [
                    [122.995, 64.5],
                    [122.995, 64.5],
                    [175.995, 11.5],
                    [175.995, -11.5],
                    [122.995, -64.5],
                    [37.495, -64.5],
                    [-37.505, -64.5],
                    [-175.995, -64.5]
                  ],
                  c: false
                },
                ix: 2
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            {
              ty: 'st',
              c: { a: 0, k: [0.0258, 0.0342, 0.0307, 1], ix: 3 },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 10, ix: 5 },
              lc: 1,
              lj: 1,
              ml: 10,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform'
            }
          ],
          nm: 'Group 1',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false
        },
        {
          ty: 'tm',
          s: { a: 0, k: 0, ix: 1 },
          e: {
            a: 1,
            k: [
              { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 65, s: [0] },
              { t: 80.0000032584668, s: [100] }
            ],
            ix: 2
          },
          o: { a: 0, k: 0, ix: 3 },
          m: 1,
          ix: 2,
          nm: 'Trim Paths 1',
          mn: 'ADBE Vector Filter - Trim',
          hd: false
        }
      ],
      ip: 0,
      op: 120.0000048877,
      st: 0,
      bm: 0
    }
  ],
  markers: []
};
export const deleteLottie = {
  v: '4.8.0',
  meta: { g: 'LottieFiles AE 3.0.2', a: '', k: '', d: '', tc: '' },
  fr: 24,
  ip: 0,
  op: 43,
  w: 500,
  h: 500,
  nm: '19_delete',
  ddd: 0,
  assets: [],
  layers: [
    {
      ddd: 0,
      ind: 1,
      ty: 4,
      nm: 'Layer 10',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [249.883, 303.607, 0], ix: 2 },
        a: { a: 0, k: [250.952, 287.607, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 }
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 1,
                k: [
                  {
                    i: { x: 0.667, y: 1 },
                    o: { x: 0.333, y: 0 },
                    t: 0,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [0, 0]
                        ],
                        o: [
                          [0, 0],
                          [0, 0]
                        ],
                        v: [
                          [-0.235, -66.139],
                          [0.235, 66.139]
                        ],
                        c: false
                      }
                    ]
                  },
                  {
                    i: { x: 0.667, y: 1 },
                    o: { x: 0.333, y: 0 },
                    t: 6,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [0, 0]
                        ],
                        o: [
                          [0, 0],
                          [0, 0]
                        ],
                        v: [
                          [-0.235, -43.639],
                          [0.469, 60.139]
                        ],
                        c: false
                      }
                    ]
                  },
                  {
                    i: { x: 0.667, y: 1 },
                    o: { x: 0.333, y: 0 },
                    t: 10,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [0, 0]
                        ],
                        o: [
                          [0, 0],
                          [0, 0]
                        ],
                        v: [
                          [-0.439, -129.639],
                          [0.469, 31.139]
                        ],
                        c: false
                      }
                    ]
                  },
                  {
                    i: { x: 0.667, y: 1 },
                    o: { x: 0.333, y: 0 },
                    t: 15,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [0, 0]
                        ],
                        o: [
                          [0, 0],
                          [0, 0]
                        ],
                        v: [
                          [0, -110.639],
                          [0.469, 21.639]
                        ],
                        c: false
                      }
                    ]
                  },
                  {
                    i: { x: 0.667, y: 1 },
                    o: { x: 0.333, y: 0 },
                    t: 18,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [0, 0]
                        ],
                        o: [
                          [0, 0],
                          [0, 0]
                        ],
                        v: [
                          [-0.235, -43.639],
                          [0.469, 60.139]
                        ],
                        c: false
                      }
                    ]
                  },
                  {
                    i: { x: 0.667, y: 1 },
                    o: { x: 0.333, y: 0 },
                    t: 22,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [0, 0]
                        ],
                        o: [
                          [0, 0],
                          [0, 0]
                        ],
                        v: [
                          [-0.039, -77.139],
                          [0.43, 55.139]
                        ],
                        c: false
                      }
                    ]
                  },
                  {
                    i: { x: 0.667, y: 1 },
                    o: { x: 0.333, y: 0 },
                    t: 26,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [0, 0]
                        ],
                        o: [
                          [0, 0],
                          [0, 0]
                        ],
                        v: [
                          [-0.289, -64.514],
                          [0.212, 64.702]
                        ],
                        c: false
                      }
                    ]
                  },
                  {
                    i: { x: 0.667, y: 1 },
                    o: { x: 0.333, y: 0 },
                    t: 29,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [0, 0]
                        ],
                        o: [
                          [0, 0],
                          [0, 0]
                        ],
                        v: [
                          [-0.289, -67.514],
                          [0.18, 64.764]
                        ],
                        c: false
                      }
                    ]
                  },
                  {
                    t: 32,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [0, 0]
                        ],
                        o: [
                          [0, 0],
                          [0, 0]
                        ],
                        v: [
                          [-0.235, -66.139],
                          [0.235, 66.139]
                        ],
                        c: false
                      }
                    ]
                  }
                ],
                ix: 2
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            {
              ty: 'st',
              c: { a: 0, k: [0.851, 0.2824, 0.3255, 1], ix: 3 },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 20, ix: 5 },
              lc: 2,
              lj: 2,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false
            },
            {
              ty: 'tr',
              p: { a: 0, k: [292.714, 287.607], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform'
            }
          ],
          nm: 'Group 1',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 1,
                k: [
                  {
                    i: { x: 0.667, y: 1 },
                    o: { x: 0.333, y: 0 },
                    t: 0,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [0, 0]
                        ],
                        o: [
                          [0, 0],
                          [0, 0]
                        ],
                        v: [
                          [-0.235, -66.139],
                          [0.235, 66.139]
                        ],
                        c: false
                      }
                    ]
                  },
                  {
                    i: { x: 0.667, y: 1 },
                    o: { x: 0.333, y: 0 },
                    t: 6,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [0, 0]
                        ],
                        o: [
                          [0, 0],
                          [0, 0]
                        ],
                        v: [
                          [-0.235, -43.639],
                          [0.469, 60.139]
                        ],
                        c: false
                      }
                    ]
                  },
                  {
                    i: { x: 0.667, y: 1 },
                    o: { x: 0.333, y: 0 },
                    t: 10,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [0, 0]
                        ],
                        o: [
                          [0, 0],
                          [0, 0]
                        ],
                        v: [
                          [-0.439, -129.639],
                          [0.469, 31.139]
                        ],
                        c: false
                      }
                    ]
                  },
                  {
                    i: { x: 0.667, y: 1 },
                    o: { x: 0.333, y: 0 },
                    t: 15,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [0, 0]
                        ],
                        o: [
                          [0, 0],
                          [0, 0]
                        ],
                        v: [
                          [0, -110.639],
                          [0.469, 21.639]
                        ],
                        c: false
                      }
                    ]
                  },
                  {
                    i: { x: 0.667, y: 1 },
                    o: { x: 0.333, y: 0 },
                    t: 18,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [0, 0]
                        ],
                        o: [
                          [0, 0],
                          [0, 0]
                        ],
                        v: [
                          [-0.235, -43.639],
                          [0.469, 60.139]
                        ],
                        c: false
                      }
                    ]
                  },
                  {
                    i: { x: 0.667, y: 1 },
                    o: { x: 0.333, y: 0 },
                    t: 22,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [0, 0]
                        ],
                        o: [
                          [0, 0],
                          [0, 0]
                        ],
                        v: [
                          [-0.039, -77.139],
                          [0.43, 55.139]
                        ],
                        c: false
                      }
                    ]
                  },
                  {
                    i: { x: 0.667, y: 1 },
                    o: { x: 0.333, y: 0 },
                    t: 26,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [0, 0]
                        ],
                        o: [
                          [0, 0],
                          [0, 0]
                        ],
                        v: [
                          [-0.289, -64.514],
                          [0.212, 64.702]
                        ],
                        c: false
                      }
                    ]
                  },
                  {
                    i: { x: 0.667, y: 1 },
                    o: { x: 0.333, y: 0 },
                    t: 29,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [0, 0]
                        ],
                        o: [
                          [0, 0],
                          [0, 0]
                        ],
                        v: [
                          [-0.289, -67.514],
                          [0.18, 64.764]
                        ],
                        c: false
                      }
                    ]
                  },
                  {
                    t: 32,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [0, 0]
                        ],
                        o: [
                          [0, 0],
                          [0, 0]
                        ],
                        v: [
                          [-0.235, -66.139],
                          [0.235, 66.139]
                        ],
                        c: false
                      }
                    ]
                  }
                ],
                ix: 2
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            {
              ty: 'st',
              c: { a: 0, k: [0.851, 0.2824, 0.3255, 1], ix: 3 },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 20, ix: 5 },
              lc: 2,
              lj: 2,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false
            },
            {
              ty: 'tr',
              p: { a: 0, k: [209.19, 287.607], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform'
            }
          ],
          nm: 'Group 2',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 2,
          mn: 'ADBE Vector Group',
          hd: false
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 1,
                k: [
                  {
                    i: { x: 0.667, y: 1 },
                    o: { x: 0.333, y: 0 },
                    t: 0,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [0, 0]
                        ],
                        o: [
                          [0, 0],
                          [0, 0]
                        ],
                        v: [
                          [-0.235, -66.139],
                          [0.235, 66.139]
                        ],
                        c: false
                      }
                    ]
                  },
                  {
                    i: { x: 0.667, y: 1 },
                    o: { x: 0.333, y: 0 },
                    t: 6,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [0, 0]
                        ],
                        o: [
                          [0, 0],
                          [0, 0]
                        ],
                        v: [
                          [-0.235, -43.639],
                          [0.469, 60.139]
                        ],
                        c: false
                      }
                    ]
                  },
                  {
                    i: { x: 0.667, y: 1 },
                    o: { x: 0.333, y: 0 },
                    t: 10,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [0, 0]
                        ],
                        o: [
                          [0, 0],
                          [0, 0]
                        ],
                        v: [
                          [-0.439, -129.639],
                          [0.469, 31.139]
                        ],
                        c: false
                      }
                    ]
                  },
                  {
                    i: { x: 0.667, y: 1 },
                    o: { x: 0.333, y: 0 },
                    t: 15,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [0, 0]
                        ],
                        o: [
                          [0, 0],
                          [0, 0]
                        ],
                        v: [
                          [0, -110.639],
                          [0.469, 21.639]
                        ],
                        c: false
                      }
                    ]
                  },
                  {
                    i: { x: 0.667, y: 1 },
                    o: { x: 0.333, y: 0 },
                    t: 18,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [0, 0]
                        ],
                        o: [
                          [0, 0],
                          [0, 0]
                        ],
                        v: [
                          [-0.235, -43.639],
                          [0.469, 60.139]
                        ],
                        c: false
                      }
                    ]
                  },
                  {
                    i: { x: 0.667, y: 1 },
                    o: { x: 0.333, y: 0 },
                    t: 22,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [0, 0]
                        ],
                        o: [
                          [0, 0],
                          [0, 0]
                        ],
                        v: [
                          [-0.039, -77.139],
                          [0.43, 55.139]
                        ],
                        c: false
                      }
                    ]
                  },
                  {
                    i: { x: 0.667, y: 1 },
                    o: { x: 0.333, y: 0 },
                    t: 26,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [0, 0]
                        ],
                        o: [
                          [0, 0],
                          [0, 0]
                        ],
                        v: [
                          [-0.289, -64.514],
                          [0.212, 64.702]
                        ],
                        c: false
                      }
                    ]
                  },
                  {
                    i: { x: 0.667, y: 1 },
                    o: { x: 0.333, y: 0 },
                    t: 29,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [0, 0]
                        ],
                        o: [
                          [0, 0],
                          [0, 0]
                        ],
                        v: [
                          [-0.289, -67.514],
                          [0.18, 64.764]
                        ],
                        c: false
                      }
                    ]
                  },
                  {
                    t: 32,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [0, 0]
                        ],
                        o: [
                          [0, 0],
                          [0, 0]
                        ],
                        v: [
                          [-0.235, -66.139],
                          [0.235, 66.139]
                        ],
                        c: false
                      }
                    ]
                  }
                ],
                ix: 2
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            {
              ty: 'st',
              c: { a: 0, k: [0.851, 0.2824, 0.3255, 1], ix: 3 },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 20, ix: 5 },
              lc: 2,
              lj: 2,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false
            },
            {
              ty: 'tr',
              p: { a: 0, k: [250.235, 287.607], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform'
            }
          ],
          nm: 'Group 3',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 3,
          mn: 'ADBE Vector Group',
          hd: false
        }
      ],
      ip: 0,
      op: 43,
      st: 0,
      bm: 0
    },
    {
      ddd: 0,
      ind: 2,
      ty: 4,
      nm: 'Layer 9',
      sr: 1,
      ks: { o: { a: 0, k: 100, ix: 11 }, r: { a: 0, k: 0, ix: 10 }, p: { a: 0, k: [250, 138.605, 0], ix: 2 }, a: { a: 0, k: [0, 0, 0], ix: 1 }, s: { a: 0, k: [100, 100, 100], ix: 6 } },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 1,
                k: [
                  {
                    i: { x: 0.833, y: 0.833 },
                    o: { x: 0.333, y: 0 },
                    t: 0,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [-10.033, 0],
                          [0, 0],
                          [0, -10.033]
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                          [0, -10.033],
                          [0, 0],
                          [10.033, 0],
                          [0, 0]
                        ],
                        v: [
                          [52.264, 17.161],
                          [-52.264, 17.161],
                          [-52.264, 1.006],
                          [-34.098, -17.161],
                          [34.098, -17.161],
                          [52.264, 1.006]
                        ],
                        c: true
                      }
                    ]
                  },
                  {
                    i: { x: 0.833, y: 0.833 },
                    o: { x: 0.167, y: 0.167 },
                    t: 3,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [-10.033, 0],
                          [0, 0],
                          [0, -10.033]
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                          [0, -10.033],
                          [0, 0],
                          [10.033, 0],
                          [0, 0]
                        ],
                        v: [
                          [53.971, 27.756],
                          [-55.015, 27.756],
                          [-55.078, 7.354],
                          [-36.911, -10.813],
                          [35.741, -10.813],
                          [53.908, 7.354]
                        ],
                        c: true
                      }
                    ]
                  },
                  {
                    i: { x: 0.667, y: 1 },
                    o: { x: 0.167, y: 0.167 },
                    t: 6,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [-10.033, 0],
                          [0, 0],
                          [0, -10.033]
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                          [0, -10.033],
                          [0, 0],
                          [10.033, 0],
                          [0, 0]
                        ],
                        v: [
                          [56.834, 37.399],
                          [-59.974, 37.399],
                          [-60.017, 18.496],
                          [-41.85, 0.33],
                          [38.626, 0.33],
                          [56.792, 18.496]
                        ],
                        c: true
                      }
                    ]
                  },
                  {
                    i: { x: 0.833, y: 0.833 },
                    o: { x: 0.333, y: 0 },
                    t: 7,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [-10.033, 0],
                          [0, 0],
                          [0, -10.033]
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                          [0, -10.033],
                          [0, 0],
                          [10.033, 0],
                          [0, 0]
                        ],
                        v: [
                          [57.058, 22.177],
                          [-60.471, 22.177],
                          [-60.471, 19.522],
                          [-42.304, 1.355],
                          [38.891, 1.355],
                          [57.058, 19.522]
                        ],
                        c: true
                      }
                    ]
                  },
                  {
                    i: { x: 0.833, y: 0.833 },
                    o: { x: 0.167, y: 0.167 },
                    t: 8,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [-10.033, 0],
                          [0, 0],
                          [0, -10.033]
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                          [0, -10.033],
                          [0, 0],
                          [10.033, 0],
                          [0, 0]
                        ],
                        v: [
                          [55.49, -12.457],
                          [-56.913, -12.457],
                          [-56.91, -14.159],
                          [-38.744, -32.325],
                          [37.325, -32.325],
                          [55.492, -14.159]
                        ],
                        c: true
                      }
                    ]
                  },
                  {
                    i: { x: 0.833, y: 0.833 },
                    o: { x: 0.167, y: 0.167 },
                    t: 9,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [-10.033, 0],
                          [0, 0],
                          [0, -10.033]
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                          [0, -10.033],
                          [0, 0],
                          [10.033, 0],
                          [0, 0]
                        ],
                        v: [
                          [52.407, -47.073],
                          [-50.997, -47.073],
                          [-50.99, -47.103],
                          [-32.824, -65.27],
                          [34.247, -65.27],
                          [52.414, -47.103]
                        ],
                        c: true
                      }
                    ]
                  },
                  {
                    i: { x: 0.667, y: 1 },
                    o: { x: 0.167, y: 0.167 },
                    t: 10,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [-10.033, 0],
                          [0, 0],
                          [0, -10.033]
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                          [0, -10.033],
                          [0, 0],
                          [10.033, 0],
                          [0, 0]
                        ],
                        v: [
                          [49.439, -61.583],
                          [-44.967, -61.583],
                          [-44.995, -71.073],
                          [-26.829, -89.239],
                          [31.243, -89.239],
                          [49.41, -71.073]
                        ],
                        c: true
                      }
                    ]
                  },
                  {
                    i: { x: 0.833, y: 0.833 },
                    o: { x: 0.333, y: 0 },
                    t: 11,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [-10.033, 0],
                          [0, 0],
                          [0, -10.033]
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                          [0, -10.033],
                          [0, 0],
                          [10.033, 0],
                          [0, 0]
                        ],
                        v: [
                          [47.599, -60.823],
                          [-41.679, -60.823],
                          [-41.58, -84.728],
                          [-23.413, -102.895],
                          [29.532, -102.895],
                          [47.699, -84.728]
                        ],
                        c: true
                      }
                    ]
                  },
                  {
                    i: { x: 0.667, y: 1 },
                    o: { x: 0.167, y: 0.167 },
                    t: 15,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [-10.033, 0],
                          [0, 0],
                          [0, -10.033]
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                          [0, -10.033],
                          [0, 0],
                          [10.033, 0],
                          [0, 0]
                        ],
                        v: [
                          [51.935, -31.481],
                          [-51.008, -31.481],
                          [-51.066, -52.038],
                          [-32.899, -70.205],
                          [33.711, -70.205],
                          [51.877, -52.038]
                        ],
                        c: true
                      }
                    ]
                  },
                  {
                    i: { x: 0.833, y: 0.833 },
                    o: { x: 0.333, y: 0 },
                    t: 16,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [-10.033, 0],
                          [0, 0],
                          [0, -10.033]
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                          [0, -10.033],
                          [0, 0],
                          [10.033, 0],
                          [0, 0]
                        ],
                        v: [
                          [52.474, -12.589],
                          [-52.054, -12.589],
                          [-52.167, -48.244],
                          [-34, -66.411],
                          [34.196, -66.411],
                          [52.362, -48.244]
                        ],
                        c: true
                      }
                    ]
                  },
                  {
                    i: { x: 0.833, y: 0.833 },
                    o: { x: 0.167, y: 0.167 },
                    t: 17,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [-10.033, 0],
                          [0, 0],
                          [0, -10.033]
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                          [0, -10.033],
                          [0, 0],
                          [10.033, 0],
                          [0, 0]
                        ],
                        v: [
                          [53.949, 22.794],
                          [-53.367, 22.794],
                          [-53.542, -28.05],
                          [-35.375, -46.217],
                          [35.607, -46.217],
                          [53.774, -28.05]
                        ],
                        c: true
                      }
                    ]
                  },
                  {
                    i: { x: 0.667, y: 1 },
                    o: { x: 0.167, y: 0.167 },
                    t: 18,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [-10.033, 0],
                          [0, 0],
                          [0, -10.033]
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                          [0, -10.033],
                          [0, 0],
                          [10.033, 0],
                          [0, 0]
                        ],
                        v: [
                          [56.278, 41.483],
                          [-56.111, 41.483],
                          [-56.045, 8.707],
                          [-37.878, -9.46],
                          [38.177, -9.46],
                          [56.344, 8.707]
                        ],
                        c: true
                      }
                    ]
                  },
                  {
                    i: { x: 0.833, y: 0.833 },
                    o: { x: 0.333, y: 0 },
                    t: 19,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [-10.033, 0],
                          [0, 0],
                          [0, -10.033]
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                          [0, -10.033],
                          [0, 0],
                          [10.033, 0],
                          [0, 0]
                        ],
                        v: [
                          [57.808, 32.677],
                          [-57.471, 32.677],
                          [-57.471, 29.647],
                          [-39.304, 11.48],
                          [39.641, 11.48],
                          [57.808, 29.647]
                        ],
                        c: true
                      }
                    ]
                  },
                  {
                    i: { x: 0.833, y: 0.833 },
                    o: { x: 0.167, y: 0.167 },
                    t: 20,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [-10.033, 0],
                          [0, 0],
                          [0, -10.033]
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                          [0, -10.033],
                          [0, 0],
                          [10.033, 0],
                          [0, 0]
                        ],
                        v: [
                          [56.08, 10.872],
                          [-55.292, 10.872],
                          [-55.263, 10.213],
                          [-37.096, -7.953],
                          [37.943, -7.953],
                          [56.11, 10.213]
                        ],
                        c: true
                      }
                    ]
                  },
                  {
                    i: { x: 0.833, y: 0.833 },
                    o: { x: 0.167, y: 0.167 },
                    t: 21,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [-10.033, 0],
                          [0, 0],
                          [0, -10.033]
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                          [0, -10.033],
                          [0, 0],
                          [10.033, 0],
                          [0, 0]
                        ],
                        v: [
                          [52.262, -4.867],
                          [-50.02, -4.867],
                          [-49.997, -7.517],
                          [-31.831, -25.684],
                          [34.119, -25.684],
                          [52.285, -7.517]
                        ],
                        c: true
                      }
                    ]
                  },
                  {
                    i: { x: 0.667, y: 1 },
                    o: { x: 0.167, y: 0.167 },
                    t: 22,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [-10.033, 0],
                          [0, 0],
                          [0, -10.033]
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                          [0, -10.033],
                          [0, 0],
                          [10.033, 0],
                          [0, 0]
                        ],
                        v: [
                          [49.05, -13.857],
                          [-45.585, -13.857],
                          [-45.521, -24.852],
                          [-27.354, -43.019],
                          [30.948, -43.019],
                          [49.114, -24.852]
                        ],
                        c: true
                      }
                    ]
                  },
                  {
                    i: { x: 0.833, y: 0.833 },
                    o: { x: 0.333, y: 0 },
                    t: 23,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [-10.033, 0],
                          [0, 0],
                          [0, -10.033]
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                          [0, -10.033],
                          [0, 0],
                          [10.033, 0],
                          [0, 0]
                        ],
                        v: [
                          [47.308, -8.323],
                          [-42.971, -8.323],
                          [-42.971, -34.728],
                          [-24.804, -52.895],
                          [29.141, -52.895],
                          [47.308, -34.728]
                        ],
                        c: true
                      }
                    ]
                  },
                  {
                    i: { x: 0.833, y: 0.833 },
                    o: { x: 0.167, y: 0.167 },
                    t: 24,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [-10.033, 0],
                          [0, 0],
                          [0, -10.033]
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                          [0, -10.033],
                          [0, 0],
                          [10.033, 0],
                          [0, 0]
                        ],
                        v: [
                          [48.461, 1.911],
                          [-44.552, 1.911],
                          [-44.655, -28.986],
                          [-26.489, -47.153],
                          [30.191, -47.153],
                          [48.358, -28.986]
                        ],
                        c: true
                      }
                    ]
                  },
                  {
                    i: { x: 0.833, y: 0.833 },
                    o: { x: 0.167, y: 0.167 },
                    t: 25,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [-10.033, 0],
                          [0, 0],
                          [0, -10.033]
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                          [0, -10.033],
                          [0, 0],
                          [10.033, 0],
                          [0, 0]
                        ],
                        v: [
                          [50.834, 14.489],
                          [-48.543, 14.489],
                          [-48.575, -15.624],
                          [-30.408, -33.79],
                          [32.635, -33.79],
                          [50.802, -15.624]
                        ],
                        c: true
                      }
                    ]
                  },
                  {
                    i: { x: 0.667, y: 1 },
                    o: { x: 0.167, y: 0.167 },
                    t: 26,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [-10.033, 0],
                          [0, 0],
                          [0, -10.033]
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                          [0, -10.033],
                          [0, 0],
                          [10.033, 0],
                          [0, 0]
                        ],
                        v: [
                          [52.845, 19.014],
                          [-51.884, 19.014],
                          [-51.872, -4.382],
                          [-33.705, -22.549],
                          [34.691, -22.549],
                          [52.858, -4.382]
                        ],
                        c: true
                      }
                    ]
                  },
                  {
                    i: { x: 0.833, y: 0.833 },
                    o: { x: 0.333, y: 0 },
                    t: 27,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [-10.033, 0],
                          [0, 0],
                          [0, -10.033]
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                          [0, -10.033],
                          [0, 0],
                          [10.033, 0],
                          [0, 0]
                        ],
                        v: [
                          [54.014, 17.177],
                          [-53.764, 17.177],
                          [-53.75, 2.022],
                          [-35.583, -16.145],
                          [35.862, -16.145],
                          [54.029, 2.022]
                        ],
                        c: true
                      }
                    ]
                  },
                  {
                    i: { x: 0.833, y: 0.833 },
                    o: { x: 0.167, y: 0.167 },
                    t: 28,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [-10.033, 0],
                          [0, 0],
                          [0, -10.033]
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                          [0, -10.033],
                          [0, 0],
                          [10.033, 0],
                          [0, 0]
                        ],
                        v: [
                          [53.107, 14.346],
                          [-52.403, 14.346],
                          [-52.371, -2.316],
                          [-34.205, -20.483],
                          [34.973, -20.483],
                          [53.139, -2.316]
                        ],
                        c: true
                      }
                    ]
                  },
                  {
                    i: { x: 0.667, y: 1 },
                    o: { x: 0.167, y: 0.167 },
                    t: 29,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [-10.033, 0],
                          [0, 0],
                          [0, -10.033]
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                          [0, -10.033],
                          [0, 0],
                          [10.033, 0],
                          [0, 0]
                        ],
                        v: [
                          [51.266, 12.396],
                          [-50.115, 12.396],
                          [-49.996, -5.883],
                          [-31.829, -24.05],
                          [33.219, -24.05],
                          [51.385, -5.883]
                        ],
                        c: true
                      }
                    ]
                  },
                  {
                    i: { x: 0.667, y: 1 },
                    o: { x: 0.333, y: 0 },
                    t: 30,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [-10.033, 0],
                          [0, 0],
                          [0, -10.033]
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                          [0, -10.033],
                          [0, 0],
                          [10.033, 0],
                          [0, 0]
                        ],
                        v: [
                          [50.303, 13.052],
                          [-48.726, 13.052],
                          [-48.643, -7.916],
                          [-30.476, -26.082],
                          [32.22, -26.082],
                          [50.386, -7.916]
                        ],
                        c: true
                      }
                    ]
                  },
                  {
                    t: 33,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [-10.033, 0],
                          [0, 0],
                          [0, -10.033]
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                          [0, -10.033],
                          [0, 0],
                          [10.033, 0],
                          [0, 0]
                        ],
                        v: [
                          [52.264, 17.161],
                          [-52.264, 17.161],
                          [-52.264, 1.006],
                          [-34.098, -17.161],
                          [34.098, -17.161],
                          [52.264, 1.006]
                        ],
                        c: true
                      }
                    ]
                  }
                ],
                ix: 2
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            {
              ty: 'st',
              c: { a: 0, k: [0.851, 0.2824, 0.3255, 1], ix: 3 },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 20, ix: 5 },
              lc: 2,
              lj: 2,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform'
            }
          ],
          nm: 'Group 1',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false
        }
      ],
      ip: 0,
      op: 43,
      st: 0,
      bm: 0
    },
    {
      ddd: 0,
      ind: 3,
      ty: 4,
      nm: 'Layer 12',
      sr: 1,
      ks: { o: { a: 0, k: 100, ix: 11 }, r: { a: 0, k: 0, ix: 10 }, p: { a: 0, k: [248.723, 182.466, 0], ix: 2 }, a: { a: 0, k: [0, 0, 0], ix: 1 }, s: { a: 0, k: [100, 100, 100], ix: 6 } },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 1,
                k: [
                  {
                    i: { x: 0.667, y: 1 },
                    o: { x: 0.333, y: 0 },
                    t: 0,
                    s: [
                      {
                        i: [
                          [9.92, 0],
                          [0, 0],
                          [0, 9.92],
                          [0, 0],
                          [-9.92, 0],
                          [0, 0],
                          [0, -9.92],
                          [0, 0]
                        ],
                        o: [
                          [0, 0],
                          [-9.92, 0],
                          [0, 0],
                          [0, -9.92],
                          [0, 0],
                          [9.92, 0],
                          [0, 0],
                          [0, 9.92]
                        ],
                        v: [
                          [97.098, 19.325],
                          [-96.98, 19.325],
                          [-114.941, 1.364],
                          [-114.941, -8.614],
                          [-96.98, -26.575],
                          [97.098, -26.575],
                          [115.059, -8.614],
                          [115.059, 1.364]
                        ],
                        c: true
                      }
                    ]
                  },
                  {
                    i: { x: 0.667, y: 1 },
                    o: { x: 0.333, y: 0 },
                    t: 6,
                    s: [
                      {
                        i: [
                          [9.92, 0],
                          [0, 0],
                          [0, 9.92],
                          [0, 0],
                          [-9.92, 0],
                          [0, 0],
                          [0, -9.92],
                          [0, 0]
                        ],
                        o: [
                          [0, 0],
                          [-9.92, 0],
                          [0, 0],
                          [0, -9.92],
                          [0, 0],
                          [9.92, 0],
                          [0, 0],
                          [0, 9.92]
                        ],
                        v: [
                          [120.051, 34.132],
                          [-115.027, 33.701],
                          [-132.988, 15.74],
                          [-133.012, 12.511],
                          [-115.051, -5.45],
                          [120.027, -5.019],
                          [137.988, 12.942],
                          [138.012, 16.171]
                        ],
                        c: true
                      }
                    ]
                  },
                  {
                    i: { x: 0.667, y: 1 },
                    o: { x: 0.333, y: 0 },
                    t: 10,
                    s: [
                      {
                        i: [
                          [9.92, 0],
                          [0, 0],
                          [0, 9.92],
                          [0, 0],
                          [-9.92, 0],
                          [0, 0],
                          [0, -9.92],
                          [0, 0]
                        ],
                        o: [
                          [0, 0],
                          [-9.92, 0],
                          [0, 0],
                          [0, -9.92],
                          [0, 0],
                          [9.92, 0],
                          [0, 0],
                          [0, 9.92]
                        ],
                        v: [
                          [83.551, -48.322],
                          [-84.527, -48.383],
                          [-102.488, -66.344],
                          [-102.488, -86.572],
                          [-84.527, -104.534],
                          [83.551, -104.473],
                          [101.512, -86.512],
                          [101.512, -66.284]
                        ],
                        c: true
                      }
                    ]
                  },
                  {
                    i: { x: 0.667, y: 1 },
                    o: { x: 0.333, y: 0 },
                    t: 15,
                    s: [
                      {
                        i: [
                          [9.92, 0],
                          [0, 0],
                          [0, 9.92],
                          [0, 0],
                          [-9.92, 0],
                          [0, 0],
                          [0, -9.92],
                          [0, 0]
                        ],
                        o: [
                          [0, 0],
                          [-9.92, 0],
                          [0, 0],
                          [0, -9.92],
                          [0, 0],
                          [9.92, 0],
                          [0, 0],
                          [0, 9.92]
                        ],
                        v: [
                          [97.128, -29.8],
                          [-96.949, -29.8],
                          [-114.911, -47.761],
                          [-114.911, -57.739],
                          [-96.949, -75.7],
                          [97.128, -75.7],
                          [115.089, -57.739],
                          [115.089, -47.761]
                        ],
                        c: true
                      }
                    ]
                  },
                  {
                    i: { x: 0.833, y: 0.833 },
                    o: { x: 0.333, y: 0 },
                    t: 18,
                    s: [
                      {
                        i: [
                          [9.92, 0],
                          [0, 0],
                          [0, 9.92],
                          [0, 0],
                          [-9.92, 0],
                          [0, 0],
                          [0, -9.92],
                          [0, 0]
                        ],
                        o: [
                          [0, 0],
                          [-9.92, 0],
                          [0, 0],
                          [0, -9.92],
                          [0, 0],
                          [9.92, 0],
                          [0, 0],
                          [0, 9.92]
                        ],
                        v: [
                          [120.578, 37.86],
                          [-119.25, 37.951],
                          [-137.211, 19.99],
                          [-137.235, 16.761],
                          [-119.274, -1.2],
                          [120.554, -1.291],
                          [138.515, 16.671],
                          [138.539, 19.899]
                        ],
                        c: true
                      }
                    ]
                  },
                  {
                    i: { x: 0.667, y: 1 },
                    o: { x: 0.167, y: 0.167 },
                    t: 20,
                    s: [
                      {
                        i: [
                          [9.92, 0],
                          [0, 0],
                          [0, 9.92],
                          [0, 0],
                          [-9.92, 0],
                          [0, 0],
                          [0, -9.92],
                          [0, 0]
                        ],
                        o: [
                          [0, 0],
                          [-9.92, 0],
                          [0, 0],
                          [0, -9.92],
                          [0, 0],
                          [9.92, 0],
                          [0, 0],
                          [0, 9.92]
                        ],
                        v: [
                          [104.547, 16.821],
                          [-99.53, 16.909],
                          [-117.491, -1.052],
                          [-117.448, -13.531],
                          [-99.487, -31.492],
                          [104.591, -31.58],
                          [122.552, -13.619],
                          [122.509, -1.14]
                        ],
                        c: true
                      }
                    ]
                  },
                  {
                    i: { x: 0.667, y: 1 },
                    o: { x: 0.333, y: 0 },
                    t: 22,
                    s: [
                      {
                        i: [
                          [9.92, 0],
                          [0, 0],
                          [0, 9.92],
                          [0, 0],
                          [-9.92, 0],
                          [0, 0],
                          [0, -9.92],
                          [0, 0]
                        ],
                        o: [
                          [0, 0],
                          [-9.92, 0],
                          [0, 0],
                          [0, -9.92],
                          [0, 0],
                          [9.92, 0],
                          [0, 0],
                          [0, 9.92]
                        ],
                        v: [
                          [85.747, 1.317],
                          [-79.706, 1.367],
                          [-97.667, -16.594],
                          [-97.557, -38.322],
                          [-79.596, -56.284],
                          [85.857, -56.334],
                          [103.818, -38.372],
                          [103.708, -16.644]
                        ],
                        c: true
                      }
                    ]
                  },
                  {
                    i: { x: 0.667, y: 1 },
                    o: { x: 0.333, y: 0 },
                    t: 26,
                    s: [
                      {
                        i: [
                          [9.92, 0],
                          [0, 0],
                          [0, 9.92],
                          [0, 0],
                          [-9.92, 0],
                          [0, 0],
                          [0, -9.92],
                          [0, 0]
                        ],
                        o: [
                          [0, 0],
                          [-9.92, 0],
                          [0, 0],
                          [0, -9.92],
                          [0, 0],
                          [9.92, 0],
                          [0, 0],
                          [0, 9.92]
                        ],
                        v: [
                          [109.301, 17.906],
                          [-100.777, 17.992],
                          [-118.738, 0.031],
                          [-118.753, -6.385],
                          [-100.792, -24.346],
                          [109.286, -24.432],
                          [127.247, -6.471],
                          [127.262, -0.055]
                        ],
                        c: true
                      }
                    ]
                  },
                  {
                    i: { x: 0.667, y: 1 },
                    o: { x: 0.333, y: 0 },
                    t: 29,
                    s: [
                      {
                        i: [
                          [9.92, 0],
                          [0, 0],
                          [0, 9.92],
                          [0, 0],
                          [-9.92, 0],
                          [0, 0],
                          [0, -9.92],
                          [0, 0]
                        ],
                        o: [
                          [0, 0],
                          [-9.92, 0],
                          [0, 0],
                          [0, -9.92],
                          [0, 0],
                          [9.92, 0],
                          [0, 0],
                          [0, 9.92]
                        ],
                        v: [
                          [94.324, 17.781],
                          [-94.253, 17.867],
                          [-112.214, -0.094],
                          [-112.203, -13.072],
                          [-94.241, -31.034],
                          [94.336, -31.12],
                          [112.298, -13.159],
                          [112.286, -0.18]
                        ],
                        c: true
                      }
                    ]
                  },
                  {
                    t: 32,
                    s: [
                      {
                        i: [
                          [9.92, 0],
                          [0, 0],
                          [0, 9.92],
                          [0, 0],
                          [-9.92, 0],
                          [0, 0],
                          [0, -9.92],
                          [0, 0]
                        ],
                        o: [
                          [0, 0],
                          [-9.92, 0],
                          [0, 0],
                          [0, -9.92],
                          [0, 0],
                          [9.92, 0],
                          [0, 0],
                          [0, 9.92]
                        ],
                        v: [
                          [97.098, 19.325],
                          [-96.98, 19.325],
                          [-114.941, 1.364],
                          [-114.941, -8.614],
                          [-96.98, -26.575],
                          [97.098, -26.575],
                          [115.059, -8.614],
                          [115.059, 1.364]
                        ],
                        c: true
                      }
                    ]
                  }
                ],
                ix: 2
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            {
              ty: 'st',
              c: { a: 0, k: [0.851, 0.2824, 0.3255, 1], ix: 3 },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 20, ix: 5 },
              lc: 2,
              lj: 2,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform'
            }
          ],
          nm: 'Group 1',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false
        }
      ],
      ip: 0,
      op: 43,
      st: 0,
      bm: 0
    },
    {
      ddd: 0,
      ind: 4,
      ty: 4,
      nm: 'Layer 2',
      sr: 1,
      ks: { o: { a: 0, k: 100, ix: 11 }, r: { a: 0, k: 0, ix: 10 }, p: { a: 0, k: [250, 306.111, 0], ix: 2 }, a: { a: 0, k: [0, 0, 0], ix: 1 }, s: { a: 0, k: [100, 100, 100], ix: 6 } },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 1,
                k: [
                  {
                    i: { x: 0.667, y: 1 },
                    o: { x: 0.333, y: 0 },
                    t: 0,
                    s: [
                      {
                        i: [
                          [19.054, 0],
                          [0, 0],
                          [0, 19.054],
                          [0, 0],
                          [0, 0],
                          [0, 0]
                        ],
                        o: [
                          [0, 0],
                          [-19.054, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 19.054]
                        ],
                        v: [
                          [60.64, 103.694],
                          [-60.64, 103.694],
                          [-95.14, 69.194],
                          [-95.14, -103.694],
                          [95.14, -103.694],
                          [95.14, 69.194]
                        ],
                        c: true
                      }
                    ]
                  },
                  {
                    i: { x: 0.667, y: 1 },
                    o: { x: 0.333, y: 0 },
                    t: 6,
                    s: [
                      {
                        i: [
                          [19.054, 0],
                          [0, 0],
                          [0, 19.054],
                          [0, 0],
                          [0, 0],
                          [0, 0]
                        ],
                        o: [
                          [0, 0],
                          [-19.054, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 19.054]
                        ],
                        v: [
                          [72.64, 104],
                          [-73.64, 103.5],
                          [-108.14, 69],
                          [-108, -88.889],
                          [107.28, -88.389],
                          [107.14, 69.5]
                        ],
                        c: true
                      }
                    ]
                  },
                  {
                    i: { x: 0.667, y: 1 },
                    o: { x: 0.333, y: 0 },
                    t: 10,
                    s: [
                      {
                        i: [
                          [19.054, 0],
                          [0, 0],
                          [0, 19.054],
                          [0, 0],
                          [0, 0],
                          [0, 0]
                        ],
                        o: [
                          [0, 0],
                          [-19.054, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 19.054]
                        ],
                        v: [
                          [52.78, 78],
                          [-53.5, 77.5],
                          [-88, 43],
                          [-87.44, -170.889],
                          [87.84, -170.389],
                          [87.28, 43.5]
                        ],
                        c: true
                      }
                    ]
                  },
                  {
                    i: { x: 0.667, y: 1 },
                    o: { x: 0.333, y: 0 },
                    t: 15,
                    s: [
                      {
                        i: [
                          [19.054, 0],
                          [0, 0],
                          [0, 19.054],
                          [0, 0],
                          [0, 0],
                          [0, 0]
                        ],
                        o: [
                          [0, 0],
                          [-19.054, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 19.054]
                        ],
                        v: [
                          [60.78, 56.694],
                          [-60.5, 56.694],
                          [-95, 22.194],
                          [-95, -150.694],
                          [95.28, -150.694],
                          [95.28, 22.194]
                        ],
                        c: true
                      }
                    ]
                  },
                  {
                    i: { x: 0.667, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 18,
                    s: [
                      {
                        i: [
                          [19.054, 0],
                          [0, 0],
                          [0, 19.054],
                          [0, 0],
                          [0, 0],
                          [0, 0]
                        ],
                        o: [
                          [0, 0],
                          [-19.054, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 19.054]
                        ],
                        v: [
                          [75.265, 103.986],
                          [-78.14, 103.611],
                          [-112.64, 69.111],
                          [-112.595, -84.528],
                          [109.81, -84.153],
                          [109.765, 69.486]
                        ],
                        c: true
                      }
                    ]
                  },
                  {
                    i: { x: 0.667, y: 1 },
                    o: { x: 0.333, y: 0 },
                    t: 22,
                    s: [
                      {
                        i: [
                          [19.054, 0],
                          [0, 0],
                          [0, 19.054],
                          [0, 0],
                          [0, 0],
                          [0, 0]
                        ],
                        o: [
                          [0, 0],
                          [-19.054, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 19.054]
                        ],
                        v: [
                          [55.155, 97],
                          [-52.375, 97],
                          [-86.875, 62.5],
                          [-86.97, -121.389],
                          [89.56, -121.389],
                          [89.655, 62.5]
                        ],
                        c: true
                      }
                    ]
                  },
                  {
                    i: { x: 0.667, y: 1 },
                    o: { x: 0.333, y: 0 },
                    t: 26,
                    s: [
                      {
                        i: [
                          [19.054, 0],
                          [0, 0],
                          [0, 19.054],
                          [0, 0],
                          [0, 0],
                          [0, 0]
                        ],
                        o: [
                          [0, 0],
                          [-19.054, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 19.054]
                        ],
                        v: [
                          [62.265, 103.75],
                          [-61.64, 103.75],
                          [-96.14, 69.25],
                          [-96.125, -105.639],
                          [96.78, -105.639],
                          [96.765, 69.25]
                        ],
                        c: true
                      }
                    ]
                  },
                  {
                    i: { x: 0.667, y: 1 },
                    o: { x: 0.333, y: 0 },
                    t: 29,
                    s: [
                      {
                        i: [
                          [19.054, 0],
                          [0, 0],
                          [0, 19.054],
                          [0, 0],
                          [0, 0],
                          [0, 0]
                        ],
                        o: [
                          [0, 0],
                          [-19.054, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 19.054]
                        ],
                        v: [
                          [59.89, 103.687],
                          [-60.265, 103.687],
                          [-94.765, 69.187],
                          [-94.75, -105.483],
                          [94.405, -105.483],
                          [94.39, 69.187]
                        ],
                        c: true
                      }
                    ]
                  },
                  {
                    t: 32,
                    s: [
                      {
                        i: [
                          [19.054, 0],
                          [0, 0],
                          [0, 19.054],
                          [0, 0],
                          [0, 0],
                          [0, 0]
                        ],
                        o: [
                          [0, 0],
                          [-19.054, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 19.054]
                        ],
                        v: [
                          [60.64, 103.694],
                          [-60.64, 103.694],
                          [-95.14, 69.194],
                          [-95.14, -103.694],
                          [95.14, -103.694],
                          [95.14, 69.194]
                        ],
                        c: true
                      }
                    ]
                  }
                ],
                ix: 2
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            {
              ty: 'st',
              c: { a: 0, k: [0.851, 0.2824, 0.3255, 1], ix: 3 },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 20, ix: 5 },
              lc: 2,
              lj: 2,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform'
            }
          ],
          nm: 'Group 1',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false
        }
      ],
      ip: 0,
      op: 43,
      st: 0,
      bm: 0
    }
  ],
  markers: []
};
export const recoverLottie = {
  v: '5.12.2',
  fr: 30,
  ip: 0,
  op: 71,
  w: 128,
  h: 128,
  nm: 'Settings_Backup_Restore_lottie',
  ddd: 0,
  assets: [],
  layers: [
    {
      ddd: 0,
      ind: 1,
      ty: 3,
      nm: 'Null 3',
      sr: 1,
      ks: {
        o: { a: 0, k: 0, ix: 11 },
        r: { a: 0, k: 47, ix: 10 },
        p: { a: 0, k: [64.25, 68.75, 0], ix: 2, l: 2 },
        a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
        s: { a: 0, k: [-100, 100, 100], ix: 6, l: 2 }
      },
      ao: 0,
      ip: 0,
      op: 241,
      st: 0,
      bm: 0
    },
    {
      ddd: 0,
      ind: 2,
      ty: 3,
      nm: 'Null 2',
      parent: 1,
      sr: 1,
      ks: {
        o: { a: 0, k: 0, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [0, 0, 0], ix: 2, l: 2 },
        a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
        s: {
          a: 1,
          k: [
            { i: { x: [0.19, 0.19, 0.19], y: [1, 1, 1] }, o: { x: [0.81, 0.81, 0.81], y: [0, 0, 0] }, t: 13, s: [100, 100, 100] },
            { i: { x: [0.19, 0.19, 0.19], y: [1, 1, 1] }, o: { x: [0.81, 0.81, 0.81], y: [0, 0, 0] }, t: 34.891, s: [80, 80, 100] },
            { t: 56, s: [100, 100, 100] }
          ],
          ix: 6,
          l: 2
        }
      },
      ao: 0,
      ip: 0,
      op: 241,
      st: 0,
      bm: 0
    },
    {
      ddd: 0,
      ind: 3,
      ty: 3,
      nm: 'Null 1',
      parent: 2,
      sr: 1,
      ks: {
        o: { a: 0, k: 0, ix: 11 },
        r: {
          a: 1,
          k: [
            { i: { x: [0.19], y: [1] }, o: { x: [0.81], y: [0] }, t: 9.168, s: [0] },
            { i: { x: [0.19], y: [1] }, o: { x: [0.81], y: [0] }, t: 37.082, s: [21] },
            { i: { x: [0.19], y: [1] }, o: { x: [0.81], y: [0] }, t: 50.146, s: [-29] },
            { t: 64, s: [0] }
          ],
          ix: 10
        },
        p: { a: 0, k: [0, 0, 0], ix: 2, l: 2 },
        a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 }
      },
      ao: 0,
      ip: 0,
      op: 241,
      st: 0,
      bm: 0
    },
    {
      ddd: 0,
      ind: 4,
      ty: 4,
      nm: 'Layer 2 Outlines 2',
      parent: 5,
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [68.552, 18.464, 0], ix: 2, l: 2 },
        a: { a: 0, k: [17.13, 17.13, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 }
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 1,
                k: [
                  {
                    i: { x: 0.19, y: 1 },
                    o: { x: 0.81, y: 0 },
                    t: 6.451,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [0, 0],
                          [0, 6.627],
                          [0, 0]
                        ],
                        o: [
                          [0, 0],
                          [6.627, 0],
                          [0, 0],
                          [0, 0]
                        ],
                        v: [
                          [-10.13, 10.13],
                          [-1.87, 10.13],
                          [10.13, -1.87],
                          [10.13, -10.13]
                        ],
                        c: false
                      }
                    ]
                  },
                  {
                    i: { x: 0.19, y: 1 },
                    o: { x: 0.81, y: 0 },
                    t: 26.7,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [0, 0],
                          [2.333, 4.919],
                          [0, 0]
                        ],
                        o: [
                          [0, 0],
                          [6.33, -0.208],
                          [0, 0],
                          [0, 0]
                        ],
                        v: [
                          [-6.672, 2.725],
                          [1.26, 2.938],
                          [5.057, -2.039],
                          [1.398, -9.704]
                        ],
                        c: false
                      }
                    ]
                  },
                  {
                    i: { x: 0.19, y: 1 },
                    o: { x: 0.81, y: 0 },
                    t: 39.008,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [-4.831, 0.917],
                          [-0.567, 4.432],
                          [0, 0]
                        ],
                        o: [
                          [0, 0],
                          [5.448, -1.034],
                          [0.563, -4.401],
                          [0, 0]
                        ],
                        v: [
                          [-9.919, 11.556],
                          [-0.467, 9.17],
                          [10.13, -1.87],
                          [12.639, -10.251]
                        ],
                        c: false
                      }
                    ]
                  },
                  {
                    i: { x: 0.19, y: 1 },
                    o: { x: 0.81, y: 0 },
                    t: 49.665,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [-5.289, -0.107],
                          [1.322, 5.089],
                          [0, 0]
                        ],
                        o: [
                          [0, 0],
                          [4.386, 0.089],
                          [-1.649, -6.351],
                          [0, 0]
                        ],
                        v: [
                          [-9.036, 3.92],
                          [3.713, 7.76],
                          [10.272, -1.067],
                          [4.692, -9.006]
                        ],
                        c: false
                      }
                    ]
                  },
                  {
                    t: 64,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [0, 0],
                          [0, 6.627],
                          [0, 0]
                        ],
                        o: [
                          [0, 0],
                          [6.627, 0],
                          [0, 0],
                          [0, 0]
                        ],
                        v: [
                          [-10.13, 10.13],
                          [-1.87, 10.13],
                          [10.13, -1.87],
                          [10.13, -10.13]
                        ],
                        c: false
                      }
                    ]
                  }
                ],
                ix: 2
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            {
              ty: 'st',
              c: { a: 0, k: [0.2235, 0.7529, 0.9451, 1], ix: 3 },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 7, ix: 5 },
              lc: 2,
              lj: 2,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false
            },
            {
              ty: 'tr',
              p: { a: 0, k: [17.13, 17.13], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform'
            }
          ],
          nm: 'Group 1',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false
        }
      ],
      ip: 0,
      op: 241,
      st: 0,
      ct: 1,
      bm: 0
    },
    {
      ddd: 0,
      ind: 5,
      ty: 4,
      nm: 'Layer 1 Outlines 2',
      parent: 3,
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: {
          a: 1,
          k: [
            { i: { x: [0.19], y: [1] }, o: { x: [0.81], y: [0] }, t: 9.168, s: [0] },
            { t: 43.0634765625, s: [360] }
          ],
          ix: 10
        },
        p: { a: 0, k: [-0.406, -0.027, 0], ix: 2, l: 2 },
        a: { a: 0, k: [44.406, 44.968, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 }
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 1,
                k: [
                  {
                    i: { x: 0.19, y: 1 },
                    o: { x: 0.81, y: 0 },
                    t: 29.416,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [5.639, -6.677],
                          [8.617, -1.462],
                          [7.526, 4.443],
                          [2.882, 8.251],
                          [-3.121, 8.163],
                          [-7.652, 4.223],
                          [-8.571, -1.712],
                          [-5.443, -6.839]
                        ],
                        o: [
                          [0, 8.74],
                          [-5.64, 6.677],
                          [-8.617, 1.462],
                          [-7.526, -4.443],
                          [-2.883, -8.251],
                          [3.122, -8.164],
                          [7.652, -4.222],
                          [8.57, 1.712],
                          [0, 0]
                        ],
                        v: [
                          [37.406, 0.027],
                          [28.673, 23.902],
                          [6.595, 36.506],
                          [-18.404, 31.889],
                          [-34.523, 12.231],
                          [-34.154, -13.188],
                          [-17.469, -32.369],
                          [7.654, -36.256],
                          [29.355, -23.015]
                        ],
                        c: false
                      }
                    ]
                  },
                  {
                    i: { x: 0.19, y: 1 },
                    o: { x: 0.81, y: 0 },
                    t: 51.796,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [5.639, -6.677],
                          [8.617, -1.462],
                          [7.526, 4.443],
                          [2.882, 8.251],
                          [-3.121, 8.163],
                          [-7.652, 4.223],
                          [-8.571, -1.712],
                          [-5.443, -6.839]
                        ],
                        o: [
                          [7.219, 13.999],
                          [-5.64, 6.677],
                          [-8.617, 1.462],
                          [-7.526, -4.443],
                          [-2.883, -8.251],
                          [3.122, -8.164],
                          [7.652, -4.222],
                          [8.57, 1.712],
                          [0, 0]
                        ],
                        v: [
                          [34.877, -9.916],
                          [28.673, 23.902],
                          [6.595, 36.506],
                          [-18.404, 31.889],
                          [-34.523, 12.231],
                          [-34.154, -13.188],
                          [-17.469, -32.369],
                          [7.654, -36.256],
                          [29.355, -23.015]
                        ],
                        c: false
                      }
                    ]
                  },
                  {
                    t: 63.51953125,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [5.639, -6.677],
                          [8.617, -1.462],
                          [7.526, 4.443],
                          [2.882, 8.251],
                          [-3.121, 8.163],
                          [-7.652, 4.223],
                          [-8.571, -1.712],
                          [-5.443, -6.839]
                        ],
                        o: [
                          [0, 8.74],
                          [-5.64, 6.677],
                          [-8.617, 1.462],
                          [-7.526, -4.443],
                          [-2.883, -8.251],
                          [3.122, -8.164],
                          [7.652, -4.222],
                          [8.57, 1.712],
                          [0, 0]
                        ],
                        v: [
                          [37.406, 0.027],
                          [28.673, 23.902],
                          [6.595, 36.506],
                          [-18.404, 31.889],
                          [-34.523, 12.231],
                          [-34.154, -13.188],
                          [-17.469, -32.369],
                          [7.654, -36.256],
                          [29.355, -23.015]
                        ],
                        c: false
                      }
                    ]
                  }
                ],
                ix: 2
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            {
              ty: 'st',
              c: { a: 0, k: [0.2235, 0.7529, 0.9451, 1], ix: 3 },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 7, ix: 5 },
              lc: 2,
              lj: 1,
              ml: 4,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false
            },
            {
              ty: 'tr',
              p: { a: 0, k: [44.406, 44.968], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform'
            }
          ],
          nm: 'Group 1',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false
        },
        {
          ty: 'tm',
          s: {
            a: 1,
            k: [
              { i: { x: [0.19], y: [1] }, o: { x: [0.81], y: [0] }, t: 9.168, s: [0] },
              { i: { x: [0.19], y: [1] }, o: { x: [0.81], y: [0] }, t: 29, s: [42] },
              { t: 52.27734375, s: [0] }
            ],
            ix: 1
          },
          e: { a: 0, k: 100, ix: 2 },
          o: { a: 0, k: 0, ix: 3 },
          m: 1,
          ix: 2,
          nm: 'Trim Paths 1',
          mn: 'ADBE Vector Filter - Trim',
          hd: false
        }
      ],
      ip: 0,
      op: 241,
      st: 0,
      ct: 1,
      bm: 0
    },
    {
      ddd: 0,
      ind: 6,
      ty: 4,
      nm: 'Layer 1 Outlines',
      parent: 3,
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 47, ix: 10 },
        p: { a: 0, k: [-0.012, 0.353, 0], ix: 2, l: 2 },
        a: { a: 0, k: [8.25, 8.25, 0], ix: 1, l: 2 },
        s: {
          a: 1,
          k: [
            { i: { x: [0.19, 0.19, 0.19], y: [1, 1, 1] }, o: { x: [0.81, 0.81, 0.81], y: [0, 0, 0] }, t: 9, s: [-100, 100, 100] },
            { i: { x: [0.19, 0.19, 0.19], y: [1, 1, 1] }, o: { x: [0.81, 0.81, 0.81], y: [0, 0, 0] }, t: 29, s: [-70, 70, 100] },
            { t: 51, s: [-100, 100, 100] }
          ],
          ix: 6,
          l: 2
        }
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-4.418, 0],
                    [0, -4.418],
                    [4.418, 0],
                    [0, 4.418]
                  ],
                  o: [
                    [4.418, 0],
                    [0, 4.418],
                    [-4.418, 0],
                    [0, -4.418]
                  ],
                  v: [
                    [0, -8],
                    [8, 0],
                    [0, 8],
                    [-8, 0]
                  ],
                  c: true
                },
                ix: 2
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            { ty: 'fl', c: { a: 0, k: [0.2235, 0.7529, 0.9451, 1], ix: 4 }, o: { a: 0, k: 100, ix: 5 }, r: 1, bm: 0, nm: 'Fill 1', mn: 'ADBE Vector Graphic - Fill', hd: false },
            {
              ty: 'tr',
              p: { a: 0, k: [8.25, 8.25], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform'
            }
          ],
          nm: 'Group 1',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false
        }
      ],
      ip: 0,
      op: 241,
      st: 0,
      ct: 1,
      bm: 0
    }
  ],
  markers: [],
  props: {}
};
export const infoLottie = {
  v: '4.8.0',
  meta: { g: 'LottieFiles AE 1.0.0', a: 'Sadjad', k: 'info', d: 'info animated icon for you designed, Enjoy!', tc: 'none' },
  fr: 30,
  ip: 0,
  op: 55,
  w: 250,
  h: 250,
  nm: 'info_lottie',
  ddd: 0,
  assets: [
    {
      id: 'comp_0',
      layers: [
        {
          ddd: 0,
          ind: 1,
          ty: 3,
          nm: 'Null 1',
          sr: 1,
          ks: {
            o: { a: 0, k: 0, ix: 11 },
            r: {
              a: 1,
              k: [
                { i: { x: [0.5], y: [1] }, o: { x: [0.5], y: [0] }, t: 10, s: [0] },
                { i: { x: [0.5], y: [1] }, o: { x: [0.5], y: [0] }, t: 26, s: [-15] },
                { t: 43, s: [0] }
              ],
              ix: 10
            },
            p: { a: 0, k: [125, 125, 0], ix: 2 },
            a: { a: 0, k: [0, 0, 0], ix: 1 },
            s: {
              a: 1,
              k: [
                { i: { x: [0.5, 0.5, 0.5], y: [1, 1, 1] }, o: { x: [0.5, 0.5, 0.5], y: [0, 0, 0] }, t: 10, s: [100, 100, 100] },
                { i: { x: [0.5, 0.5, 0.5], y: [1, 1, 1] }, o: { x: [0.5, 0.5, 0.5], y: [0, 0, 0] }, t: 26, s: [150, 150, 100] },
                { t: 43, s: [100, 100, 100] }
              ],
              ix: 6
            }
          },
          ao: 0,
          ip: 0,
          op: 241,
          st: 0,
          bm: 0
        },
        {
          ddd: 0,
          ind: 2,
          ty: 4,
          nm: 'i Outlines',
          parent: 1,
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: {
              a: 1,
              k: [
                { i: { x: 0.5, y: 1 }, o: { x: 0.5, y: 0 }, t: 11, s: [0, 16.667, 0], to: [0, -2.083, 0], ti: [0, -5.25, 0] },
                { i: { x: 0.5, y: 1 }, o: { x: 0.5, y: 0 }, t: 19, s: [0, 4.167, 0], to: [0, 5.25, 0], ti: [0, 0, 0] },
                { i: { x: 0.5, y: 1 }, o: { x: 0.5, y: 0 }, t: 27, s: [0, 48.167, 0], to: [0, 0, 0], ti: [0, 5.25, 0] },
                { i: { x: 0.5, y: 1 }, o: { x: 0.5, y: 0 }, t: 36, s: [0, 4.167, 0], to: [0, -5.25, 0], ti: [0, -2.083, 0] },
                { t: 44, s: [0, 16.667, 0] }
              ],
              ix: 2
            },
            a: { a: 0, k: [6.5, 23.166, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 }
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0]
                      ],
                      o: [
                        [0, 0],
                        [0, 0]
                      ],
                      v: [
                        [6.5, 39.833],
                        [6.5, 6.5]
                      ],
                      c: false
                    },
                    ix: 2
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false
                },
                {
                  ty: 'st',
                  c: { a: 0, k: [0.2235, 0.7529, 0.9451, 1], ix: 3 },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 13, ix: 5 },
                  lc: 2,
                  lj: 2,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform'
                }
              ],
              nm: 'Group 1',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false
            }
          ],
          ip: 0,
          op: 241,
          st: 0,
          bm: 0
        },
        {
          ddd: 0,
          ind: 3,
          ty: 4,
          nm: 'point Outlines',
          parent: 1,
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: {
              a: 1,
              k: [
                { i: { x: 0.5, y: 1 }, o: { x: 0.5, y: 0 }, t: 10, s: [0.041, -33.333, 0], to: [-0.003, 2.526, 0], ti: [0.009, -7.014, 0] },
                { i: { x: 0.5, y: 1 }, o: { x: 0.5, y: 0 }, t: 18, s: [0.021, -37.674, 0], to: [-0.01, 8.413, 0], ti: [0, 0, 0] },
                { i: { x: 0.5, y: 1 }, o: { x: 0.5, y: 0 }, t: 26, s: [0, 0, 0], to: [0, 0, 0], ti: [-0.011, 8.738, 0] },
                { i: { x: 0.5, y: 1 }, o: { x: 0.5, y: 0 }, t: 35, s: [0.022, -46.652, 0], to: [0.008, -6.688, 0], ti: [-0.003, 2.409, 0] },
                { t: 43, s: [0.041, -33.333, 0] }
              ],
              ix: 2
            },
            a: { a: 0, k: [6.541, 6.5, 0], ix: 1 },
            s: {
              a: 1,
              k: [
                { i: { x: [0.5, 0.5, 0.5], y: [1, 1, 1] }, o: { x: [0.5, 0.5, 0.5], y: [0, 0, 0] }, t: 10, s: [100, 100, 100] },
                { i: { x: [0.5, 0.5, 0.5], y: [1, 1, 1] }, o: { x: [0.5, 0.5, 0.5], y: [0, 0, 0] }, t: 26, s: [200, 200, 100] },
                { t: 43, s: [100, 100, 100] }
              ],
              ix: 6
            }
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0]
                      ],
                      o: [
                        [0, 0],
                        [0, 0]
                      ],
                      v: [
                        [6.5, 6.5],
                        [6.583, 6.5]
                      ],
                      c: false
                    },
                    ix: 2
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false
                },
                {
                  ty: 'st',
                  c: { a: 0, k: [0.2235, 0.7529, 0.9451, 1], ix: 3 },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 13, ix: 5 },
                  lc: 2,
                  lj: 2,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform'
                }
              ],
              nm: 'Group 1',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false
            }
          ],
          ip: 0,
          op: 241,
          st: 0,
          bm: 0
        }
      ]
    }
  ],
  layers: [
    {
      ddd: 0,
      ind: 1,
      ty: 0,
      nm: '!',
      refId: 'comp_0',
      sr: 1,
      ks: { o: { a: 0, k: 100, ix: 11 }, r: { a: 0, k: 0, ix: 10 }, p: { a: 0, k: [125, 125, 0], ix: 2 }, a: { a: 0, k: [125, 125, 0], ix: 1 }, s: { a: 0, k: [100, 100, 100], ix: 6 } },
      ao: 0,
      hasMask: true,
      masksProperties: [
        {
          inv: false,
          mode: 'a',
          pt: {
            a: 0,
            k: {
              i: [
                [45.287, 0],
                [0, -45.287],
                [-45.287, 0],
                [0, 45.287]
              ],
              o: [
                [-45.287, 0],
                [0, 45.287],
                [45.287, 0],
                [0, -45.287]
              ],
              v: [
                [125, 42.5],
                [43, 124.5],
                [125, 206.5],
                [207, 124.5]
              ],
              c: true
            },
            ix: 1
          },
          o: { a: 0, k: 100, ix: 3 },
          x: { a: 0, k: 0, ix: 4 },
          nm: 'Mask 1'
        }
      ],
      w: 250,
      h: 250,
      ip: 0,
      op: 241,
      st: 0,
      bm: 0
    },
    {
      ddd: 0,
      ind: 2,
      ty: 4,
      nm: 'circle Outlines',
      sr: 1,
      ks: { o: { a: 0, k: 100, ix: 11 }, r: { a: 0, k: 0, ix: 10 }, p: { a: 0, k: [125, 125, 0], ix: 2 }, a: { a: 0, k: [137.501, 137.501, 0], ix: 1 }, s: { a: 0, k: [100, 100, 100], ix: 6 } },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-46.024, 0],
                    [0, -46.023],
                    [46.023, 0],
                    [0, 46.024]
                  ],
                  o: [
                    [46.023, 0],
                    [0, 46.024],
                    [-46.024, 0],
                    [0, -46.023]
                  ],
                  v: [
                    [0, -83.333],
                    [83.333, 0],
                    [0, 83.334],
                    [-83.333, 0]
                  ],
                  c: true
                },
                ix: 2
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            {
              ty: 'st',
              c: { a: 0, k: [0.2235, 0.7529, 0.9451, 1], ix: 3 },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 13, ix: 5 },
              lc: 2,
              lj: 2,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false
            },
            {
              ty: 'tr',
              p: { a: 0, k: [137.501, 137.501], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform'
            }
          ],
          nm: 'Group 1',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false
        }
      ],
      ip: 0,
      op: 241,
      st: 0,
      bm: 0
    }
  ],
  markers: []
};
export const noteLottie = {
  v: '4.8.0',
  meta: { g: 'LottieFiles AE 3.5.4', a: '', k: '', d: '', tc: '' },
  fr: 60,
  ip: 0,
  op: 60,
  w: 128,
  h: 128,
  nm: 'note',
  ddd: 0,
  assets: [],
  layers: [
    {
      ddd: 0,
      ind: 1,
      ty: 4,
      nm: 'Layer 6',
      parent: 6,
      sr: 1,
      ks: { o: { a: 0, k: 100, ix: 11 }, r: { a: 0, k: 0, ix: 10 }, p: { a: 0, k: [-2.5, -5, 0], ix: 2 }, a: { a: 0, k: [0, 0, 0], ix: 1 }, s: { a: 0, k: [100, 100, 100], ix: 6 } },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0]
                  ],
                  o: [
                    [0, 0],
                    [0, 0]
                  ],
                  v: [
                    [-2.5, 0],
                    [2.5, 0]
                  ],
                  c: false
                },
                ix: 2
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            {
              ty: 'st',
              c: { a: 0, k: [0.1843, 0.4824, 0.3373, 1], ix: 3 },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 1.5, ix: 5 },
              lc: 2,
              lj: 2,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform'
            }
          ],
          nm: 'Group 1',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false
        },
        {
          ty: 'tm',
          s: {
            a: 1,
            k: [
              { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 10, s: [0] },
              { t: 20, s: [100] }
            ],
            ix: 1
          },
          e: { a: 0, k: 4, ix: 2 },
          o: { a: 0, k: 0, ix: 3 },
          m: 1,
          ix: 2,
          nm: 'Trim Paths 1',
          mn: 'ADBE Vector Filter - Trim',
          hd: false
        }
      ],
      ip: 0,
      op: 60,
      st: 0,
      bm: 0
    },
    {
      ddd: 0,
      ind: 2,
      ty: 4,
      nm: 'Layer 1',
      parent: 6,
      sr: 1,
      ks: { o: { a: 0, k: 100, ix: 11 }, r: { a: 0, k: 0, ix: 10 }, p: { a: 0, k: [-2.5, -5, 0], ix: 2 }, a: { a: 0, k: [0, 0, 0], ix: 1 }, s: { a: 0, k: [100, 100, 100], ix: 6 } },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0]
                  ],
                  o: [
                    [0, 0],
                    [0, 0]
                  ],
                  v: [
                    [-2.5, 0],
                    [2.5, 0]
                  ],
                  c: false
                },
                ix: 2
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            {
              ty: 'st',
              c: { a: 0, k: [0.1843, 0.4824, 0.3373, 1], ix: 3 },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 1.5, ix: 5 },
              lc: 2,
              lj: 2,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform'
            }
          ],
          nm: 'Group 1',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false
        },
        {
          ty: 'tm',
          s: {
            a: 1,
            k: [
              { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 0, s: [0] },
              { t: 10, s: [100] }
            ],
            ix: 1
          },
          e: { a: 0, k: 100, ix: 2 },
          o: { a: 0, k: 0, ix: 3 },
          m: 1,
          ix: 2,
          nm: 'Trim Paths 1',
          mn: 'ADBE Vector Filter - Trim',
          hd: false
        }
      ],
      ip: 0,
      op: 60,
      st: 0,
      bm: 0
    },
    {
      ddd: 0,
      ind: 3,
      ty: 4,
      nm: 'Layer 7',
      parent: 6,
      sr: 1,
      ks: { o: { a: 0, k: 100, ix: 11 }, r: { a: 0, k: 0, ix: 10 }, p: { a: 0, k: [0, -2, 0], ix: 2 }, a: { a: 0, k: [0, 0, 0], ix: 1 }, s: { a: 0, k: [100, 100, 100], ix: 6 } },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0]
                  ],
                  o: [
                    [0, 0],
                    [0, 0]
                  ],
                  v: [
                    [-5, 0],
                    [5, 0]
                  ],
                  c: false
                },
                ix: 2
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            {
              ty: 'st',
              c: { a: 0, k: [0.1843, 0.4824, 0.3373, 1], ix: 3 },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 1.5, ix: 5 },
              lc: 2,
              lj: 2,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform'
            }
          ],
          nm: 'Group 1',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false
        },
        {
          ty: 'tm',
          s: {
            a: 1,
            k: [
              { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 15, s: [0] },
              { t: 25, s: [100] }
            ],
            ix: 1
          },
          e: { a: 0, k: 0, ix: 2 },
          o: { a: 0, k: 0, ix: 3 },
          m: 1,
          ix: 2,
          nm: 'Trim Paths 1',
          mn: 'ADBE Vector Filter - Trim',
          hd: false
        }
      ],
      ip: 0,
      op: 60,
      st: 0,
      bm: 0
    },
    {
      ddd: 0,
      ind: 4,
      ty: 4,
      nm: 'Layer 2',
      parent: 6,
      sr: 1,
      ks: { o: { a: 0, k: 100, ix: 11 }, r: { a: 0, k: 0, ix: 10 }, p: { a: 0, k: [0, -2, 0], ix: 2 }, a: { a: 0, k: [0, 0, 0], ix: 1 }, s: { a: 0, k: [100, 100, 100], ix: 6 } },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0]
                  ],
                  o: [
                    [0, 0],
                    [0, 0]
                  ],
                  v: [
                    [-5, 0],
                    [5, 0]
                  ],
                  c: false
                },
                ix: 2
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            {
              ty: 'st',
              c: { a: 0, k: [0.1843, 0.4824, 0.3373, 1], ix: 3 },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 1.5, ix: 5 },
              lc: 2,
              lj: 2,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform'
            }
          ],
          nm: 'Group 1',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false
        },
        {
          ty: 'tm',
          s: {
            a: 1,
            k: [
              { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 5, s: [0] },
              { t: 15, s: [100] }
            ],
            ix: 1
          },
          e: { a: 0, k: 100, ix: 2 },
          o: { a: 0, k: 0, ix: 3 },
          m: 1,
          ix: 2,
          nm: 'Trim Paths 1',
          mn: 'ADBE Vector Filter - Trim',
          hd: false
        }
      ],
      ip: 0,
      op: 60,
      st: 0,
      bm: 0
    },
    {
      ddd: 0,
      ind: 5,
      ty: 4,
      nm: 'Layer 5',
      parent: 6,
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          a: 1,
          k: [
            { i: { x: 0.833, y: 0.833 }, o: { x: 0.167, y: 0.167 }, t: 9, s: [5.75, 5.75, 0], to: [-0.229, -0.285, 0], ti: [0, 0, 0] },
            { i: { x: 0.833, y: 0.833 }, o: { x: 0.167, y: 0.167 }, t: 22, s: [4.374, 4.042, 0], to: [0, 0, 0], ti: [-0.229, -0.285, 0] },
            { t: 36, s: [5.75, 5.75, 0] }
          ],
          ix: 2
        },
        a: { a: 0, k: [0, 0, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 }
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, -1.105],
                    [0, 0]
                  ],
                  o: [
                    [0, 0],
                    [-1.105, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  v: [
                    [2.75, -2.75],
                    [-0.75, -2.75],
                    [-2.75, -0.75],
                    [-2.75, 2.75]
                  ],
                  c: false
                },
                ix: 2
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            {
              ty: 'st',
              c: { a: 0, k: [0.1843, 0.4824, 0.3373, 1], ix: 3 },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 1.5, ix: 5 },
              lc: 2,
              lj: 2,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform'
            }
          ],
          nm: 'Group 1',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false
        }
      ],
      ip: 0,
      op: 60,
      st: 0,
      bm: 0
    },
    {
      ddd: 0,
      ind: 6,
      ty: 4,
      nm: 'Layer 4',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: {
          a: 1,
          k: [
            { i: { x: [0.52], y: [0.96] }, o: { x: [0.48], y: [0.04] }, t: 0, s: [0] },
            { i: { x: [0.52], y: [0.96] }, o: { x: [0.48], y: [0.04] }, t: 9, s: [5] },
            { i: { x: [0.52], y: [0.96] }, o: { x: [0.48], y: [0.04] }, t: 22, s: [-8] },
            { i: { x: [0.52], y: [0.96] }, o: { x: [0.48], y: [0.04] }, t: 31, s: [4] },
            { i: { x: [0.52], y: [0.96] }, o: { x: [0.48], y: [0.04] }, t: 39, s: [-3] },
            { t: 45, s: [0] }
          ],
          ix: 10
        },
        p: { a: 0, k: [64, 64, 0], ix: 2 },
        a: { a: 0, k: [0, 0, 0], ix: 1 },
        s: { a: 0, k: [500, 500, 100], ix: 6 }
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [-2.209, 0],
                    [0, 0],
                    [0, -2.209],
                    [0, 0],
                    [0.375, -0.375],
                    [0, 0],
                    [0.53, 0],
                    [0, 0],
                    [0, 2.209]
                  ],
                  o: [
                    [0, -2.209],
                    [0, 0],
                    [2.209, 0],
                    [0, 0],
                    [0, 0.53],
                    [0, 0],
                    [-0.375, 0.375],
                    [0, 0],
                    [-2.209, 0],
                    [0, 0]
                  ],
                  v: [
                    [-9, -5],
                    [-5, -9],
                    [5, -9],
                    [9, -5],
                    [9, 2.172],
                    [8.414, 3.586],
                    [3.586, 8.414],
                    [2.172, 9],
                    [-5, 9],
                    [-9, 5]
                  ],
                  c: true
                },
                ix: 2
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            {
              ty: 'st',
              c: { a: 0, k: [0.1843, 0.4824, 0.3373, 1], ix: 3 },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 1.5, ix: 5 },
              lc: 2,
              lj: 2,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform'
            }
          ],
          nm: 'Group 1',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false
        }
      ],
      ip: 0,
      op: 60,
      st: 0,
      bm: 0
    }
  ],
  markers: []
};
