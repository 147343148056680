import { Input, InputProps, Spin } from 'antd';
import { Field, FieldProps } from 'formik';
import { FC } from 'react';
import { FormLabel } from './FormLabel';

interface Props extends InputProps {
  fieldName: string;
  placeholder?: string;
  type?: string;
  label: string;
  loadingState?: boolean;
}

export const FieldInput: FC<Props> = ({ fieldName, placeholder, type, label, loadingState, ...rest }) => {
  return (
    <Spin spinning={loadingState}>
      <Field name={fieldName} placeholder={placeholder}>
        {({
          field, // { name, value, onChange, onBlur }
          form: { setFieldTouched, setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
          meta
        }: FieldProps): JSX.Element => {
          return (
            <div>
              <FormLabel label={label} />
              <Input
                onFocus={(): void => {
                  setFieldTouched(fieldName, false);
                }}
                style={{ borderColor: meta.error && meta.touched ? '#dc3545' : undefined }}
                type={type}
                {...field}
                onChange={(e) => {
                  setFieldValue(fieldName, e.target.value.toLocaleUpperCase());
                }}
                {...rest}
              />
              <div className={meta.touched && meta.error ? 'field-error-show' : 'field-error-hide'}>{meta.error}</div>
            </div>
          );
        }}
      </Field>
    </Spin>
  );
};
