import { useAuth0 } from '@auth0/auth0-react';
import { Col, Row, Spin } from 'antd';
import { FieldInputDatePicker } from 'components/atoms/FieldInputDatePicker';
import { FieldInputSelect } from 'components/atoms/FieldInputSelect';
import { useFormikContext } from 'formik';
import { Load } from 'models/DockReceipt';
import { FC } from 'react';
import { useGetUsersQuery } from 'redux/services/cookieMonster/cookieMonsterApi';
import { useGetLegalEntitiesQuery } from 'redux/services/maggieCadabby/maggieCadabbyApi';

type Props = {
  isCreate?: boolean;
};

export const LoadForm: FC<Props> = ({ isCreate }) => {
  const { data, isLoading } = useGetLegalEntitiesQuery({});
  const { values } = useFormikContext<Load>();
  const { data: userData, isLoading: isUsersLoading } = useGetUsersQuery({ isMdsiEmployee: true, warehouseIdEquals: values.warehouseId }, { skip: !values.warehouseId });

  const { user } = useAuth0();
  const assignedWarehouses = user?.['https://acuity.mdsiinc.com/user/user_metadata'].authorized_warehouses;

  const legalEntityInUse = data?.data.find((legalEntity) => legalEntity.dataAreaId.toLowerCase() === 'mdsi');

  const filteredUsers = userData?.data;

  const carrierOptions = legalEntityInUse?.freightVendors.map((carrier) => ({ label: carrier.carrierId, value: carrier.carrierId })) || [];

  return (
    <Row gutter={[16, 16]}>
      <Col span={24}>
        <Spin spinning={isLoading}>
          <FieldInputSelect disabled={!isCreate} fieldName="carrierId" options={carrierOptions} label="Carrier" />
        </Spin>
      </Col>
      {!isCreate && (
        <Col span={24}>
          <FieldInputSelect fieldName="status" options={['Open', 'Closed', 'Cancelled'].map((opt) => ({ label: opt, value: opt }))} label="Status" />
        </Col>
      )}
      <Col span={24}>
        <FieldInputDatePicker fieldName="arrivalDate" label="Arrival Date" />
      </Col>
      <Col span={24}>
        <FieldInputSelect fieldName="warehouseId" options={assignedWarehouses?.map((opt: any) => ({ label: opt.warehouseCode, value: opt.warehouseCode }))} label="Warehouse" />
      </Col>
      <Col span={24}>
        <Spin spinning={isUsersLoading}>
          <FieldInputSelect
            disabled={!values.warehouseId}
            options={filteredUsers?.map((user) => ({ label: user.name, value: user.user_id }))}
            fieldName="assignedToUserFullName"
            label="Assigned User"
          />
        </Spin>
      </Col>
    </Row>
  );
};
