import { CloseOutlined, SlidersOutlined } from '@ant-design/icons';
import { Button, Col, Drawer, Row, Typography } from 'antd';
import { HomeFilters } from 'components/molecules/HomeFilters';
import { ShipmentFilters } from 'components/molecules/ShipmentFilters';
import { FC, useState } from 'react';

type Props = {
  navKey?: string;
};

export const MobileFilters: FC<Props> = ({ navKey }) => {
  const [visible, setVisible] = useState(false);

  const showDrawer = (): void => {
    setVisible(true);
  };

  const onClose = (): void => {
    setVisible(false);
  };

  return (
    <>
      <Button onClick={showDrawer} icon={<SlidersOutlined />} />
      <Drawer
        title={
          <Row style={{ width: '100%' }} align={'middle'}>
            <Col span={10}>
              <CloseOutlined style={{ fontSize: 17, cursor: 'pointer' }} onClick={onClose} />
            </Col>
            <Col span={14} style={{ textAlign: 'left' }}>
              <Typography.Title level={4} style={{ padding: 0, margin: 0 }}>
                Filters
              </Typography.Title>
            </Col>
          </Row>
        }
        closeIcon={null}
        style={{ borderRadius: 15 }}
        onClose={onClose}
        closable={false}
        height={'75%'}
        placement="bottom"
        open={visible}>
        {navKey === '2' || navKey === '3' ? <ShipmentFilters isMIR={navKey === '2'} /> : <HomeFilters />}
      </Drawer>
    </>
  );
};
