import { useGetBusinessNameConatinsQuery } from 'redux/services/abbyCadabby/abbyCadabbyApi';
import { FieldInputSelect } from './FieldInputSelect';

export const BusinessSelect = () => {
  const { data, isLoading } = useGetBusinessNameConatinsQuery({});

  const options = data?.data.map((business) => ({ label: `${business.name} - ${business.erpId}`, value: business.erpId }));

  return <FieldInputSelect loading={isLoading} options={options} fieldName="accountNumber" label="Account Number" />;
};
