import { Button, Drawer, notification, Row, Space, Spin } from 'antd';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import { editLottie } from 'assets/lottieAssets';
import { colors, toRgba } from 'common/styles/colors';
import { LoaderWithMessage } from 'components/common/LoaderWithMessage';
import { ColorCard } from 'components/molecules/ColorCard';
import { LoadForm } from 'components/molecules/LoadForm';
import { FormikProvider, useFormik } from 'formik';
import { DockReceipt, Load } from 'models/DockReceipt';
import { QueryErrorModel } from 'models/ErrorModel';
import { FC, useEffect, useState } from 'react';
import { useUpdateDockReceiptMutation } from 'redux/services/linda/lindaApi';
import { LottieButton } from './LottieButton';

type Props = {
  record: DockReceipt;
  setParentVisible: React.Dispatch<React.SetStateAction<boolean>>;
  selection?: string;
};

export const EditLoadDrawer: FC<Props> = ({ record, selection, setParentVisible }) => {
  const { xs } = useBreakpoint();

  const [updateDockReceipt] = useUpdateDockReceiptMutation();

  const [visible, setVisible] = useState(false);

  const formik = useFormik<Load>({
    enableReinitialize: true,
    initialValues: {
      carrierId: record?.carrierId ?? '',
      arrivalDate: record?.arrivalDate ?? '',
      assignedToUserFullName: record?.assignedToUserFullName ?? '',
      assignedToUserId: record?.assignedToUserId ?? '',
      status: record?.status ?? '',
      trackingNumbers: record?.masterTrackingNumbers ?? [],
      warehouseId: record?.warehouseId ?? '',
      isActive: true
    },
    onSubmit: async (values) => {
      try {
        const resp = await updateDockReceipt({ receiptId: record.id, payload: values }).unwrap();

        notification.success({ message: `Dock Receipt ${resp.loadId} successfully updated` });
        setVisible(false);
        formik.resetForm();
      } catch (e) {
        console.error(e, 'error');

        const error = e as QueryErrorModel;
        const errorMessage = error?.data?.errorMessage ? error?.data?.errorMessage : error.data ? (error?.data as string) : 'An error occurred, the team has been notified.';

        notification.error({
          message: 'Error',
          description: errorMessage,
          className: 'custom-class',
          style: {
            width: 600
          },
          duration: 5
        });
      }
    }
  });

  useEffect(() => {
    if (selection === 'edit') {
      setVisible(true);
      setParentVisible(false);
    }
  }, [selection]);

  const showDrawer = (): void => {
    setVisible(true);
  };

  const onClose = (): void => {
    setVisible(false);
    formik.resetForm();
  };

  return (
    <FormikProvider value={formik}>
      <LottieButton tooltip={!xs ? 'Edit' : undefined} onClick={showDrawer} animationData={editLottie} borderColor="#FFBF66" hoverColor="#FFE9D9" />
      <Drawer
        footer={
          <Row justify={'end'}>
            <Space>
              <Button onClick={onClose}>Cancel</Button>
              <Button
                disabled={
                  !formik.values.arrivalDate ||
                  !formik.values.carrierId ||
                  !formik.values.warehouseId ||
                  !formik.values.assignedToUserFullName ||
                  !formik.values.assignedToUserId ||
                  !formik.values.isActive ||
                  !formik.values.status
                }
                type={'primary'}
                onClick={formik.submitForm}>
                Submit
              </Button>
            </Space>
          </Row>
        }
        style={{ borderRadius: xs ? 15 : 0 }}
        closeIcon={null}
        onClose={onClose}
        height={'90%'}
        width={xs ? '100%' : '30%'}
        open={visible}
        placement={xs ? 'bottom' : 'right'}>
        <ColorCard color={toRgba(colors.orangeWeb, 0.4)} title={`Edit Load ${record?.loadId}`}>
          <Space direction="vertical" size={20}>
            <Spin spinning={formik.isSubmitting} indicator={<LoaderWithMessage loadingMessage="Updating Dock Receipt..." />}>
              <LoadForm />
            </Spin>
          </Space>
        </ColorCard>
      </Drawer>
    </FormikProvider>
  );
};
