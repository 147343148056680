import { Modal, notification } from 'antd';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import { deleteLottie } from 'assets/lottieAssets';
import { DockReceipt } from 'models/DockReceipt';
import { QueryErrorModel } from 'models/ErrorModel';
import { FC } from 'react';
import { useDeleteDockReceiptMutation } from 'redux/services/linda/lindaApi';
import { useAppSelector } from 'redux/store';
import { LottieButton } from './LottieButton';

type Props = {
  record: DockReceipt;
};

export const DeleteDockReceiptButton: FC<Props> = ({ record }) => {
  const [deleteDockReceipt] = useDeleteDockReceiptMutation();
  const { xs } = useBreakpoint();

  const { returnDeletedDataOnly } = useAppSelector((state) => state.lindaParams);
  const handleClick = (): void => {
    Modal.confirm({
      title: 'Are you sure you want to delete this dock receipt?',
      content: returnDeletedDataOnly ? 'This action cannot be undone.' : 'You can still recover this dock receipt from the recycle bin.',
      onOk: async () => {
        if (!record.id) {
          return;
        }
        try {
          await deleteDockReceipt({ receiptId: record.id, params: { wouldYouLikeToPlayAGame: returnDeletedDataOnly ? true : false } });
          notification.success({ message: 'Dock Receipt successfully deleted' });
        } catch (e) {
          console.error(e, 'error');

          const error = e as QueryErrorModel;
          const errorMessage = error?.data?.errorMessage ? error?.data?.errorMessage : error.data ? (error?.data as string) : 'An error occurred, the team has been notified.';

          notification.error({
            message: 'Error',
            description: errorMessage,
            className: 'custom-class',
            style: {
              width: 600
            },
            duration: 5
          });
        }
      }
    });
  };

  return <LottieButton tooltip={!xs ? 'Delete' : undefined} onClick={handleClick} animationData={deleteLottie} borderColor="#D94853" hoverColor="#FFEAEB" />;
};
