import { Col, Row } from 'antd';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import { FancyDateRangePicker } from 'components/ui/filters/FancyDateRangePicker';
import { FancyInput } from 'components/ui/filters/FancyInput';
import { FancySelect } from 'components/ui/filters/FancySelect';
import { FC, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { resetRangeValues } from 'redux/services/linda/lindaParams';
import {
  setAccountIdContains,
  setCustomerIdContains,
  setMdsiOrderNumberContains,
  setProcessingWarehouseContains,
  setReceiptType,
  setReturnReferenceContains,
  setStatuses,
  setTrackingNumberContains
} from 'redux/services/linda/shipmentParams';
import { useAppSelector } from 'redux/store';
import { useDebouncedCallback } from 'use-debounce';

type Props = {
  isMIR?: boolean;
  isDrawer?: boolean;
};

export const ShipmentFilters: FC<Props> = ({ isMIR = false, isDrawer = false }) => {
  const { xs } = useBreakpoint();
  const dispatch = useDispatch();
  const { receiptType, statuses } = useAppSelector((state) => state.shipmentParams);

  const handleChange = useDebouncedCallback((event: React.ChangeEvent<HTMLInputElement>, filterAction: any) => {
    const { value } = event.target;

    dispatch(filterAction(value || undefined));
  }, 500);

  const handleFilters = (filter: string, event: React.ChangeEvent<HTMLInputElement>): void => {
    switch (filter) {
      case 'status': {
        dispatch(setStatuses(event as unknown as string));
        break;
      }

      case 'receiptType': {
        dispatch(setReceiptType(event as unknown as string));
        break;
      }

      case 'processingWarehouse': {
        handleChange(event, setProcessingWarehouseContains);
        break;
      }

      case 'customerIdContains': {
        handleChange(event, setCustomerIdContains);
        break;
      }

      case 'trackingNumber': {
        handleChange(event, setTrackingNumberContains);
        break;
      }

      case 'accountId': {
        handleChange(event, setAccountIdContains);
        break;
      }

      case 'orderNumber': {
        handleChange(event, setMdsiOrderNumberContains);
        break;
      }

      case 'returnReference': {
        handleChange(event, setReturnReferenceContains);
        break;
      }
    }
  };

  useEffect(() => {
    return (): void => {
      dispatch(setStatuses(statuses));
      dispatch(setReceiptType(receiptType));
      dispatch(resetRangeValues());
      dispatch(setTrackingNumberContains(undefined));
      dispatch(setProcessingWarehouseContains(undefined));
      dispatch(setCustomerIdContains(undefined));
    };
  }, []);

  return (
    <Row style={{ background: 'white', padding: isDrawer ? 0 : 16, marginBottom: isDrawer ? 16 : 0 }} gutter={xs ? [0, 25] : [12, 20]}>
      {!isMIR && (
        <Col xs={24} sm={12} md={12} lg={8} xl={4} xxl={4}>
          <FancySelect
            onChange={(e) => handleFilters('status', e)}
            style={{ width: '100%' }}
            options={['New', 'In Progress', 'Escalation', 'Issue Resolved', 'Completed', 'Cancelled', 'MIR'].map((opt) => ({ label: opt, value: opt }))}
            placeholder="Status"
            value={statuses}
          />
        </Col>
      )}
      <Col xs={24} sm={12} md={12} lg={8} xl={4} xxl={4}>
        <FancySelect
          options={['Purchase Order', 'Customer Return', 'RMA/Defective Return', 'Transfer', 'Other'].map((opt) => ({ label: opt, value: opt }))}
          onChange={(e) => handleFilters('receiptType', e)}
          placeholder="Receipt Type"
          value={receiptType}
        />
      </Col>
      <Col xs={24} sm={12} md={12} lg={8} xl={4} xxl={4}>
        <FancyInput onChange={(e) => handleFilters('accountId', e)} placeholder="Account Id" />
      </Col>
      <Col xs={24} sm={12} md={12} lg={8} xl={4} xxl={4}>
        <FancyInput onChange={(e) => handleFilters('orderNumber', e)} placeholder="MDSi Order Number" />
      </Col>
      <Col xs={24} sm={12} md={12} lg={8} xl={4} xxl={4}>
        <FancyInput onChange={(e) => handleFilters('returnReference', e)} placeholder="Return Reference" />
      </Col>
      <Col xs={24} sm={12} md={12} lg={8} xl={4} xxl={4}>
        <FancyInput onChange={(e) => handleFilters('processingWarehouse', e)} placeholder="Processing Warehouse" />
      </Col>
      <Col xs={24} sm={12} md={12} lg={8} xl={4} xxl={4}>
        <FancyInput onChange={(e) => handleFilters('trackingNumber', e)} placeholder="Tracking Number" />
      </Col>
      <Col xs={24} sm={12} md={12} lg={8} xl={4} xxl={4}>
        <FancyDateRangePicker isShipment />
      </Col>
    </Row>
  );
};
