import { Col, Modal, Row, Typography } from 'antd';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import { infoLottie } from 'assets/lottieAssets';
import { Shipment } from 'models/DockReceipt';
import { FC } from 'react';
import { LottieButton } from './LottieButton';

type Props = {
  record: Shipment;
};
export const ShipmentInfoModal: FC<Props> = ({ record }) => {
  const { xs } = useBreakpoint();
  const handleClick = (): void => {
    Modal.info({
      icon: null,
      okText: 'Exit',
      content: (
        <Row>
          <Col span={12}>
            <Typography.Title style={{ margin: 0, padding: 0 }} level={5}>
              Tracking Numbers:
            </Typography.Title>
            {record?.trackingNumbers?.map((num) => {
              return <Row key={num}>{num}</Row>;
            })}
          </Col>
          <Col span={12}>
            <Typography.Title style={{ margin: 0, padding: 0 }} level={5}>
              Attachments:
            </Typography.Title>
            {record?.attachments?.map((att) => {
              return (
                <Row key={att.id}>
                  <a href={att?.url} target="_blank" rel="noreferrer">
                    {att.name}
                  </a>
                </Row>
              );
            })}
          </Col>
        </Row>
      )
    });
  };

  return <LottieButton animationData={infoLottie} borderColor="#39C0F1" hoverColor="#E0F7FF" tooltip={xs ? undefined : 'Tracking Numbers/Attachments'} onClick={handleClick} />;
};
