import { List, Space, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import { DeleteShipmentsButton } from 'components/atoms/DeleteShipmentsButton';
import { EditShipmentsDrawer } from 'components/atoms/EditShipmentsDrawer';
import { MobileCardShipments } from 'components/atoms/MobileCardShipments';
import { ShipmentInfoModal } from 'components/atoms/ShipmentInfoModal';
import { ShipmentNoteModal } from 'components/atoms/ShipmentNoteModal';
import { LoaderWithMessage } from 'components/common/LoaderWithMessage';
import { TablePagination } from 'components/molecules/TablePagination';
import { Shipment } from 'models/DockReceipt';
import { FC } from 'react';
import { useDispatch } from 'react-redux';
import { useGetMIRShipmentsQuery } from 'redux/services/linda/lindaApi';
import { setOffset } from 'redux/services/linda/shipmentParams';
import { useAppSelector } from 'redux/store';

export const AllShipmentsTable: FC = () => {
  const { xs } = useBreakpoint();
  const { shipmentParams } = useAppSelector((state) => state);
  const dispatch = useDispatch();

  const { data, isLoading, isFetching } = useGetMIRShipmentsQuery({ params: { ...shipmentParams, orderByDirection: 'descending' } });

  const handlePageChange = (page: number, pageSize: number): void => {
    dispatch(setOffset((page - 1) * pageSize));
  };
  const columns: ColumnsType<Shipment> = [
    {
      title: 'Shipment Id',
      dataIndex: 'shipmentId',
      key: 'shipmentId'
    },
    {
      title: 'Receipt Type',
      dataIndex: 'receiptType',
      key: 'receiptType'
    },
    {
      title: 'Account Id',
      dataIndex: 'accountId',
      key: 'accountId'
    },
    {
      title: 'MDSi Order Number',
      dataIndex: 'mdsiOrderNumber',
      key: 'mdsiOrderNumber'
    },
    {
      title: 'Return Reference',
      dataIndex: 'returnReference',
      key: 'returnReference'
    },
    {
      title: 'Processing Warehouse',
      dataIndex: 'processingWarehouse',
      key: 'processingWarehouse'
    },
    {
      title: 'Shipment Date',
      dataIndex: 'shipmentDate',
      key: 'shipmentDate',
      render: (text) => new Date(text).toLocaleDateString()
    },
    {
      title: 'Assigned To',
      dataIndex: 'assignedToUserFullName',
      key: 'assignedToUserFullName'
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status'
    },
    {
      title: 'Actions',
      key: 'actions',
      fixed: 'right',
      render: (text, record) => {
        return (
          <Space>
            <EditShipmentsDrawer record={record} dockReceiptId={record?.partitionKeyLevel2} />
            <ShipmentInfoModal record={record} />
            <ShipmentNoteModal record={record} />
            <DeleteShipmentsButton record={record} dockReceiptId={record?.partitionKeyLevel2} />
          </Space>
        );
      }
    }
  ];

  if (xs)
    return (
      <List
        grid={{ xs: 1, sm: 2, md: 4, lg: 4, xl: 4, xxl: 4, gutter: [8, 8] }}
        dataSource={data?.data}
        loading={{ spinning: isLoading || isFetching }}
        renderItem={(item): JSX.Element => <MobileCardShipments dockReceiptId={item.partitionKeyLevel2} record={item} />}
      />
    );

  return (
    <>
      <Table
        loading={{ indicator: <LoaderWithMessage loadingMessage="Loading shipments" />, spinning: isLoading || isFetching }}
        columns={columns}
        scroll={{ x: 'max-content' }}
        size={xs ? 'large' : 'small'}
        dataSource={data?.data}
        rowKey={(record): string => record.id}
        pagination={false}
      />
      <TablePagination offset={shipmentParams.offset} dispatchAction={setOffset} hasMore={data?.hasMore ?? false} />
    </>
  );
};
