import { Col, List, Row, Space, Typography } from 'antd';
import { PrintSlipListItem } from 'components/atoms/PrintSlipListItem';
import { useFormikContext } from 'formik';
import { ShipmentPayload } from 'models/DockReceipt';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setPrintDetails } from 'redux/slices/appSlice';

export const PrintSlipList = (): JSX.Element => {
  const { values } = useFormikContext<ShipmentPayload>();
  const dispatch = useDispatch();

  useEffect(() => {
    const newDetails = values.trackingNumbers.reduce((acc, num) => {
      acc[num] = { qty: 1 };

      return acc;
    }, {} as { [key: string]: { qty: number } });

    dispatch(setPrintDetails(newDetails));
  }, [values.trackingNumbers]);

  return (
    <Space direction="vertical" style={{ width: '100%' }}>
      <Row justify={'space-between'} style={{ marginBottom: 16, width: '100%' }}>
        <Typography.Text>Tracking Numbers</Typography.Text>
        <Typography.Text>Qty To Print</Typography.Text>
      </Row>
      <Row style={{ marginBottom: 16, width: '100%' }}>
        <Col span={24}>
          <List
            dataSource={values.trackingNumbers}
            grid={{ gutter: [0, 16], column: 1 }}
            renderItem={(item) => {
              return <PrintSlipListItem item={item} />;
            }}
            rowKey={(item): string => item}
          />
        </Col>
      </Row>
    </Space>
  );
};
