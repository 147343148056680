import { PlusOutlined } from '@ant-design/icons';
import { Button, InputNumber, Row, Space, Typography } from 'antd';
import { FC, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setPrintDetails } from 'redux/slices/appSlice';
import { useAppSelector } from 'redux/store';

type Props = {
  item: string;
};

export const PrintSlipListItem: FC<Props> = ({ item }) => {
  const { printDetails } = useAppSelector((state) => state.app);
  const dispatch = useDispatch();
  const [isDisabled, setIsDisabled] = useState(false);

  const handleChange = (qty: number): void => {
    setIsDisabled(true);
    dispatch(
      setPrintDetails({
        ...printDetails,
        [item]: { qty }
      })
    );
    setIsDisabled(false);
  };

  return (
    <Row align={'middle'} justify={'space-between'} key={item}>
      <Typography.Text>{item}</Typography.Text>
      <Space.Compact>
        <InputNumber min={1} value={printDetails[item]?.qty} onChange={(n) => handleChange(n ?? 0)} defaultValue={1} controls={false} style={{ width: 65 }} />
        <Button disabled={isDisabled} type="primary" icon={<PlusOutlined />} onClick={() => handleChange(printDetails[item]?.qty + 1)} />
      </Space.Compact>
    </Row>
  );
};
