import { useGetBusinessesQuery } from 'redux/services/abbyCadabby/abbyCadabbyApi';
import { FieldInputSelect } from './FieldInputSelect';

export const DivisionSelect = () => {
  const { data, isLoading } = useGetBusinessesQuery();

  const allDivisions = data?.data.flatMap((bus) => bus.divisions);

  const options = allDivisions?.map((business) => ({ label: `${business.name} - ${business.erpId}`, value: business.erpId }));

  return <FieldInputSelect loading={isLoading} options={options} fieldName="accountId" label="Account Id" />;
};
