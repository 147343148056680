import { Badge, Button, Drawer, InputRef, notification, Row, Space, Spin } from 'antd';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import { linkLottie } from 'assets/lottieAssets';
import { colors, toRgba } from 'common/styles/colors';
import { LoaderWithMessage } from 'components/common/LoaderWithMessage';
import { ColorCard } from 'components/molecules/ColorCard';
import { TrackingNumbersForm } from 'components/molecules/TrackingNumbersForm';
import { FormikProvider, useFormik } from 'formik';
import { DockReceipt, Load } from 'models/DockReceipt';
import { QueryErrorModel } from 'models/ErrorModel';
import { FC, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useUpdateDockReceiptMutation } from 'redux/services/linda/lindaApi';
import { setIsCreated } from 'redux/slices/appSlice';
import { useAppSelector } from 'redux/store';
import { LottieButton } from './LottieButton';

type Props = {
  record?: DockReceipt;
  isTable?: boolean;
};

export const AssignTrackingNumbersDrawer: FC<Props> = ({ record, isTable = false }) => {
  const { xs } = useBreakpoint();
  const [assignTrackingNumbers] = useUpdateDockReceiptMutation();
  const dispatch = useDispatch();
  const { isCreated } = useAppSelector((state) => state.app);

  const [visible, setVisible] = useState(false);
  const inputRef = useRef<InputRef>(null);

  const masterTrackingNumbers = record?.masterTrackingNumbers.filter((num) => !record?.assignedTrackingNumbers?.includes(num));

  const formik = useFormik<Partial<Load>>({
    enableReinitialize: true,
    initialValues: {
      trackingNumbers: masterTrackingNumbers ?? []
    },
    onSubmit: async (values) => {
      try {
        const payload: Load = {
          arrivalDate: record?.arrivalDate ?? '',
          carrierId: record?.carrierId ?? '',
          assignedToUserFullName: record?.assignedToUserFullName ?? '',
          warehouseId: record?.warehouseId ?? '',
          status: record?.status ?? '',
          assignedToUserId: record?.assignedToUserId ?? '',
          isActive: true,
          trackingNumbers: [...(values?.trackingNumbers ?? []), ...(record?.assignedTrackingNumbers ?? [])]
        };

        await assignTrackingNumbers({ receiptId: record?.id as string, payload }).unwrap();
        dispatch(setIsCreated(false));
        notification.success({ message: 'Tracking Numbers successfully assigned' });
        formik.resetForm();
        setVisible(false);
      } catch (e) {
        console.error(e, 'error');

        const error = e as QueryErrorModel;
        const errorMessage = error?.data?.errorMessage ? error?.data?.errorMessage : error.data ? (error?.data as string) : 'An error occurred, the team has been notified.';

        notification.error({
          message: 'Error',
          description: errorMessage,
          className: 'custom-class',
          style: {
            width: 600
          },
          duration: 5
        });
      }
    }
  });

  const showDrawer = (): void => {
    setVisible(true);
  };

  const onClose = (): void => {
    setVisible(false);
    formik.resetForm();
    dispatch(setIsCreated(false));
  };

  useEffect(() => {
    setVisible(isCreated && !isTable ? true : visible);
  }, [isCreated]);

  return (
    <FormikProvider value={formik}>
      {!isCreated && (
        <Badge color="blue" size="small" count={record?.unassignedTrackingNumbers?.length}>
          <LottieButton tooltip={!xs ? 'Available Tracking Numbers' : undefined} animationData={linkLottie} borderColor="#289AFF" hoverColor="#DDECFF" onClick={showDrawer} />
        </Badge>
      )}

      <Drawer
        footer={
          <Row justify={'end'} align={'bottom'}>
            <Space>
              <Button onClick={onClose}>Cancel</Button>
              <Button type={'primary'} onClick={formik.submitForm}>
                {'Complete Scan'}
              </Button>
            </Space>
          </Row>
        }
        style={{ borderRadius: xs ? 15 : 0 }}
        closeIcon={null}
        onClose={onClose}
        afterOpenChange={(): void => {
          inputRef.current?.focus();
        }}
        height={'90%'}
        width={xs ? '100%' : '30%'}
        open={visible}
        placement={xs ? 'bottom' : 'right'}>
        <ColorCard color={toRgba(colors.illuminatingEmerald, 0.4)} title={'Assign Tracking Numbers'}>
          <Spin spinning={formik.isSubmitting} indicator={<LoaderWithMessage loadingMessage="Assigning Tracking Numbers" />}>
            <Space direction="vertical" size={20}>
              <TrackingNumbersForm record={record} inputRef={inputRef} visible={visible} />
            </Space>
          </Spin>
        </ColorCard>
      </Drawer>
    </FormikProvider>
  );
};
