import { Avatar, Col, List, Modal, Row } from 'antd';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import { noteLottie } from 'assets/lottieAssets';
import { Shipment } from 'models/DockReceipt';
import moment from 'moment';
import { FC } from 'react';
import { LottieButton } from './LottieButton';

type Props = {
  record: Shipment;
};
export const ShipmentNoteModal: FC<Props> = ({ record }) => {
  const { xs } = useBreakpoint();
  const handleClick = (): void => {
    Modal.info({
      icon: null,
      okText: 'Exit',
      content: (
        <Row>
          <Col span={24}>
            <List
              dataSource={record.notes}
              renderItem={(item) => {
                const initials = item.userFullName
                  .split(' ')
                  .map((n) => n[0])
                  .join('');

                return (
                  <List.Item>
                    <List.Item.Meta avatar={<Avatar>{initials}</Avatar>} title={item.userFullName} description={item.note} />
                    <div>{moment(item.createdTimeStamp).format('MM-DD-YYYY')}</div>
                  </List.Item>
                );
              }}
            />
          </Col>
        </Row>
      )
    });
  };

  return <LottieButton animationData={noteLottie} borderColor="#065839" hoverColor="#E5FFF0" tooltip={xs ? undefined : 'Notes'} onClick={handleClick} />;
};
