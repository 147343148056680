import { Space, Spin } from 'antd';
import { FieldInputSelect } from 'components/atoms/FieldInputSelect';
import { useFormikContext } from 'formik';
import { ShipmentPayload } from 'models/DockReceipt';
import { FC } from 'react';
import { useGetDockReceiptQuery } from 'redux/services/linda/lindaApi';

type Props = {
  recordId: string;
};

export const AssignTrackingList: FC<Props> = ({ recordId }) => {
  const { data, isLoading } = useGetDockReceiptQuery({ receiptId: recordId }, { skip: !recordId });
  const { values } = useFormikContext<ShipmentPayload>();

  const isReadOnly = values.status === 'Cancelled' || values.status === 'Completed';

  return (
    <Space direction="vertical" size={0} style={{ width: '100%' }}>
      <Spin spinning={isLoading}>
        <FieldInputSelect
          disabled={isReadOnly}
          fieldName="trackingNumbers"
          label="Tracking Numbers"
          style={{ width: '100%' }}
          mode="tags"
          options={[...(data?.unassignedTrackingNumbers ?? [])]?.map((num) => ({ label: num, value: num }))}
          showSearch={false}
        />
      </Spin>
    </Space>
  );
};
