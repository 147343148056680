import { Card, Descriptions, Row, Typography } from 'antd';
import { DescriptionsProps } from 'antd/lib';
import { colors, toRgba } from 'common/styles/colors';
import { DockReceipt } from 'models/DockReceipt';
import { FC } from 'react';
import { AssignTrackingNumbersDrawer } from './AssignTrackingNumbersDrawer';
import { DeleteDockReceiptButton } from './DeleteDockReceiptButton';
import { EditLoadDrawer } from './EditLoadDrawer';
import { ShipmentsDrawer } from './ShipmentsDrawer';

type Props = {
  record: DockReceipt;
};

export const MobileCard: FC<Props> = ({ record }) => {
  const items: DescriptionsProps['items'] = [
    {
      key: '1',
      label: 'Carrier',
      children: record?.carrierId ?? 'FEDEX',
      contentStyle: { textAlign: 'end' }
    },
    {
      key: '2',
      label: 'Warehouse',
      children: record?.warehouseId ?? 'MDSI-ATL1',
      contentStyle: { textAlign: 'end' }
    },
    {
      key: '4',
      label: 'Assigned User',
      children: record?.assignedToUserFullName ?? 'Carson Coyle',
      contentStyle: { textAlign: 'end' }
    },
    {
      key: '3',
      label: 'Arrival Date',
      children: record?.arrivalDate ?? '08-20-2024',
      contentStyle: { textAlign: 'end' }
    }
  ];

  return (
    <Card
      styles={{ body: { padding: 8 } }}
      actions={[
        <EditLoadDrawer key={'edit'} setParentVisible={() => console.log()} record={record} />,
        <AssignTrackingNumbersDrawer key={'tracking'} isTable record={record} />,
        <ShipmentsDrawer key={'shipments'} record={record} />,
        <DeleteDockReceiptButton key={'delete'} record={record} />
      ]}
      title={
        <Row justify={'space-between'}>
          <Typography.Title style={{ padding: 0, margin: 0 }} level={4}>
            {record.loadId}
          </Typography.Title>
          <Typography.Text style={{ color: toRgba(colors.orangeWeb, 0.6) }}>{'NEW'}</Typography.Text>
        </Row>
      }>
      <Descriptions layout="horizontal" bordered size="small" style={{ marginTop: 0 }} items={items} />
    </Card>
  );
};
