import { PlusCircleOutlined } from '@ant-design/icons';
import { Col, GetProp, notification, Row, Space, Typography, UploadFile } from 'antd';
import Dragger from 'antd/es/upload/Dragger';
import { UploadProps } from 'antd/lib';
import { useFormikContext } from 'formik';
import { Shipment, ShipmentPayload } from 'models/DockReceipt';
import { FC } from 'react';

type FileType = Parameters<GetProp<UploadProps, 'beforeUpload'>>[0];

type Props = {
  isEdit?: boolean;
  setFileList: React.Dispatch<React.SetStateAction<UploadFile[]>>;
  fileListState: UploadFile[];
  isCreate?: boolean;
  record?: Shipment;
};

const getBase64 = (file: FileType): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();

    if (file instanceof Blob) {
      reader?.readAsDataURL(file);
      reader.onload = (): void => resolve(reader.result as string);
      reader.onerror = (error): void => reject(error);
    }
  });

export const ShipmentAttachmentUpload: FC<Props> = ({ setFileList, fileListState, record }) => {
  const { setValues, values } = useFormikContext<ShipmentPayload>();

  const isReadOnly = values.status === 'Cancelled' || values.status === 'Completed';

  const props: UploadProps = {
    name: 'file',
    style: { width: '100%' },
    onChange: async ({ fileList, file }) => {
      if (file.status !== 'removed' && fileListState.find((att) => att.name === file.name)) {
        // const file: any = fileList.find((att) => att.name === file.name);

        notification.error({ message: `File already exists` });

        return;
      }

      setFileList(fileList);
      const filePromises = fileList
        ?.filter((file) => !record?.attachments.find((att) => att.name === file.name))
        .map(async (file) => {
          const base64 = await getBase64(file.originFileObj as any);
          const trimmedBase64 = base64.split(',')[1];

          return { fileBase64: trimmedBase64, fileName: file.name };
        });
      const filesToAdd = await Promise.all(filePromises);

      if (!record) {
        setValues((prev) => ({
          ...prev,
          attachmentsToCreate: filesToAdd
        }));

        return;
      }

      if (record && file.status !== 'removed' && fileList.find((att) => !record.attachments.find((file) => att.name === file.name))) {
        setValues((prev) => ({
          ...prev,
          attachmentsToCreate: filesToAdd
        }));

        return;
      }
      if (record && file.status === 'removed' && record.attachments.find((att) => att.name === file.name)) {
        console.log('delte');
        const attachmentsToDelete = record.attachments.find((att) => att.name === file.name)?.id;

        setValues((prev) => ({
          ...prev,
          attachmentsToDelete: [...prev.attachmentsToDelete, attachmentsToDelete as string]
        }));

        return;
      }
      if (record && !fileList.length) {
        setValues((prev) => ({
          ...prev,
          attachmentsToDelete: record.attachments.map((att) => att.id)
        }));

        return;
      }
    },
    fileList: fileListState,
    beforeUpload: () => false
  };

  return (
    <Space direction="vertical" style={{ width: '100%' }}>
      <Typography.Text>Attachments</Typography.Text>
      <Row style={{ marginBottom: 16, width: '100%' }}>
        <Col span={24}>
          <Dragger multiple disabled={isReadOnly} {...props}>
            <PlusCircleOutlined style={{ fontSize: 35, color: '#1677ff' }} />
          </Dragger>
        </Col>
      </Row>
    </Space>
  );
};
