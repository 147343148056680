import { Button, Tooltip } from 'antd';
import { ButtonProps } from 'antd/lib';
import { FC, useState } from 'react';
import Lottie from 'react-lottie-player';

type Props = ButtonProps & {
  animationData: object;
  hoverColor: string;
  borderColor: string;
  tooltip?: string;
  scale?: number;
};

export const LottieButton: FC<Props> = ({ animationData, hoverColor, borderColor, tooltip, scale, ...rest }) => {
  const [hover, setHover] = useState(false);

  return (
    <Tooltip title={tooltip ?? undefined}>
      <Button
        style={{ background: hover ? hoverColor : undefined, border: `1px solid ${borderColor}` }}
        onMouseEnter={(): void => setHover(true)}
        onMouseLeave={(): void => setHover(false)}
        icon={<Lottie play={hover} loop animationData={animationData} goTo={0} style={{ width: 25, height: 25, transform: scale ? `scale(${scale})` : undefined }} />}
        {...rest}
      />
    </Tooltip>
  );
};
