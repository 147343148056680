import { Card, Descriptions, Row, Typography } from 'antd';
import { DescriptionsProps } from 'antd/lib';
import { colors, toRgba } from 'common/styles/colors';
import { Shipment } from 'models/DockReceipt';
import { FC } from 'react';
import { DeleteShipmentsButton } from './DeleteShipmentsButton';
import { EditShipmentsDrawer } from './EditShipmentsDrawer';
import { ShipmentInfoModal } from './ShipmentInfoModal';
import { ShipmentNoteModal } from './ShipmentNoteModal';

type Props = {
  record: Shipment;
  dockReceiptId: string;
};

export const MobileCardShipments: FC<Props> = ({ record, dockReceiptId }) => {
  const items: DescriptionsProps['items'] = [
    {
      key: '0',
      label: 'Receipt Type',
      children: record?.receiptType ?? 'PO',
      contentStyle: { textAlign: 'end' }
    },
    {
      key: '1',
      label: 'Account Id',
      children: record?.accountId ?? '',
      contentStyle: { textAlign: 'end' }
    },
    {
      key: '2',
      label: 'MDSi Order Number',
      children: record?.mdsiOrderNumber ?? '',
      contentStyle: { textAlign: 'end' }
    },
    {
      key: '3',
      label: 'Return Ref',
      children: record?.returnReference ?? 'Carson Coyle',
      contentStyle: { textAlign: 'end' }
    },
    {
      key: '4',
      label: 'Warehouse',
      children: record?.processingWarehouse ?? '08-20-2024',
      contentStyle: { textAlign: 'end' }
    },
    {
      key: '5',
      label: 'Shipment Date',
      children: new Date(record?.shipmentDate).toLocaleDateString() ?? '08-20-2024',
      contentStyle: { textAlign: 'end' }
    },
    {
      key: '6',
      label: 'Assigned To',
      children: record?.assignedToUserFullName ?? '08-20-2024',
      contentStyle: { textAlign: 'end' }
    }
  ];

  return (
    <Card
      styles={{ body: { padding: 8 } }}
      actions={[
        <EditShipmentsDrawer key={'edit'} record={record} dockReceiptId={record.partitionKeyLevel2} />,
        <ShipmentInfoModal key={'info'} record={record} />,
        <ShipmentNoteModal key={'note'} record={record} />,
        <DeleteShipmentsButton key={'delete'} dockReceiptId={record.partitionKeyLevel2} record={record} />
      ]}
      title={
        <Row justify={'space-between'}>
          <Typography.Title style={{ padding: 0, margin: 0 }} level={4}>
            {record.shipmentId ?? 'TEST'}
          </Typography.Title>
          <Typography.Text style={{ color: toRgba(colors.orangeWeb, 0.6) }}>{record.status ?? 'NEW'}</Typography.Text>
        </Row>
      }>
      <Descriptions layout="horizontal" bordered size="small" style={{ marginTop: 0 }} items={items} />
    </Card>
  );
};
