import { InfoCircleOutlined } from '@ant-design/icons';
import { List, Space, Table, Tooltip, Typography } from 'antd';
import { ColumnsType } from 'antd/es/table';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import { DeleteShipmentsButton } from 'components/atoms/DeleteShipmentsButton';
import { EditShipmentsDrawer } from 'components/atoms/EditShipmentsDrawer';
import { MobileCardShipments } from 'components/atoms/MobileCardShipments';
import { ShipmentInfoModal } from 'components/atoms/ShipmentInfoModal';
import { ShipmentNoteModal } from 'components/atoms/ShipmentNoteModal';
import { LoaderWithMessage } from 'components/common/LoaderWithMessage';
import { TablePagination } from 'components/molecules/TablePagination';
import { DockReceipt, Shipment } from 'models/DockReceipt';
import { FC } from 'react';
import { useDispatch } from 'react-redux';
import { useGetShipmentsQuery } from 'redux/services/linda/lindaApi';
import { setOffset } from 'redux/services/linda/shipmentParams';
import { useAppSelector } from 'redux/store';

type Props = {
  dockRecord: DockReceipt;
};

export const ShipmentsTable: FC<Props> = ({ dockRecord }) => {
  const { xs } = useBreakpoint();
  const { shipmentParams } = useAppSelector((state) => state);
  const dispatch = useDispatch();

  const handlePageChange = (page: number, pageSize: number): void => {
    dispatch(setOffset((page - 1) * pageSize));
  };

  const { data, isLoading, isFetching } = useGetShipmentsQuery({ dockReceiptId: dockRecord?.id, params: shipmentParams });
  const columns: ColumnsType<Shipment> = [
    {
      title: 'Shipment Id',
      dataIndex: 'shipmentId',
      key: 'shipmentId'
    },
    {
      title: 'Receipt Type',
      dataIndex: 'receiptType',
      key: 'receiptType'
    },
    {
      title: 'Account Id',
      dataIndex: 'accountId',
      key: 'accountId',
      render: (text, record) => {
        const tooltipText =
          record.receiptType === 'Customer Return' ? 'Customer Id' : record.receiptType === 'RMA/Defective Return' ? 'Vendor Id' : record.receiptType === 'Purchase Order' ? 'Vendor Id' : '';

        return (
          <Space>
            <Tooltip title={tooltipText}>
              <InfoCircleOutlined style={{ color: 'rgb(56, 191, 241)' }} />
            </Tooltip>
            <Typography.Text>{text}</Typography.Text>
          </Space>
        );
      }
    },
    {
      title: 'MDSi Order Number',
      dataIndex: 'mdsiOrderNumber',
      key: 'mdsiOrderNumber',
      render: (text, record) => {
        const tooltipText =
          record.receiptType === 'Customer Return'
            ? 'Equipment Return Number'
            : record.receiptType === 'RMA/Defective Return'
            ? 'D365 SO Number'
            : record.receiptType === 'Purchase Order'
            ? 'D365 PO Number'
            : '';

        return (
          <Space>
            <Tooltip title={tooltipText}>
              <InfoCircleOutlined style={{ color: 'rgb(56, 191, 241)' }} />
            </Tooltip>
            <Typography.Text>{text}</Typography.Text>
          </Space>
        );
      }
    },
    {
      title: 'Return Reference',
      dataIndex: 'returnReference',
      key: 'returnReference'
    },
    {
      title: 'Processing Warehouse',
      dataIndex: 'processingWarehouse',
      key: 'processingWarehouse'
    },
    {
      title: 'Shipment Date',
      dataIndex: 'shipmentDate',
      key: 'shipmentDate',
      render: (text) => new Date(text).toLocaleDateString()
    },
    {
      title: 'Assigned To',
      dataIndex: 'assignedToUserFullName',
      key: 'assignedToUserFullName'
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status'
    },
    {
      title: 'Actions',
      key: 'actions',
      align: 'center',
      fixed: 'right',
      render: (text, record) => {
        return (
          <Space>
            <EditShipmentsDrawer record={record} dockReceiptId={record.partitionKeyLevel2} />
            <ShipmentInfoModal record={record} />
            <ShipmentNoteModal record={record} />
            <DeleteShipmentsButton record={record} dockReceiptId={record.partitionKeyLevel2} />
          </Space>
        );
      }
    }
  ];

  if (xs)
    return (
      <List
        grid={{ xs: 1, sm: 2, md: 4, lg: 4, xl: 4, xxl: 4, gutter: [8, 8] }}
        dataSource={data?.data}
        loading={{ spinning: isLoading || isFetching }}
        renderItem={(item): JSX.Element => <MobileCardShipments dockReceiptId={item.partitionKeyLevel2} record={item} />}
      />
    );

  return (
    <>
      <Table
        loading={{ indicator: <LoaderWithMessage loadingMessage="Loading shipments" />, spinning: isLoading || isFetching }}
        columns={columns}
        style={{ width: '100%' }}
        size="small"
        dataSource={data?.data}
        scroll={{ x: 'max-content' }}
        pagination={false}
      />
      <TablePagination offset={shipmentParams.offset} dispatchAction={setOffset} hasMore={data?.hasMore ?? false} />
    </>
  );
};
